<section id="albero-titolario-container" class="pt-2 pb-2">

    <div id="albero-titolario-header" class="d-flex pt-2 pb-3">
        <div class="form-group d-flex mr-2 mb-0 spaced-input" style="max-width: 300px;">
            <input type="text" class="form-control text-center" placeholder="" aria-label="" aria-describedby="basic-addon1"  [(ngModel)]="collocazione.col1">
            <input type="text" class="form-control text-center" placeholder="" aria-label="" aria-describedby="basic-addon1"  [(ngModel)]="collocazione.col2">
            <input type="text" class="form-control text-center" placeholder="" aria-label="" aria-describedby="basic-addon1"  [(ngModel)]="collocazione.col3">
            <input type="text" class="form-control text-center" placeholder="" aria-label="" aria-describedby="basic-addon1"  [(ngModel)]="collocazione.col4">
            <input type="text" class="form-control text-center" placeholder="" aria-label="" aria-describedby="basic-addon1"  [(ngModel)]="collocazione.col5">
            <input type="text" class="form-control text-center" placeholder="" aria-label="" aria-describedby="basic-addon1"  [(ngModel)]="collocazione.col6">
        </div>
        <div class="filter-button d-flex justify-content-center align-items-center text-primary-archipro">
            <i class="icon-search" title="Cerca titolario" (click)="filtraListaCollocazione()"></i>
            <i class="icon-filter" title="Ripristina titolario" (click)="ripristinaCollocazione()"></i>
        </div>
    </div>
    
    <tree-view *ngIf="listObj.length > 0;else loading" [listObj]="listObj" [show]="true" [riporta]="riportaCollocazione" [modalMode]="modalMode"></tree-view>
    <ng-template  #loading>
        <app-loading></app-loading>
    </ng-template>
</section>
