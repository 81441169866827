export class ListaDocNavigazioneRequest{

    titolo1 : number;
    titolo2 : number;
    titolo3 : number;
    titolo4 : number;
    titolo5 : number;
    titolo6 : number;

    col1 : number;
    col2 : number;
    col3 : number;
    col4 : number;
    col5 : number;
    col6 : number;


    tipoDoc : string; // "T"=tutti P=protocollati NP
    
    numeroFascicolo : number;
    annoFascicolo : number;
    numTitolo : number;

    collocazione : string; // SI o NO

    order : number;
    currentPage : number;
    numRow : number;

    oggetto : string;

    constructor(){
       // this.tipoDoc = "T";
    }

    setCurrentPage(currentPage : number)
    {
        this.currentPage = currentPage;
    }

    setNumRow(numRow : number){
        this.numRow = numRow;
    }



}