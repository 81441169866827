import { Component, Input, OnInit } from '@angular/core';
import { RequestService } from 'src/app/service/requestService/request.service';
import { SCREEN_SIZE } from 'src/app/service/resize/screen-size.enum';
import { Utility } from 'src/app/Utility/Utility';

@Component({
  selector: 'app-protocolli',
  templateUrl: './protocolli.component.html',
  styleUrls: ['./protocolli.component.scss']
})
export class ProtocolliComponent implements OnInit {

  readonly tipoRiferimento : string = "D";

  searchInProgress : boolean = false;

  //Variabili utilizzate per la paginazione
  //************************************* */
  totRecords : number;  
  numPage : number = 0;
  currentPage : number = 1;
  numRow : number = 10;
  listObjPage : any = [];
  elementA : number;
  elementDa : number;
  //************************************* */

  //Lista generale passato dal component container
  @Input() listaRiferimentiSelezionata : any[] = [];

  //Oggetto che indica la misura del container
  @Input() size : SCREEN_SIZE;

  //Funzione per rimuovere dalla lista generale il riferimento
  @Input() rimuoviRiferimento : (tipo : string, riferimento : any) => void;

  //Oggetto utilizzato per il filtro richiesta
  docRequest : any = {
    fullText : ""
  }

  //Lista dei riferimenti di un singolo tipo
  listRiferimenti : any = [];

  constructor(private requestService : RequestService) { }

  ngOnInit(): void {
    
  }

   /**
   * Questo Metodo viene utilizzata per calcolare il numero di pagine che la lista ha calcolata
   * in base al numero di record della lista e al opzione di numero di righe selezionata dall'utente
   */
    buildPaginationObject()
    {
      this.numPage = Utility.calculateNumberOfPages(this.totRecords, this.numRow);
      this.listObjPage = Utility.buildPaginationObject(this.numPage, this.currentPage);
      this.elementA = (this.currentPage * this.numRow) - (this.numRow - this.listRiferimenti.length);
      this.elementDa = (this.currentPage * this.numRow) - (this.numRow - 1);
    }

       /**
   * Metodo utilizzato dalla paginazione per richiedere al servere la lista
   * 
   * @param page : numero della pagina cliccata
   */
  changePage(page : number)
  {
    this.currentPage = page;
    this.getList();
  }

  getList() : void {
    this.docRequest.currentPage = this.currentPage;

    this.searchInProgress = true;
    this.requestService.postRequest("searchDocumentFreeText", this.docRequest,
      
      (success) => {

        this.listRiferimenti = success.obj.list; 

        /**
         * Questa parte viene fatta per fare in modo che quelle 
         * precedentemente selezionate compaiano con il check nelle varie liste
         * (in questo caso quella dei protocolli)
         */
        
        let listToCheck = this.listaRiferimentiSelezionata.filter(element => element.tipoRiferimento == this.tipoRiferimento);
        listToCheck.forEach(element => {
          this.listRiferimenti.forEach(riferimento => {
            if(element.annoProtocollo == riferimento.pk.annoProt && element.numProtocollo == riferimento.pk.numProt)
            {
              riferimento.active = true;
              riferimento.tipoRiferimento = this.tipoRiferimento;
              riferimento.descrizione = element.descrizione;
            }
          })
        })

        this.totRecords = success.obj.totRecords;

        this.buildPaginationObject();
        this.searchInProgress = false;
      },
      () => {
        this.searchInProgress = false;
      }
    )
  }

  addToGeneralList(doc: any){
    if(!doc.active)
    {
      doc.active = true;
      doc.tipoRiferimento = this.tipoRiferimento;
      doc.descrizione = "(" + doc.docNumString + ") - " + doc.oggetto;

      //questa parte la faccio per fare in modo che al momento che riporto la lista generale viene creato correttamente l'oggetto riferimenti
      //visto che i nomi delle variabili che mi vengono passate dal server sono diversi in ogni richiesta (numProt, numProtocollo, numeroProtocollo)
      doc.numProtocollo = doc.pk.numProt;
      doc.annoProtocollo = doc.pk.annoProt;

      this.listaRiferimentiSelezionata.push(doc);
    }
    else
    {
      doc.active = false;
      this.rimuoviRiferimento(this.tipoRiferimento, doc);
    }
  }
}
