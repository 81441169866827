import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ViewerComponent } from './../sub-component/viewer/viewer.component';
import { PrimaryKeyAllegato } from './../../model/PrimaryKeyAllegato';
import { RequestService } from './../../service/requestService/request.service';
import { PrimaryKeyPratica } from 'src/app/model/PrimaryKeyPratica';


declare var $ : any;

@Component({
  selector: 'app-viewer-ext',
  templateUrl: './viewer-ext.component.html',
  styleUrls: ['./viewer-ext.component.scss']
})


export class ViewerExtComponent implements OnInit {


  @ViewChild('viewerAllegato')
  vieweObj : ViewerComponent;

  objRequestAllegato : PrimaryKeyAllegato = new PrimaryKeyAllegato();

  objRequestPratica : PrimaryKeyPratica = new PrimaryKeyPratica();

  documentoViewer: any = {};

  /**
   * Variabile istanziata a seconda dei parametri che vengono passati da procedo, se i dati appartengono alla pratica
   * allora deve chiamare il servizio che gestisce anno e numero pratica anzichè anno e numero protocollo
   */
  modPratica : boolean = false;

  constructor(private activatedRoute : ActivatedRoute, private requestService : RequestService) {
    this.activatedRoute.queryParams.subscribe(params => {

      if(params['annoProt'] != undefined && params['numProt'])
      {
        this.objRequestAllegato.annoProt = params['annoProt'];
        this.objRequestAllegato.numProt = params['numProt'];
        this.objRequestAllegato.progr = params['progr'];
      }
      else if(params['annoPrat'] != undefined && params['numPrat'])
      {
        this.modPratica = true;
        this.objRequestPratica.annoPrat = params['annoPrat'];
        this.objRequestPratica.numPrat = params['numPrat'];
      }
      else
      {
        this.actionFailure()
      }


    });

    if(this.objRequestAllegato.checkPrimaryKey())
    {
      this.initDocumentViewer();
    }
    else
    {
      this.actionFailure();
    }

  }

  ngOnInit(): void {
  }

  ngAfterViewInit(){

  }

  /**
   * Metodo che effettua la chiamata al server per prendere le inforamzioni iniziali del documento
   *
   */
  initDocumentViewer()
  {
    let urlService = this.modPratica ? "" : "getListaDocumentiProtocollo";
    let payload = this.modPratica ? this.objRequestPratica : this.objRequestAllegato;

    this.requestService.postRequest(urlService, payload,
      (response) => {
        if(response.obj.length > 0)
        {
          this.documentoViewer.documenti = response.obj[0];
          this.apriViewer();
        }
        else
        {
          this.actionFailure();
        }
      }
    );
  }


  /**
   * Funzione che, una volta completata la richiesta fatta al server per prendere i dati del documento (quello che di solito al viewer
   * viene passato dalla lista) apre il viewer
   *
   * ps: al momento il timeout è inserito per effettuare i test, valutare in seguito se toglierlo
   */
  apriViewer()
  {

    //this.vieweObj.ngOnInit();
    setTimeout(()=>{
      this.vieweObj.ngOnInit();
      $('#externalViewer').modal({
        keyboard: false
      });
    }, 100)
  }


  actionFailure(){
    alert("errore");
  }


}
