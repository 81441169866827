import { Firmatario } from "./Firmatario";
import { PrimaryKeyAllegato } from './PrimaryKeyAllegato';

export class AllegatoObj{

    pk : PrimaryKeyAllegato;

    daScansire: boolean;
    dataScan: string;
    applic: string;
    fileOrig: string;
    nomeDoc: string;
    nomePdf: string;
    nomeOCR: string;
    descrizione: string;
    pgriservato: boolean;
    matrScan: number;
    impronta: string;
    flagEmail: boolean;

    idMail : number;
    progrMail : number;

    //Campo aggiunto solo lato client per costruire l'url del documento che viene visualizzato nel viewer
    url : string = "";
    //Campo che indica se il file è stato convertito
    converted: boolean;

    signer : any = [];

    stampaGenericaObj : AllegatoObj;


    constructor(obj? : any){
        if(obj)
        {
            this.pk = new PrimaryKeyAllegato(obj.pk);

            this.daScansire = obj.daScansire;
            this.dataScan = obj.dataScan;
            this.applic = obj.applic;
            this.fileOrig = obj.fileOrig;
            this.nomeDoc = obj.nomeDoc;
            this.nomePdf = obj.nomePdf;
            this.nomeOCR = obj.nomeOCR;
            this.descrizione = obj.descrizione;
            this.pgriservato = obj.pgriservato;
            this.matrScan = obj.matrScan;
            this.impronta = obj.impronta;
            this.flagEmail = obj.flagEmail;
            this.idMail  = obj.idMail;
            this.progrMail  = obj.progrMail;

            //Campo aggiunto solo lato client per costruire l'url del documento che viene visualizzato nel viewer
            this.url  = obj.url;
            //Campo che indica se il file è stato convertito
            this.converted = obj.converted;

            this.signer  = obj.signer;
        }
    }
}



