<section class="d-flex flex-column w-100 h-100 p-3 overflow-auto">

    <section id="header-page">

        <div class="d-flex text-primary-archipro justify-content-between align-items-center border-bottom pb-2">
            <h4 class="">
                <span>Navigazione / </span>
                <span (click)="breadCrumb.setAlberoTitolario()" class="cursor-pointer breadCrumbTitle" [ngClass]="{'breadcrumb-active' : breadCrumb.showAlberoTitolario && titolarioSelezionato}">Titolario</span>
                <span *ngIf="breadCrumb.showFascicoli || titolarioSelezionato || breadCrumb.showDettaglioDocumento" class="breadCrumbTitle">
                    <i class="icon-right-open ml-2 mr-2"></i>
                    <span (click)="breadCrumb.setFascicoli()" class="cursor-pointer" [ngClass]="{'breadcrumb-active' : breadCrumb.showFascicoli}">Fascicoli </span>
                </span>

                <span *ngIf="breadCrumb.showFascicoli && (breadCrumb.showNuovoFascicolo || breadCrumb.showModificaFascicolo)" class="breadCrumbTitle">
                    <i class="icon-right-open ml-2 mr-2"></i>
                    <span (click)="breadCrumb.setFascicoli()" class="cursor-pointer" [ngClass]="{'breadcrumb-active' : breadCrumb.showFascicoli}">{{breadCrumb.showNuovoFascicolo ? "Nuovo fascicolo" : "Modifica fascicolo (" + modalModificaFascicolo.numeroFascicolo + "/" + modalModificaFascicolo.annoFascicolo + ")" }}  </span>
                </span>

                <span *ngIf="breadCrumb.showDocumenti || fascicoloSelezionato || breadCrumb.showDettaglioDocumento" class="breadCrumbTitle">
                    <i class="icon-right-open ml-2 mr-2" style="font-size: 1.2rem;"></i>
                    <span (click)="breadCrumb.setDocumenti()" class="cursor-pointer" [ngClass]="{'breadcrumb-active' : breadCrumb.showDocumenti}">Documenti </span>
                </span>

                <span *ngIf="modalMode && breadCrumb.showDettaglioDocumento" class="breadCrumbTitle">
                    <i class="icon-right-open ml-2 mr-2" style="font-size: 1.2rem;"></i>
                    <span (click)="breadCrumb.setDettaglioDocumento()" class="cursor-pointer" [ngClass]="{'breadcrumb-active' : breadCrumb.showDettaglioDocumento}">Dettaglio documento </span>
                </span>

            </h4>
            <div class="spaced-input d-flex">
                <h4 class="icon-left-big cursor-pointer" title="Indietro" *ngIf="!breadCrumb.showAlberoTitolario" (click)="breadCrumb.showDocumenti ? breadCrumb.setFascicoli() : breadCrumb.setAlberoTitolario()"></h4>
                <button *ngIf="modalMode" type="button" class="close d-flex" data-dismiss="modal" aria-label="Close">
                    <h4 aria-hidden="true">&times;</h4>
                </button>
            </div>

        </div>
    </section>

    <section id="body-page" class="h-100">
        <app-albero-titolario *ngIf="breadCrumb.showAlberoTitolario"
        [apriFascicoloAlberoTitolario]="apriFascicolo"
        [titolarioIsSelezionato]="titolarioIsSelezionato"
        [riportaTitolario]="riportaTitolario"
        [titolarioFromForm]="titolarioFromForm"></app-albero-titolario>

        <app-fascicoli *ngIf="breadCrumb.showFascicoli && !(breadCrumb.showModificaFascicolo || breadCrumb.showNuovoFascicolo)"
        [openFormFascicolo]="openFormFascicolo"
        [titolarioFromForm]="titolarioFromForm"
        [titolarioSelezionato]="titolarioSelezionato"
        [apriListaDocumenti]="apriListaDocumenti"
        [fascicoloIsSelezionato]="fascicoloIsSelezionato"
        [riportaFascicolo]="riportaFascicolo"></app-fascicoli>

        <app-documenti *ngIf="breadCrumb.showDocumenti"
        [titolarioSelezionato]="titolarioSelezionato"
        [fascicoloSelezionato]="fascicoloSelezionato"
        [apriDettaglioDocumentiPerModal]="apriDettaglioDocumentiPerModal"></app-documenti>

        <app-form-fascicolo *ngIf="breadCrumb.showModificaFascicolo || breadCrumb.showNuovoFascicolo"
        [modalMode]="'true'"
        [modalModificaFascicolo]="modalModificaFascicolo"
        [isNuovoFascicolo]="breadCrumb.showNuovoFascicolo"
        (fascicoloModificatoEvent)="fascicoloSelezionato = null"
        ></app-form-fascicolo>

        <app-viewer-in-navigazione-modal *ngIf="breadCrumb.showDettaglioDocumento"
        [documentoViewer]="documentoViewer"></app-viewer-in-navigazione-modal>
    </section>
</section>
