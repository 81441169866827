<section *ngIf="!searchInProgress; else loading" id="flussi-interoperabilita" class="w-100 h-100 d-flex flex-column">
    <div class="container-info-flussi d-flex-">
        <div class="row-dettaglio d-flex">
            <span class="label-dettaglio">
                <b>Documento:</b>
            </span>
            <span class="value-dettaglio d-flex align-items-center">
                {{dettaglioFlussi.intestazione.NUMERO}} del {{dettaglioFlussi.intestazione.DATA | dataStringTransform}}
            </span>
        </div>

        <div class="row-dettaglio d-flex">
            <span class="label-dettaglio">
                <b>Mittente:</b>
            </span>
            <span class="value-dettaglio d-flex align-items-center">
                {{dettaglioFlussi.intestazione.MITTENTE}}
            </span>
        </div>


        <div class="row-dettaglio d-flex">
            <span class="label-dettaglio">
                <b>Destinatario:</b>
            </span>
            <span class="value-dettaglio d-flex align-items-center">
                {{dettaglioFlussi.intestazione.DESTINATARIO}}
            </span>
        </div>


    </div>
    <div class="container-lista-flussi mt-3">
        <div>
            <table class="table table-striped border" *ngIf="size > 3; else mobile">
                <thead class="">
                    <tr class="text-primary-archipro">
                        <th class="size-badge-notifica"></th>
                        <th scope="col">
                            <span class="d-flex">Data e ora</span>
                        </th>                             
   
                        <th scope="col">
                            <span class="d-flex">Flusso</span>
                        </th>

                        <th scope="col">
                            <span class="d-flex">Oggetto</span>
                        </th>

                        <th scope="col">
                            <span class="d-flex">Tipo operazione</span>
                        </th>
                        <th scope="col">Azioni</th>
                    </tr>
                </thead>
                <tbody *ngIf="!searchInProgress; else loadingList">
                    <tr *ngFor="let flusso of dettaglioFlussi.list; let i = index">
                        <td class="size-badge-notifica pl-2">
                            <div class="d-flex h-100 justify-content-start align-items-center">
                                <i *ngIf="flusso.flussoOperazione == 'U'" [style.visibility]="flusso.trasmessa == 'true' ? 'hidden' : 'visible'" title="Mail non inviata" class="icon-export"></i>
                            </div>
                            
                        </td>
                        <td>{{flusso.dataOperazione | dataStringTransform}} {{flusso.oraOperazione}}</td>
                        <td>{{flusso.flussoOperazione}}</td>
                        <td>{{flusso.oggetto}}</td>
                        <td>{{flusso.tipoOperazioneDescr}}</td>
                        <td  class="action-icon d-flex align-items-center" ClickStopPropagation>
                            <div class="icona-doc-da-leggere border container-icon" (click)="openMail(flusso)">
                                <i class="icon-mail" title="Leggi"></i>
                            </div>
                        </td>

                    </tr>

                </tbody>
                <ng-template #loadingList>
                    <tbody>
                        <tr>
                            <td colspan="100%">
                                <app-loading class="w-100 h-100 d-flex justify-content-center align-items-center"></app-loading>
                            </td>
                        </tr>
                    </tbody>
                </ng-template>
            </table>

            <ng-template #mobile>
                <div id="accordionMobileListaFlussi" class="mt-3" *ngIf="!searchInProgress; else loading">
                    <div class="card card-list-mobile" *ngFor="let flusso of dettaglioFlussi.list; let i = index">
                      <div class="card-header" [attr.id]="'lista_flussi_' + i + 'heading'">
                        <div class="mb-0 d-flex">
                   
                          <button class="btn w-100 p-0" data-toggle="collapse" [attr.data-target]="'#' + 'lista_flussi_' + i" aria-expanded="true" [attr.aria-controls]="'lista_flussi_' + i">
                                <div class="d-flex w-100 justify-content-between">
                                    <h6 class="mt-1 d-flex align-items-center">{{flusso.dataOperazione | dataStringTransform}} {{flusso.oraOperazione}}</h6>
                                    <div>

                                        <div style="margin-right: 4px;"> 
                                            <small class="ml-2">{{flusso.flussoOperazione}}</small>
                                        </div>
                                      
                                    </div>
                                </div>
                                <div class="d-flex mb-1 text-left">
                                    <span class="oggetto-mobile cut-text">
                                        {{flusso.oggetto}}
                                    </span>
                                </div>
                                <div class="d-flex text-muted cut-text ">
                                    <small class="agd-uo-list-mobile w-100 d-flex justify-content-between">
                                        <span>{{flusso.tipoOperazioneDescr}}</span>
                                    </small>
                                </div>
                                
                          </button>
                          <div class="position-relative">
                            <div class="dropdown d-flex align-items-center justify-content-center h-100 position-absolute" style="right: -16px;">
                                <span [attr.id]="'action-dropdown-' + 'lista_flussi_' + i" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <i class="icon-ellipsis-vert" title="Azioni"></i>
                                </span>
                                
                                <div class="dropdown-menu dropdown-menu-right" [attr.aria-labelledby]="'action-dropdown-' + 'lista_flussi_' + i">
                                    <h6 class="dropdown-header">Azioni</h6>
        
                                    <div class="dropdown-divider"></div>

                                    <a class="dropdown-item cursor-pointer" (click)="openMail(flusso)">
                                        <i class="icon-mail text-primary-archipro"></i>
                                        <span class="ml-1">Leggi</span>
                                    </a>
                                </div>
                            </div>
                          </div>
                        </div>
                      </div>
                  
                      <div [attr.id]="'lista_flussi_' + i" class="collapse {{i == 0 ? 'show' : ''}}" [attr.aria-labelledby]="'lista_flussi_' + i + 'heading'" data-parent="#accordionMobileListaFlussi">
                        <div class="card-body">
                          
                            <div class="d-flex flex-column" style="font-size: 14px;">

                                <div class="d-flex column-element-mobile">
                                    <span class="w-25 font-weight-bold h-100 name-column-mobile">Data e ora :</span>
                                    <span class="w-100 text-left h-100">{{flusso.dataOperazione | dataStringTransform}} {{flusso.oraOperazione}}</span>
                                </div>
                                <div class="d-flex column-element-mobile">
                                    <span class="w-25 font-weight-bold h-100 name-column-mobile">Flusso :</span>
                                    <span class="w-100 text-left h-100">{{flusso.flussoOperazione}}</span>
                                </div>
                                <div class="d-flex column-element-mobile">
                                    <span class="w-25 font-weight-bold h-100 name-column-mobile">Oggetto :</span>
                                    <span class="w-100 text-left h-100">{{flusso.oggetto}}</span>
                                </div>
                                <div class="d-flex column-element-mobile">
                                    <span class="w-25 font-weight-bold h-100 name-column-mobile">Tipo operazione :</span>
                                    <span class="w-100 text-left h-100">{{flusso.tipoOperazioneDescr}}</span>
                                </div>

                               

                            </div>
                        </div>
                      </div>
                    </div>
                  </div>
            </ng-template>
        </div>
    </div>
</section>

<ng-template #loading>
    <app-loading [text]="''"></app-loading>
</ng-template>