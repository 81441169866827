import { ThrowStmt } from '@angular/compiler';
import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { timeout } from 'rxjs/operators';
import { collapseTextChangeRangesAcrossMultipleVersions } from 'typescript';
import { AllegatoObj } from './../../../model/AllegatoObj';
import { RequestService } from './../../../service/requestService/request.service';


@Component({
  selector: 'app-viewer-form-protocollazione',
  templateUrl: './viewer-form-protocollazione.component.html',
  styleUrls: ['./viewer-form-protocollazione.component.scss']
})
export class ViewerFormProtocollazioneComponent implements OnInit {


  @Input() 
  objParameterComponent : any;

  url : string = "";

  allegato : AllegatoObj = new AllegatoObj() ; 

  listaFirmatari : any = [];

  showDatiFirma : boolean = false;
  showPrevew : boolean = true;
  loading : boolean = true;

  downloadUrl : any = "";
  previewNotAvaible : boolean = false;

  constructor(private sanitizer : DomSanitizer) { }

  ngOnInit(): void {
    
  }

  ngAfterViewInit() : void {
    this.allegato = this.objParameterComponent.allegato;

    this.listaFirmatari = this.allegato.signer;

    this.url = this.allegato.url;
   
    this.downloadUrl = URL.createObjectURL(this.objParameterComponent.file);

    this.downloadUrl = this.sanitizer.bypassSecurityTrustUrl(this.downloadUrl);
    
    this.loading = !this.checkUrl();
    setTimeout(()=>{ this.previewNotAvaible = true }, 3000);




  }

  /**
   * Apre e chiude i dati del firmatario all'interno della preview
   * 
   * @param stato : se true apre i dati firmatario, altrimenti li nasconde
   */
  showFirmatario(stato : boolean) : void{
    this.showDatiFirma = stato;
    this.showPrevew = !stato;
  }

  checkUrl() : boolean
  {
    return this.url != undefined && this.url != null && this.url != "";
  }

  checkSigner() : boolean{
    return this.listaFirmatari != null && this.listaFirmatari.length > 0;
  }
}
