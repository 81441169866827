import { Component, OnInit } from '@angular/core';
import { VerifyLoginResult } from './../../../model/VerifyLoginResult';
import { Utility } from './../../../Utility/Utility';
import { StaticProperty } from 'src/app/Utility/StaticProperty';

@Component({
  selector: 'app-info-utente-inline',
  templateUrl: './info-utente-inline.component.html',
  styleUrls: ['./info-utente-inline.component.scss']
})
export class InfoUtenteInlineComponent implements OnInit {

  user : VerifyLoginResult;
  constructor() 
  { 
    this.user = Utility.localStorageGet(StaticProperty.UTENTE_SESSIONE);
  }

  ngOnInit(): void {
  }

}
