import { Component, Input, OnInit, HostListener, ViewChild, Type, TemplateRef } from '@angular/core';
import { Titolario } from './../../../../../../model/Titolario';
import { RequestService } from './../../../../../../service/requestService/request.service';
import { SCREEN_SIZE } from 'src/app/service/resize/screen-size.enum';
import { ResizeService } from './../../../../../../service/resize/resize.service';
import { NavigazioneComponent } from '../../navigazione.component';
import { Utility } from 'src/app/Utility/Utility';
import { ModalComponent } from 'src/app/component/sub-component/modal/modal.component';
import { OptionModal } from 'src/app/component/sub-component/modal/OptionModal';
import { ElementPdfViewerComponent } from 'src/app/component/sub-component/element-pdf-viewer/element-pdf-viewer.component';
import { environment } from 'src/environments/environment';
import { GlobalService } from 'src/app/service/globalService/global.service';
import { GlobalObj } from 'src/app/service/globalService/GlobalObj';



@Component({
  selector: 'app-albero-titolario',
  templateUrl: './albero-titolario.component.html',
  styleUrls: ['./albero-titolario.component.scss']
})
export class AlberoTitolarioComponent implements OnInit {

  build_no_functionality : boolean = true;

  urlPdfGuida : string = environment.url + "PDF/guida_titolario.pdf";
  pagePdf : number;

  //****************************************************** */
  //Variabili ulitilizzate per dichiarare e inizializzare la modale
  //****************************************************** */
  @ViewChild('dynamicModalComponent')
  dynamicModal : ModalComponent;
  optionModal : OptionModal = new OptionModal();
  //****************************************************** */
  //****************************************************** */

    //Variabili utilizzate per la paginazione
  //************************************* */
  totRecords: number;
  numPage: number = 0;
  currentPage: number = 1;
  numRow: number = 10;
  listObjPage: any = [];
  elementA: number;
  elementDa: number;
  //************************************* */


  listTitolarioRicerca : Titolario[] = [];

  pdfFullScreen : boolean = false;
  searchInProgress : boolean = false;

  searchText : string = "";

  size: SCREEN_SIZE;

  @Input()
  titolarioSelezionato : any;

  @Input() 
  apriFascicoloAlberoTitolario: (args: any) => void;

  @Input() 
  titolarioIsSelezionato: (args: any) => boolean;

  @Input()
  titolarioFromForm : Titolario;

  @Input() riportaTitolario : (titolario : any) => void;

  titolario : Titolario = new Titolario();

  listObj : any = [];

  searchLemme : boolean = false;

  static persistentObj : any = {};

  GLOBALI : GlobalObj;
  
  constructor(private requestService : RequestService, private resizeSvc : ResizeService, private globalService : GlobalService){
    this.size = this.resizeSvc.onResize();
    this.GLOBALI = this.globalService.GLOBAL;
  }
  
  @HostListener("window:resize", [])
  private onResizeFunction() {
    this.size = this.resizeSvc.onResize();
  }

  get modalMode()
  {
    return NavigazioneComponent.modalMode;
  }

  ngOnInit(): void {
    if(!this.titolarioIsSelezionato(null))
    {
      if(this.titolarioFromForm != null) 
      {
        this.titolario = this.titolarioFromForm;
      }
      this.filtraListaTitolario();
    }  
    else
    {
      this.listObj = AlberoTitolarioComponent.persistentObj.list;
      this.titolario = AlberoTitolarioComponent.persistentObj.titolario;
    }
  }
 
  apriFascicolo = (titolario: any): void => {
    
    this.apriFascicoloAlberoTitolario(titolario);
  }

  filtraListaTitolario(){
    this.listObj = [];
    this.requestService.postRequest("searchTitolario",this.titolario,
      (response) => {
        this.listObj = response.obj.list;
        AlberoTitolarioComponent.persistentObj.list = this.listObj;
        AlberoTitolarioComponent.persistentObj.titolario = this.titolario;

        /**
         * Apro direttamente il fascicolo nel caso in cui i valori del titolario inseriti danno
         * una sola voce di titolario.
         */
        if(NavigazioneComponent.autoNext && Utility.checkObj(this.listObj) && this.listObj.length == 1 && this.listObj[0].terminale)
        {
          this.apriFascicoloAlberoTitolario(this.listObj[0]);
        }

      }
    );
  }

  ripristinaTitolario()
  {
    this.titolario = new Titolario();
    this.filtraListaTitolario();
  }

  openCercaLemme() : void{
    this.searchLemme = !this.searchLemme;
  }

  apriGuida = (page : number) : void=>{
    if(!this.modalMode)
    {
      this.optionModal = new OptionModal();
      this.optionModal.titolo = "Guida";
      //this.optionModal.additionalClassModalBody = "p-0";
      
      this.optionModal.setSizeModal("90");
      this.optionModal.maxSizeHeightModal = true;
      this.optionModal.showSaveButton = false;
      //this.optionModal.setClassSizeMaxHeightModalBody("l");
      
      //Opzioni per settare il component da caricare dinamicamente
      //******************************************************* */

      this.optionModal.setLoadDynamicComponent(true);
      this.optionModal.dynamicComponnet = ElementPdfViewerComponent;
      this.optionModal.objParameterComponent = {url : this.urlPdfGuida, page : page};
  
      this.dynamicModal.initModal(this.optionModal);
      this.dynamicModal.openModal();
    }
    else
    {
      this.pdfFullScreen = true;
      this.pagePdf = page;
    }

  }

  riportaSuTitolario(titolario : any) : void{
    //per chiudere ricerca lemme
    this.openCercaLemme();

    for(let i = 1; i <= 6; i++){
      this.titolario["titolo"+i] = titolario["titolo"+i];
    }

    this.filtraListaTitolario();
    
  }

  /*********************************************************************************************** */
  /***************************************RICERCA LEMMI ****************************************** */
  /*********************************************************************************************** */


  cercaTitolario(){
    this.searchInProgress = true;
    this.requestService.postRequest("searchLemmiFreeText",{testo1 : this.searchText}, 
      (success)=>{
        this.listTitolarioRicerca = success.obj.list.map(element => new Titolario(element));
        this.totRecords = success.obj.totRecords;
        this.buildPaginationObject();
        this.searchInProgress = false;
      });
  }



//
/**
 * Metodo utilizzato dalla paginazione per richiedere al servere la lista
 *
 * @param page : numero della pagina cliccata
 */
  changePage(page: number) : void{
    this.currentPage = page;
    this.cercaTitolario();
  }
  /**
   * Questo Metodo viene utilizzata per calcolare il numero di pagine che la lista ha calcolata
   * in base al numero di record della lista e al opzione di numero di righe selezionata dall'utente
   */
  buildPaginationObject() : void{
    this.numPage = Utility.calculateNumberOfPages(this.totRecords, this.numRow);
    this.listObjPage = Utility.buildPaginationObject(
      this.numPage,
      this.currentPage
    );
    this.elementA = this.currentPage * this.numRow - (this.numRow - this.listTitolarioRicerca.length);
    this.elementDa = this.currentPage * this.numRow - (this.numRow - 1);
  }

  /**
   * Metodo utilizzato per settare nell'oggetto di richiesta solo i campi relativi alla paginazione
   * essendo una cosa esterna ai filtri
   */
  buildDocRequestPaginationField() : void{
    //this.objRequest.setNumRow(this.numRow);
    //this.objRequest.setCurrentPage(this.currentPage);
  }

  /*********************************************************************************************** */
  /***************************************RICERCA LEMMI ****************************************** */
  /*********************************************************************************************** */

}


   

