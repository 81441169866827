import { Titolario } from "./Titolario";

export class FascicoliRequest{
    titolo1 : number;
    titolo2 : number;
    titolo3 : number;
    titolo4 : number;
    titolo5 : number;
    titolo6 : number;

    annoFascicolo : number;
    numeroFascicolo : number;
    oggetto : string;
    soggetto : string;
    note : string;

    //fatto per ricerca nel la form di protocollazione
    fullText : string = "";



    order : number;
    currentPage : number;
    numRow : number;



    constructor(){
        this.titolo1 = 0;
        this.titolo2 = 0;
        this.titolo3 = 0;
        this.titolo4 = 0;
        this.titolo5 = 0;
        this.titolo6 = 0;
    }



    setCurrentPage(currentPage : number)
    {
        this.currentPage = currentPage;
    }

    setNumRow(numRow : number){
        this.numRow = numRow;
    }



    setFascicoliRequestFromString(titolario : string){
        let tempTitolario = titolario.split(".");

        for(let i = 0; i < tempTitolario.length; i++){
            this["titolo"+(i+1)] = tempTitolario[i];
        }
    }

    setObjByTitolarioAndFascicolo(titolario : Titolario, annoFascicolo : number, numeroFascicolo : number){
        for(let i = 1; i <= 6; i++)
        {
            this["titolo"+i] = titolario["titolo"+i];
        }
        this.annoFascicolo = annoFascicolo;
        this.numeroFascicolo = numeroFascicolo;
    }


}
