import { ActivatedRouteSnapshot, DetachedRouteHandle, RouteReuseStrategy } from '@angular/router';

interface StoredRoute {
  route: ActivatedRouteSnapshot;
  handle: DetachedRouteHandle;
}

export class NavigateService implements RouteReuseStrategy {

  storedRoutes: Record<string, StoredRoute> = {};

  // Dovremmo salvare questa rotta? Default: false
  shouldDetach(route: ActivatedRouteSnapshot): boolean {
    return !!route.data.storeRoute;
  }
  
  // Salviamo la rotta (default: non la salva mai)
  store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle): void {
    const key = this.getFullPath(route); // Ex. users/1, users/2, users/3, ...
    this.storedRoutes[key] = { route, handle };
  }
  
  // Dovremmo riprenderci questa rotta dallo store? Default: false
  shouldAttach(route: ActivatedRouteSnapshot): boolean {
    const key = this.getFullPath(route);
    const isStored = !!route.routeConfig && !!this.storedRoutes[key];
    return isStored;
    
  }
  
  // Ritorniamo la rotta dallo store (default: non la salva mai, quindi non c'è mai)
  retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle {
    const key = this.getFullPath(route);
    //if(this.storedRoutes[key]) console.log("recupero", this.storedRoutes[key].handle);
    if (!route.routeConfig || !this.storedRoutes[key]) return null;
    return this.storedRoutes[key].handle;
  }

  // Dovremmo riutilizzare questa rotta? Default: solo se non ha la stessa configurazione.
  shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
    return future.routeConfig === curr.routeConfig;
  }
q
  private getFullPath(route: ActivatedRouteSnapshot): string {
    return route.pathFromRoot
      .map(v => v.url.map(segment => segment.toString()).join("/"))
      .join("/")
      .trim()
      .replace(/\/$/, ""); // Remove trailing slash
  }

  






}
