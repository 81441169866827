import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dataStringTransform'
})
export class DataStringTransformPipe implements PipeTransform {

  DELIMITER : string = "/";

  transform(value: string): string {

    //2021-01-19
    let dataSplitted = value.split("-");
    return (((dataSplitted[2] as unknown as number) < 10 && dataSplitted[2].length < 2) ? "0" + dataSplitted[2] : dataSplitted[2]) + this.DELIMITER +  
          (((dataSplitted[1] as unknown as number) < 10 && dataSplitted[1].length < 2)? "0" + dataSplitted[1] : dataSplitted[1]) + this.DELIMITER + 
          dataSplitted[0].substring(2);
  }

}