<section class="firmatario-viewer d-flex flex-column">
    <div *ngFor="let firmatario of listaFirmatari; let i = index">
        <div class="row-dettaglio d-flex"> <!--*ngIf="firmatario.descrizione == null"-->
            <span class="label-dettaglio">
                <b>Nome:</b>
            </span>
            <span class="value-dettaglio d-flex align-items-center">
                {{firmatario.nome}} {{firmatario.cognome}}
            </span>
        </div>

        <div class="row-dettaglio d-flex">
            <span class="label-dettaglio">
                <b>Organizzazione:</b>
            </span>
            <span class="value-dettaglio d-flex align-items-center">
                {{firmatario.ente}}
            </span>
        </div>

        <div class="row-dettaglio d-flex">
            <span class="label-dettaglio">
                <b>Codice fiscale:</b>
            </span>
            <span class="value-dettaglio d-flex align-items-center">
                {{firmatario.cf}}
            </span>
        </div>

        <div class="row-dettaglio d-flex">
            <span class="label-dettaglio">
                <b>Valido dal:</b>
            </span>
            <span class="value-dettaglio d-flex align-items-center">
                {{firmatario.validoDal}}
            </span>
        </div>

        <div class="row-dettaglio d-flex">
            <span class="label-dettaglio">
                <b>Scadenza:</b>
            </span>
            <span class="value-dettaglio d-flex align-items-center">
                {{firmatario.validoAl}}
            </span>
        </div>

        <div class="row-dettaglio d-flex" *ngIf="firmatario.descrizione != null">
            <span class="label-dettaglio">
                <b>Ente certificatore:</b>
            </span>
            <span class="value-dettaglio d-flex align-items-center">
                {{firmatario.descrizione}}
            </span>
        </div>    
        <div class="dropdown-divider mt-4 mb-0" *ngIf="listaFirmatari.length > 1 && i < listaFirmatari.length - 1"></div>    
    </div>        
</section>


