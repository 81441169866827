<section class="w-100 h-100 treeview" *ngIf="show">
    <div *ngFor="let element of listAgdUo" >
        <div *ngIf="hasChild(element) || uo" class="element-agd-uo d-flex flex-column" >
            <div class="d-flex align-items-center">
                <div class="spaced-input d-flex mr-1 mr-0 action-icon-treview-agd-uo">
                    <div class="container-icon cursor-pointer" (click)="!element.activeCC && activeCompetenza(element, 'C')">
                        <span class="" [ngClass]="{'active-c-cc' : element.activeC}" >C</span>
                    </div>
        
                    <div class="container-icon cursor-pointer" (click)="!element.activeC && activeCompetenza(element, 'CC')">
                        <span class="" [ngClass]="{'active-c-cc' : element.activeCC}" >CC</span>
                    </div>
                </div>
                <i *ngIf="hasChild(element)" class="cursor-pointer icon-{{element.show ? 'down-open' : 'right-open'}}" (click)="changeShowElement(element)"></i>
                <span class="m-2 cursor-pointer" >
                    <i class="{{element.icona}} color-icon"></i>
                    <span>
                        <span *ngIf="!uo; else childrenUo">{{element.descrizione}}</span><ng-template #childrenUo>{{element.descrizioneRidotta}}</ng-template> ({{element.codice}})
                    </span>
                </span>
            </div>
            <app-elenco-agd-uo-treeview *ngIf="hasChild(element)" [addAgdUoScelto]="addAgdUoScelto" [codicePadre]="element.codice" [descrizionePadre]="''+element.descrizione + ' (' + element.codice + ')'" [listAgdUo]="element.children" [show]="element.show" [uo]="true"></app-elenco-agd-uo-treeview>
        </div>
        
    </div>
</section>
 