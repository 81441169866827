/**
 * Classe utilizzata per la gestione del breadcrumb nel viewer
 */
export class ShowObjViewer{
    showAllegato : boolean = true;
    showMovimenti : boolean = false;
    showDettaglioDoc : boolean = false;
    showDatiFirma : boolean = false;
    showRiferimenti : boolean = false;
    showFlussi : boolean = false;
    showDettaglioEmail : boolean = false;
    showAnnullaProtocollo : boolean = false;

    setAllegato(){
        this.showAllegato = true;
        this.showMovimenti = false;
        this.showDettaglioDoc = false;
        this.showDatiFirma = false;
        this.showRiferimenti = false;
        this.showFlussi = false;
        this.showDettaglioEmail = false;
        this.showAnnullaProtocollo = false
    }

    setMovimenti(){
        this.showAllegato = false;
        this.showMovimenti = true;
        this.showDettaglioDoc = false;
        this.showDatiFirma = false;
        this.showRiferimenti = false;
        this.showFlussi = false;
        this.showDettaglioEmail = false;
        this.showAnnullaProtocollo = false
    }

    setDettaglioDoc(){
        this.showAllegato = false;
        this.showMovimenti = false;
        this.showDettaglioDoc = true;
        this.showDatiFirma = false;
        this.showRiferimenti = false;
        this.showFlussi = false;
        this.showDettaglioEmail = false;
        this.showAnnullaProtocollo = false
    }

    setDatiFirma(){
        this.showAllegato = false;
        this.showMovimenti = false;
        this.showDettaglioDoc = false;
        this.showDatiFirma = true;
        this.showRiferimenti = false;
        this.showFlussi = false;
        this.showDettaglioEmail = false;
        this.showAnnullaProtocollo = false
    }

    setRiferimenti(){
        this.showAllegato = false;
        this.showMovimenti = false;
        this.showDettaglioDoc = false;
        this.showDatiFirma = false;
        this.showRiferimenti = true;
        this.showFlussi = false;
        this.showDettaglioEmail = false;
        this.showAnnullaProtocollo = false
    }

    setFlussi(){
        this.showAllegato = false;
        this.showMovimenti = false;
        this.showDettaglioDoc = false;
        this.showDatiFirma = false;
        this.showRiferimenti = false;
        this.showFlussi = true;
        this.showDettaglioEmail = false;
        this.showAnnullaProtocollo = false
    }

    setAnnullaProtocollo(){
        this.showAllegato = false;
        this.showMovimenti = false;
        this.showDettaglioDoc = false;
        this.showDatiFirma = false;
        this.showRiferimenti = false;
        this.showFlussi = false;
        this.showDettaglioEmail = false;
        this.showAnnullaProtocollo = true
    }

    /**
     * In questo caso il set del dettaglio email dipende dal valore di showFlussi
     * perchè il dettaglio delle mail deve essere visualizzato solo se viene chiamato dai flussi,
     * questo vuol dire che quando viene chiamato questo metodo showDettaglioEmail viene settato a prescindere a true
     */
    setDettaglioEmail(){
        this.showDettaglioEmail = this.showFlussi;
    }



}