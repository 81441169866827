<div class="form-group w-100 position-relative" [ngClass]="{'text-danger' : conditionError}" >
    <label *ngIf="labelInput.length != 0">{{labelInput}}</label>

    <input #searchInput type="text" class="form-control" 
            aria-describedby="passwordRequired" 
            placeholder="" 
            [disabled]="disabled"
            [ngClass]="{'border-danger' : conditionError}" 
            (focus)="showHiddenList()"
            (blur)="showHiddenList()"
            (keyup)="filterList()"
            >
    <small *ngIf="conditionError" id="passwordRequired" class="form-text text-danger">{{textError}}</small>

    <!--Parte della lista-->
    <section class="d-flex position-absolute" style="width: 140% !important;" *ngIf="showList"><!---->
        <div class="d-flex w-100 flex-column list-group container-list" *ngIf="listToView.length > 0;else emptyListTag"><!-- -->
            <div class="d-flex cursor-pointer list-group-item list-group-item-action" *ngFor="let element of listToView" (click)="selectItem(element)">
                <span class="element-label">{{element[keyword]}}</span>
            </div>
        </div>

        <ng-template #emptyListTag>
            <div class="d-flex cursor-pointer list-group-item list-group-item-action disabled">
                <span>{{textEmptyList}}</span> 
            </div>
        </ng-template>
    </section>
</div>