import { Component, Input, OnInit } from '@angular/core';
import { VerifyLoginResult } from './../../../model/VerifyLoginResult';
import { ArchiPROInfoModel } from './../../../model/ArchiPROInfoModel';
import { RequestService } from './../../../service/requestService/request.service';

@Component({
  selector: 'app-seleziona-uo-aoo',
  templateUrl: './seleziona-uo-aoo.component.html',
  styleUrls: ['./seleziona-uo-aoo.component.scss']
})
export class SelezionaUoAooComponent {


  @Input()
  user : VerifyLoginResult;

  listUo : any = [];


  constructor(private requestService : RequestService) 
  { 
    
  }

  ngAfterContentInit(){
    this.listUo = [];
    if(this.user.aooAssociati.length == 1)
    {
      this.user.codAoo = this.user.aooAssociati[0].cod_aoo;
      this.getUoByCodAoo(this.user.codAoo);
    }
    else if(this.user.aooAssociati.length == 0)
    {
      this.listUo = this.user.uoAssociati;
    }
  }

  getUoByCodAoo(codAoo : string)
  {
    if(codAoo != null){

      this.listUo = [];
      this.requestService.getRequestClient("getAgdUoFromAOO?cod_aoo=" + codAoo, "")
        .subscribe(response => {
          
          if(response != null)
          {
            if(response.status)
            {
              this.listUo = [];
              for (let key in response.obj) 
              {
                  this.listUo.push({
                    "codUO" :  key,
                    "desUO" : response.obj[key]
                  });
              }
            }
          }
        
        });
    }
    else
    {
      this.user.codUOAD = null;
      this.listUo = [];
    }

  }
}
