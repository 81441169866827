export class Collocazione{
    col1 : number;
    col2 : number;
    col3 : number;
    col4 : number;
    col5 : number;
    col6 : number;

    constructor(obj?){
        if(obj)
        {
            this.col1 = obj.col1;
            this.col2 = obj.col2;
            this.col3 = obj.col3;
            this.col4 = obj.col4;
            this.col5 = obj.col5;
            this.col6 = obj.col6;
        }
        else
        {
            this.col1 = 0;
            this.col2 = 0;
            this.col3 = 0;
            this.col4 = 0;
            this.col5 = 0;
            this.col6 = 0;
        }
        
    }
}