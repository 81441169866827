
<section class="d-flex flex-column w-100 h-100">
  <div *ngIf="showAlert" (click)="showAlert = false"  class="px-3 my-4 w-100">
    <div class="alert alert-{{classAlert}} text-center cursor-pointer w-100" role="alert">
      <span>{{messageAlert}}</span>
    </div>
  </div>

  <form [formGroup]="formPass" (ngSubmit)="submit()">

    <div class="form-group px-3 pt-2">
      <label for="oldPassword" [ngClass]="{'text-danger' : oldPassword.touched && oldPassword.invalid}">Vecchia password</label>
      <input formControlName="oldPassword" id="oldPassword" type="password" class="form-control" [ngClass]="{'border-danger' : oldPassword.touched && oldPassword.invalid }">
      <ng-container *ngIf="oldPassword.touched && oldPassword.invalid">
          <small *ngIf="oldPassword.errors.required" id="oldPassword" class="form-text text-danger">Campo obbligatorio</small>
      </ng-container>
    </div>

    <div class="form-group px-3">
      <label for="newPassword" [ngClass]="{'text-danger' : newPassword.touched && newPassword.invalid}">Nuova password</label>
      <input formControlName="newPassword" id="newPassword" type="password" class="form-control" [ngClass]="{'border-danger' : newPassword.touched && newPassword.invalid }">
      <ng-container *ngIf="newPassword.touched && newPassword.invalid">
          <small *ngIf="newPassword.errors.required" id="newPassword" class="form-text text-danger">Campo obbligatorio</small>
          <small *ngIf="newPassword.errors.pattern" id="newPassword" class="form-text text-danger">La password deve essere di almeno 8 caratteri e deve contenere almeno 1 lettera maiuscola, 1 minuscola, 1 numero e 1 carattere speciale</small>
          <small *ngIf="newPassword.errors.notEqualOldAndNewPassword" id="newPassword" class="form-text text-danger">La vecchia e la nuova password non possono essere uguali</small>

      </ng-container>
    </div>

    <div class="form-group px-3">
      <label for="confirmPassword" [ngClass]="{'text-danger' : confirmPassword.touched && confirmPassword.invalid}">Conferma password</label>
      <input formControlName="confirmPassword" id="confirmPassword" type="password" class="form-control" [ngClass]="{'border-danger' : confirmPassword.touched && confirmPassword.invalid }">

      <ng-container *ngIf="confirmPassword.touched && confirmPassword.invalid">
        <small *ngIf="confirmPassword.errors.required" id="confirmPassword" class="form-text text-danger">Campo obbligatorio</small>
        <small *ngIf="confirmPassword.errors.confirmedValidator" id="confirmPassword" class="form-text text-danger">I campi nuova e conferma password devono essere uguali</small>
      </ng-container>
    </div>

    <div class="modal-footer" *ngIf="showFooter; else showClose">
      <button type="submit" class="btn bg-primary-archipro text-white">Salva</button>
      <button type="button" class="btn bg-primary-archipro text-white" data-dismiss="modal">Annulla</button>
    </div>

    <ng-template #showClose>
      <div class="modal-footer">
        <button type="button" class="btn bg-primary-archipro text-white" data-dismiss="modal">Chiudi</button>
      </div>
    </ng-template>

  </form>

</section>
