/**
 * Classe utilizzata per salvare la sessione utente dopo il login
 */
export class VerifyLoginResult{

    userName : string;
    matricola : number;
    nome : string;
    cognome: string;
    superUser : boolean = false;
    uoGerarchView : boolean = false;
    abilitazioneArchipro : boolean = false;
    abilitazioneProcedo : boolean = false;
    abilitazioneInfatti : boolean = false;
    abilitazioneProcedimentiAmm : boolean = false;
    uoAssociati : any = [];
    aooAssociati : any = [];
    codRuolo : string;
    aoo : string;
    codAoo : string;
    codUOAD : string;
    codUO : string;
    codAD : string;
    isRettore : boolean;
    isAssessore : boolean;

    descrAgd : string = "";
    descrUo : string = "";
    isAg : boolean = false;


    constructor(){

    }

    setUser(verifyLoginResult : any)
    {
        this.userName = verifyLoginResult.userName;
        this.matricola = verifyLoginResult.matricola;
        this.nome = verifyLoginResult.nome;
        this.cognome = verifyLoginResult.cognome;
        this.superUser = verifyLoginResult.superUser;
        this.uoGerarchView = verifyLoginResult.uoGerarchView;
        this.abilitazioneArchipro = verifyLoginResult.abilitazioneArchipro;
        this.abilitazioneProcedo = verifyLoginResult.abilitazioneProcedo;
        this.abilitazioneInfatti = verifyLoginResult.abilitazioneInfatti;
        this.abilitazioneProcedimentiAmm = verifyLoginResult.abilitazioneProcedimentiAmm;
        this.uoAssociati = verifyLoginResult.uoAssociati;
        this.aooAssociati = verifyLoginResult.aooAssociati;
        this.codRuolo = verifyLoginResult.codRuolo;
        this.aoo = verifyLoginResult.aoo;
        this.codAoo = verifyLoginResult.codAoo;
        this.codUOAD = verifyLoginResult.codUOAD;
        this.codAD = verifyLoginResult.codUOAD != null ? verifyLoginResult.codUOAD.split("_")[0]:null;
        this.codUO = verifyLoginResult.codUOAD != null ? verifyLoginResult.codUOAD.split("_")[1]:null;
        this.isRettore = verifyLoginResult.isRettore;
        this.isAssessore = verifyLoginResult.isAssessore;
    }

    setIsAg(isAg : boolean) : void{
        this.isAg = isAg;
    }
}
