import { Component, OnInit, HostListener } from '@angular/core';
import { ResizeService } from './../../../service/resize/resize.service';
import { SCREEN_SIZE } from 'src/app/service/resize/screen-size.enum';
import { Utility } from 'src/app/Utility/Utility';
import { Observable } from 'rxjs';
import { GlobalObj } from 'src/app/service/globalService/GlobalObj';
import { GlobalService } from 'src/app/service/globalService/global.service';
import { RequestService } from 'src/app/service/requestService/request.service';
import { OpzioniMenu } from 'src/app/model/OpzioniMenu';
import { UserFunctionService } from 'src/app/service/userFunctionService/user-function.service';
import { StaticProperty } from 'src/app/Utility/StaticProperty';
import { VerifyLoginResult } from 'src/app/model/VerifyLoginResult';


@Component({
  selector: 'app-scrivania',
  templateUrl: './scrivania.component.html',
  styleUrls: ['./scrivania.component.scss']
})
export class ScrivaniaComponent implements OnInit {

  size : SCREEN_SIZE;

  showApp : boolean = false;
  menu : OpzioniMenu[];
  public static functionMap : Map<string, number> = new Map();

  public static showSidebar = false;

  constructor(private requestService : RequestService, private resizeSvc: ResizeService, private globalService : GlobalService, private userFunctionService : UserFunctionService) {
    this.size = this.resizeSvc.onResize();
    this.checkSizeMobileSidebar();
  }

  @HostListener("window:resize", [])
  private onResizeFunction() {
    this.size = this.resizeSvc.onResize();
    this.checkSizeMobileSidebar();
  }

  ngOnInit(): void {
    this.showApp = Utility.checkUtenteSessione();
    if(!this.showApp){
      window.location.href = "/ArchiPRO/";
    }

    this.getMenuOption();
    this.checkIfUserIsAg();
  }

  checkIfUserIsAg(){
    this.requestService.postRequest("isAg", null,
      success => {
        let user = Utility.localStorageGet(StaticProperty.UTENTE_SESSIONE)
        user.isAg = success.obj.isAg;
        Utility.localStorageSet(StaticProperty.UTENTE_SESSIONE, user);
      }
    )

  }

  /**
   * Metodo che prende dinamicamente dal server le voci di menu da inserire nella sidebar
   */
  getMenuOption() : void{
    this.requestService.postRequest("getOpzioniMenu", {},
      (success) => {
        let list : OpzioniMenu[] = success.obj.map((element) => new OpzioniMenu(element));
        this.menu = this.buildMenuSidebar(list.filter((element) => {return !element.idPadre && element.visibile}), list);
        success.obj.map((element) =>  ScrivaniaComponent.functionMap.set(element.voce, element.posizione));

        this.menu.map(element => {
          let baseLink = "/modulo/" + element.voce;
          if(element.children.length > 0)
          {
            this.buildRouterLink(element.children, baseLink);
          }
        })
      }
    )
  }

  /**
   * Funzione ricorsiva utilizzata per costruire l'albero con padri e figli
   * in modo da poter creare il menu della sidebar dinamicamente (le voci di menu vengono passate dal server).
   * La prima volta che questa funzione viene chiamata, deve essere passata la lista contenenti solo i padri
   * che non hanno a loro volta altri padri (le voci del primo livello del menu), poi ricorsivamente chiama se stessa
   * passando per creare la stessa struttura se un figlio a a sua volta dei figli
   *
   * @param menu : questa è la lista contenente i padri (e le foglie padri)
   * @param list : lista da dove costruire la struttura padre figlio
   * @returns    : viene tornata la lista ordinata in ordine crescente tramite l'attributo "ordine" di "OpzioniMenu"
   */
  buildMenuSidebar(menu : OpzioniMenu[], list : OpzioniMenu[]) : OpzioniMenu[]{
    menu.map((voceMenu) => {
      voceMenu.children = this.buildMenuSidebar(list.filter(child => {return child.idPadre == voceMenu.id && child.visibile}), list);
    })
    return menu.sort((a, b) => {return a.ordine > b.ordine ? 1 : -1});
  }

  /**
   * Metodo utilizzato per costruire il routerLink di ogni elemento terminale del menu
   *
   * @param list : lista dove controllare se costruire o meno il routerlink
   * @param baseLink : elemento base del link
   */
  buildRouterLink(list : OpzioniMenu[], baseLink : string) : void{
    list.map(element => {
      if(element.children.length > 0)
      {
        this.buildRouterLink(element.children, baseLink);
      }
      else
      {
        element.routerLink  = this.getAbilitato(element.posizione) ? baseLink + "/" + element.voce : "";
      }

    })
  }


  /**
   * Metodo che controlla la larghezza della finestra e setta la visualizzazione della sidebar
   */
  checkSizeMobileSidebar(){
    if(this.size < 3)
    {
      ScrivaniaComponent.setShowSidebar(false);
    }
    else
    {
      ScrivaniaComponent.setShowSidebar(true);
    }
  }

  getShowSidebar()
  {
    return ScrivaniaComponent.showSidebar;
  }

  static setShowSidebar(showSidebar : boolean)
  {
    ScrivaniaComponent.showSidebar = showSidebar;
  }

  static getShowSidebar(){
    return ScrivaniaComponent.showSidebar;
  }



  /**
   * Funzione che tramite il servizio userfunction dice se una determinata funzione
   * specificata attraverso un indice è abilitata all'utente loggato o no
   * @param i : indice di posizione della funzione
   * @returns
   */
  getAbilitato = (i : number) : Boolean => {
    return this.userFunctionService.getAccessFunction(i);
  }

  static getAbilitatoByNameFunction(nameFunction : string) : Boolean {
    return UserFunctionService.getAccessFunction(ScrivaniaComponent.functionMap.get(nameFunction));
  }

}
