import { Component, OnInit, HostListener, Input } from '@angular/core';
import { SCREEN_SIZE } from 'src/app/service/resize/screen-size.enum';
import { ResizeService } from './../../../service/resize/resize.service';
import { NavigationEnd, Router } from '@angular/router';
import { VerifyLoginResult } from './../../../model/VerifyLoginResult';
import { getLabelAgd, getLabelUo, Utility } from './../../../Utility/Utility';
import { StaticProperty } from 'src/app/Utility/StaticProperty';
import { Observable } from 'rxjs';
import { OpzioniMenu } from 'src/app/model/OpzioniMenu';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

  getLabelAgd : () => string = getLabelAgd;
  getLabelUo : () => string = getLabelUo;

  menuElementActive : string = "";
  menusubElementActive : string = "";
  size : SCREEN_SIZE;
  user : VerifyLoginResult;

  @Input()
  getAbilitato = (i : number) => Boolean;

  @Input()
  menu : OpzioniMenu[];

  constructor(private resizeSvc: ResizeService, private router: Router) {
    this.size = this.resizeSvc.onResize();

    this.router.events.subscribe((ev) => {
      if (ev instanceof NavigationEnd) { this.refreshActiveElement();}
    });

    this.user = Utility.localStorageGet(StaticProperty.UTENTE_SESSIONE);
    if(!this.user) this.user = new VerifyLoginResult();
    else if(this.user.uoAssociati.length > 0)
    {
      let uoSelezionato = Utility.getUoSelezionato();
      this.user.descrAgd = uoSelezionato.desAD;
      this.user.descrUo = uoSelezionato.desUO;
    }

  }


  canDeactivate(): Observable<boolean> | boolean{

    return !(this.size < 3);
  }

  workInProgress(){
    alert("Work in progress");
  }

  refreshActiveElement(){

    this.menuElementActive = this.router.url.substring(1).split("/")[1];
    this.menusubElementActive = this.router.url.substring(1).split("/")[2];
  }

  ngOnInit(): void {
    this.refreshActiveElement()
  }

  ngAfterViewInit()
  {

  }

  /**
   * Funzione utilizzata per settare le icone in base alla funzione che arriva dal server per costruire le voci di menu
   * @param functionName  : nome della funzione (voce) del menu
   * @returns             : la classe per settare l'icona corretta
   */
  getIconFromMapFunction(functionName : string) : string{
    return StaticProperty.MAP_FUNCTION_ICON_SIDEBAR[functionName];
  }

  @HostListener("window:resize", [])
  private onResizeFunction() {
    this.size = this.resizeSvc.onResize();
  }


}
