import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { StaticProperty } from 'src/app/Utility/StaticProperty';
import { Utility } from 'src/app/Utility/Utility';
import { environment } from 'src/environments/environment';
import { GlobalObj } from './GlobalObj';

@Injectable({
  providedIn: 'root'
})
export class GlobalService {

  GLOBAL : GlobalObj;

  constructor(private http : HttpClient) {
    this.GLOBAL = Utility.getGlobali();
    this.checkGlobalObj();
  }

  getGlobal(){
    this.checkGlobalObj();
    return this.GLOBAL;
  }

  async checkGlobalObj(){
    if(!Utility.checkObj(this.GLOBAL))
    {
      await this.postRequestAsync("getGlobaliClient", {} ,
        (success) => {
          this.GLOBAL = new GlobalObj(success.obj);
          Utility.localStorageSet(StaticProperty.GLOBALI, new GlobalObj(success.obj));

        }
      );
    }
    else
    {
      if(!this.GLOBAL)
      {
        this.GLOBAL = new GlobalObj();
      }

    }
  }

  async postRequestAsync(additionalUrl: any, params: any, successRequest : (value: any) => void, errorRequest? : (value?: any) => void ) {

    if(Utility.checkUtenteSessione())
    {
      let payload = !params ? {} : params;
      let headers = new HttpHeaders({
        'Content-Type': 'application/json; charset=utf-8',
        responseType: 'arraybuffer',
        observe: 'response' as 'response'

      });

      let options = {
        data: params,
        headers: headers,
        whitCredential : "true"
      };

      let objResponse : any;

      await this.http.post(environment.url + additionalUrl, params, options).toPromise().then(
        response => {
          if(response != null)
          {
            objResponse = response;
          }
        }
      );

      if(objResponse.status){
        successRequest(objResponse);
      }
      else if (objResponse.message == "MS_1000"){
        alert(Utility.getMessageByCode(objResponse.message));
        //this.logout();
      }
      else
      {
        errorRequest ? errorRequest() : null;
        alert(Utility.getMessageByCode(objResponse.message));
      }

    }
    else
    {

    }
  }
}
