import { Utility } from "../Utility/Utility";
import { Referente } from "./Referente";

export class GruppoReferente{
    nome : string;
    codice : number;
    referenti : Referente[] = [];

    constructor(obj : any)
    {
        this.nome = obj.nome;
        this.codice = obj.codice;

        if(Utility.checkObj(obj.referenti))
        {
            obj.referenti.forEach(element => {
                this.referenti.push(new Referente(element));
            })
        }
    }

}