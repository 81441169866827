import { Component, ComponentFactoryResolver, EventEmitter, Input, Output, Type, ViewChild } from '@angular/core';
import { OptionModal } from './OptionModal';
import { DinamicComponentDirective } from './../../../directive/DinamicComponentDirective/dinamic-component.directive';


declare var $: any;

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent {

  @Input() idModal : string = "std";
  option : OptionModal = new OptionModal();

  @Output() emitter : EventEmitter<any> = new EventEmitter();

  @ViewChild(DinamicComponentDirective, {static: true}) adHost!: DinamicComponentDirective;

  constructor(private componentFactoryResolver: ComponentFactoryResolver) {}

  /**
   * Funzione che ti permette di inizializzare la modale
   *
   * @param option : Oggetto dove vengono inseriti le impostazioni della modale
   */
  initModal(option : OptionModal) : void
  {
    this.option = option;
    if(this.option.getLoadDynamicComponent())
    {
      this.loadComponent(this.option.dynamicComponnet, this.option.objParameterComponent);
    }
    else
    {
      this.adHost.viewContainerRef.clear();
    }
  }

  emitChoiceValue(value : any){
    this.emitter.emit(value);
    this.closeModal();
  }

  /**
   * Metodo che ti permette di aprire la modale
   */
  openModal() : void {
    setTimeout(() => {$('#dynamicModal-'+this.idModal).modal({
      keyboard: false
    });} , 250);
  }

  /**
   * Metodo che chiude la modale
   */
  closeModal() : void{
    $('#dynamicModal-'+this.idModal).modal('toggle');
  }

   /**
   * Metodo che chiude la modale
   */
  static closeModal() : void{
      $('#dynamicModal-' + new ModalComponent(null).idModal).modal('toggle');
    }


  /**
   * Funzione che controlla se c'è almeno un pulsante da visualizzare nel footer, se presente
   * viene inserito all'interno della modale anche il footer
   */
  checkShowFooterModal() : boolean
  {
    return this.option.showSaveButton || this.option.showCustomButton || this.option.showCloseButton || (this.option.valueYesChoiceButton != null && this.option.valueNoChoiceButton != null);
  }

  /**
   * Metodo che ha il compito di caricare all'interno della modale un'eventuale component esterno e l'oggetto del compomente da chiamare
   *
   * @param classComponent : classe del componente che deve essere caricato nella modale
   * @param objParameterComponent : oggetto dove all'interno sono presenti funzioni e variabili/oggetti del componente da chiamare
   */
  loadComponent(classComponent: Type<any>, objParameterComponent : any ){
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(classComponent);
    const viewContainerRef = this.adHost.viewContainerRef;
    viewContainerRef.clear();
    const componentRef = viewContainerRef.createComponent<any>(componentFactory);

    if(objParameterComponent != null)
    {
      componentRef.instance.objParameterComponent = objParameterComponent;
    }
  }
}
