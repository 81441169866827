<section class="h-100 w-100 d-flex flex-column">

    <div class="w-100 d-flex justify-content-between align-items-center mb-3" [ngClass]="{'flex-column' : size < 3}">
        <h5>Elenco movimenti ({{docNumString}})</h5>

        <div class="d-flex justify-content-end">
            <div class="form-group p-1 mb-0">
                <!--<label for="intervallo" class="text-primary-archipro">Tipo documento</label>-->
                <select class="form-control max-width-340" [(ngModel)]="tempTipoElencoMovimenti" >
                    <option class="text-primary-archipro placeholder-select" disabled>Tipo movimenti</option>
                    <option [ngValue]="'competenza'">Per competenza</option>
                    <option [ngValue]="'conoscenza'">Per conoscenza</option>
                </select>
            </div>
            <div class="p-1">
                <button type="button" (click)="getListaMovimenti()" class="btn justify-content-center d-flex bg-primary-archipro text-white">
                    <i class="icon-search"></i>Cerca
                </button>
            </div>
        </div>
    </div>

    <div *ngIf="!searchInProgress; else loading"  class="w-100 d-flex flex-column h-100">

        <div *ngIf="size > 2; else mobile" class="w-100 h-100">
            <table *ngIf="listaMovimenti.length > 0; else nessunRisultato" class="table table-striped border">
                <thead>
                  <tr>
                    <th *ngFor="let nameColumn of theadNameColumn[tipoElencoMovimenti]" scope="col">{{nameColumn}}</th>
                  </tr>
                </thead>
                <tbody *ngIf="tipoElencoMovimenti == 'competenza'">
                    <tr *ngFor="let movimento of listaMovimenti">
                        <td>
                            {{movimento.codOper}}
                        </td>
                        <td>
                            {{movimento.data | dataStringTransform}}
                        </td>
                        <td>
                            {{movimento.ora}}
                        </td>
                        <td>
                            <span *ngIf="movimento.codOper != 'INSERITO'">{{movimento.desTras}} ({{movimento.codTras}}) {{movimento.desTrasUO}} ({{movimento.codTrasUO}})</span>
                        </td>

                        <td>
                            {{movimento.desRice}} ({{movimento.codRice}}) {{movimento.desRiceUO}} ({{movimento.codRiceUO}})
                        </td>

                        <td>
                            {{movimento.motivo}}
                        </td>

                        <td>
                            {{movimento.utenteNome}} ({{movimento.userMatr}})
                        </td>
                    </tr>
                </tbody>
                <tbody *ngIf="tipoElencoMovimenti == 'conoscenza'">
                    <tr *ngFor="let movimento of listaMovimenti">
                        <td>
                            {{movimento.DATA_INIZIO | dataStringTransform}}
                        </td>
                        <td>
                            <span *ngIf="movimento.DATA_FINE != ' ----- '">{{movimento.DATA_FINE | dataStringTransform}}</span>
                        </td>
                        <td>
                            {{movimento.AGD}} {{movimento.UO}}
                        </td>
                        <td>
                            {{movimento.UTENTE_I ? '(I) ' + movimento.UTENTE_I : ''}} {{movimento.UTENTE_F != ' ' ? '(F) ' + movimento.UTENTE_F : ''}}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <ng-template #mobile>

            <div id="accordionMovimenti">

                <div class="card" *ngFor="let movimento of listaMovimenti;  let i = index">
                  <div class="card-header" [attr.id]="'movimento' + i + 'heading'">
                    <h5 class="mb-0">
                      <button *ngIf="tipoElencoMovimenti == 'competenza'" class="btn w-100 p-0 d-flex flex-column" data-toggle="collapse" [attr.data-target]="'#collapse' + i + 'movimento'" aria-expanded="true" [attr.aria-controls]="'collapse' + i + 'movimento'">
                        <div class="d-flex w-100 justify-content-between align-items-center">
                            <h6 class="mt-1 d-flex align-items-center">{{movimento.codOper}}</h6>
                            <small class=""> {{movimento.data | dataStringTransform}} {{movimento.ora}}</small>
                        </div>
                        <small *ngIf="movimento.codOper != 'INSERITO'">{{movimento.desTras}} ({{movimento.codTras}}) {{movimento.desTrasUO}} ({{movimento.codTrasUO}})</small>
                      </button>


                      <button *ngIf="tipoElencoMovimenti == 'conoscenza'" class="btn w-100 p-0 d-flex flex-column" data-toggle="collapse" [attr.data-target]="'#collapse' + i + 'movimento'" aria-expanded="true" [attr.aria-controls]="'collapse' + i + 'movimento'">
                        <div class="d-flex w-100 justify-content-between align-items-center">
                            <h6 class="mt-1 d-flex align-items-center">{{movimento.UTENTE_I ? '(I) ' + movimento.UTENTE_I : ''}} {{movimento.UTENTE_F != 'null null' ? '(F) ' + movimento.UTENTE_F : ''}}</h6>

                        </div>
                        <div class="d-flex">
                            <small>{{movimento.DATA_INIZIO | dataStringTransform}}</small>
                            <small *ngIf="movimento.DATA_FINE != ' ----- '"> - {{movimento.DATA_FINE | dataStringTransform}}</small>
                        </div>

                      </button>

                    </h5>
                  </div>

                  <div [attr.id]="'collapse' + i + 'movimento'" class="collapse {{i == 0 ? 'show' : ''}}" [attr.aria-labelledby]="'movimento' + i + 'heading'" data-parent="#accordionMovimenti">
                    <div class="card-body">
                        <div class="d-flex flex-column" style="font-size: 14px;" *ngIf="tipoElencoMovimenti == 'competenza'">
                            <div class="d-flex column-element-mobile">
                                <span class="w-25 font-weight-bold h-100 name-column-mobile">Operazione :</span>
                                <span class="w-100 text-left h-100">{{movimento.codOper}}</span>
                            </div>

                            <div class="d-flex column-element-mobile">
                                <span class="w-25 font-weight-bold h-100 name-column-mobile">Data e ora :</span>
                                <span class="w-100 text-left h-100">{{movimento.data | dataStringTransform}} - {{movimento.ora}}</span>
                            </div>

                            <div class="d-flex column-element-mobile" *ngIf="movimento.codOper != 'INSERITO'">
                                <span class="w-25 font-weight-bold h-100 name-column-mobile">Da :</span>
                                <span class="w-100 text-left h-100">{{movimento.desTras}} ({{movimento.codTras}}) {{movimento.desTrasUO}} ({{movimento.codTrasUO}})</span>
                            </div>

                            <div class="d-flex column-element-mobile">
                                <span class="w-25 font-weight-bold h-100 name-column-mobile">A :</span>
                                <span class="w-100 text-left h-100">
                                    {{movimento.desRice}} ({{movimento.codRice}}) {{movimento.desRiceUO}} ({{movimento.codRiceUO}})
                                </span>
                            </div>

                            <div class="d-flex column-element-mobile">
                                <span class="w-25 font-weight-bold h-100 name-column-mobile">Motivo :</span>
                                <span class="w-100 text-left h-100">{{movimento.motivo}}</span>
                            </div>

                            <div class="d-flex column-element-mobile">
                                <span class="w-25 font-weight-bold h-100 name-column-mobile">Eseguito da :</span>
                                <span class="w-100 text-left h-100">{{movimento.utenteNome}} ({{movimento.userMatr}})</span>
                            </div>
                        </div>

                        <div class="d-flex flex-column" style="font-size: 14px;" *ngIf="tipoElencoMovimenti == 'conoscenza'">
                            <div class="d-flex column-element-mobile">
                                <span class="w-25 font-weight-bold h-100 name-column-mobile">Dal :</span>
                                <span class="w-100 text-left h-100">{{movimento.DATA_INIZIO | dataStringTransform}}</span>
                            </div>

                            <div class="d-flex column-element-mobile" *ngIf="movimento.DATA_FINE != ' ----- '">
                                <span class="w-25 font-weight-bold h-100 name-column-mobile">Al :</span>
                                <span class="w-100 text-left h-100">{{movimento.DATA_FINE | dataStringTransform}}</span>
                            </div>

                            <div class="d-flex column-element-mobile">
                                <span class="w-25 font-weight-bold h-100 name-column-mobile">{{getLabelAgd()}} - {{getLabelUo()}} :</span>
                                <span class="w-100 text-left h-100">{{movimento.AGD}} {{movimento.UO}}</span>
                            </div>

                            <div class="d-flex column-element-mobile">
                                <span class="w-25 font-weight-bold h-100 name-column-mobile">Dal :</span>
                                <span class="w-100 text-left h-100">{{movimento.UTENTE_I ? '(I) ' + movimento.UTENTE_I : ''}} {{movimento.UTENTE_F != 'null null' ? '(F) ' + movimento.UTENTE_F : ''}}</span>
                            </div>
                        </div>
                    </div>
            </div>
                </div>
              </div>

        </ng-template>



        <ng-template #nessunRisultato >
            <div class="h-100 w-100 d-flex justify-content-center align-items-center" *ngIf="!searchInProgress; else loading">
                <h2>Non è stato trovato nessun risultato con i filtri selezionati</h2>
            </div>
        </ng-template>
    </div>

    <ng-template #loading>
        <app-loading></app-loading>
    </ng-template>


</section>
