import { NgbDate } from "@ng-bootstrap/ng-bootstrap";
import { StaticProperty } from 'src/app/Utility/StaticProperty';
import { CustomDateParserFormatterService } from "../service/CustomDateParserFormatter/custom-date-parser-formatter.service";
import { GlobalObj } from "../service/globalService/GlobalObj";

export class Utility{
    constructor(){

    }

    static localStorageSet(key: string, value: any) {
        localStorage.setItem(key, JSON.stringify(value));
    }

    static localStorageGet(key: string) {
        return JSON.parse(localStorage.getItem(key));
    }

    static localStorageDelete(key: string) {
        localStorage.removeItem(key);
    }

    static getMessageByCode(code: string)
    {
        return JSON.parse(this.localStorageGet(StaticProperty.MESSAGGI_SISTEMA))[code];
    }

    static getGlobali() : GlobalObj{
        let obj : any = this.localStorageGet(StaticProperty.GLOBALI);
        if(!this.checkObj(obj))
        {
            return null;
        }
        else
        {
            return new GlobalObj(obj);
        }

    }

    /**
     * Funzione che controlla se esiste l'utente in sessione nel browser
     *
     * @returns true se esiste, false se non esiste
     */
    static checkUtenteSessione() : boolean{
        let utenteSessione = this.localStorageGet(StaticProperty.UTENTE_SESSIONE);
        return utenteSessione != null && utenteSessione != undefined;
    }

    /**
     * Calcola il numero di pagina in base al resultset e al numero di righe
     *
     * @param totRecords : numero totale restituito dal resultset
     * @param numRows : numero di righe che l'utente vuole vedere
     * @returns : il numero di pagine totali calcolate per eccesso
     */
    static calculateNumberOfPages(totRecords : number, numRows : number)
    {
        return Math.ceil(totRecords/numRows);
    }

    /**
     * Funzione che costruisce la lista utilizzata per costruire l'oggetto di paginazione
     *
     * @param numPage : numero di pagine totali del resultset paginato;
     * @param currentPage : pagina corrente selezionata dall'utente;
     * @returns : la lista dei singoli quadratini della paginazione
     */
    static buildPaginationObject(numPage, currentPage)
    {
        let listObjPage = [];
        let delta = 1
        let left = currentPage - delta;
        let right = currentPage + delta + 1;
        let range = []
        let l;

        for (let i = 1; i <= numPage; i++) {
            if (i == 1 || i == numPage || i >= left && i < right) {
                range.push(i);
            }
        }

        for (let i of range) {

            if (l) {

                if (i - l === 2) {
                    listObjPage.push({
                    index : l+1,
                    value : l+1,
                    clickable : true
                    });
                }
                else if (i - l !== 1)
                {
                listObjPage.push(
                    {
                    index : null,
                    value : "...",
                    clickable : false
                    }
                );
                }
            }
            listObjPage.push({
            index : i,
            value : i,
            clickable : true
            });
            l = i;
        }

        return listObjPage;
    }

    /**
     * Funzione che restituisce una stringa contenente numero e anno di protocollo in modo da identificare nel mobile
     * l'elemento di accordione che deve essere mostrato oppure no
     *
     * @param numAnnoProt   : stringa contenente numero e anno di protocollo
     * @returns             : stringa pulita in modo da far funzionare l'accordion
     */
    static cleanForAccordionNumAnnoProt(numAnnoProt: any) : string
    {
        return "accordion" + numAnnoProt.replaceAll(/\s/g, "").replaceAll("/","-").replaceAll(".","-");
    }

    static deepCopyObject(objToCopy : any) : any{
        return JSON.parse(JSON.stringify(objToCopy));
    }

    /**
     * Converte la data passata dal server al formato NgbDate
     * per far visualizzare la data correttamente dai datapicker
     *
     * @param date : Stringa contenente la data in formato YYYY-MM-DD
     * @returns : torna un oggetto di tipo NgbDate compatibile con i datapicker
     */
    static convertDateServerToNgbDate(date : string, slash? : boolean) : NgbDate{
        let dataConvertita : NgbDate;
        try {
            let temp = date.split(slash ? "/" : "-").map(Number);
            if(!slash)
              dataConvertita = new NgbDate(temp[0], temp[1], temp[2]);
            else
              dataConvertita = new NgbDate(temp[2], temp[1], temp[0]);
        } catch (error) {
            console.error(error);
            dataConvertita = null;
        } finally {
            return dataConvertita;
        }
    }

    /**
     * Funzione che torna il nome dell'icona da far vedere a seconda del formato del file presente nel nome
     *
     * @param fileName  : nome del file
     * @returns         : nome dell'icona da far visualizzare
     */
    static getFileClass(fileName : string) : string{

        if(fileName.toLowerCase().endsWith(".p7m")){
           return "icon-doc-certificate";
        }else if(fileName.toLowerCase().endsWith(".pdf")){
          return "icon-file-pdf";
        }else if(fileName.toLowerCase().endsWith(".doc")||fileName.toLowerCase().endsWith(".docx")){
          return "icon-file-word";
        }else if(fileName.toLowerCase().endsWith(".xls")||fileName.toLowerCase().endsWith(".xlsx")){
          return "icon-file-excel";
        }else if(fileName.toLowerCase().endsWith(".ppt")||fileName.toLowerCase().endsWith(".pptx")){
          return "icon-file-powerpoint";
        }else if(fileName.toLowerCase().endsWith(".tiff")||fileName.toLowerCase().endsWith(".png")||fileName.toLowerCase().endsWith(".jpg")){
          return "icon-file-image";
        }else{
          return "icon-doc-text";
        }
    }

    /**
     * Metodo che controlla se un oggetto è instanziato
     * @param obj   : oggetto da controllare
     * @returns     : torna true se è instanziato, false se non lo è
     */
    static checkObj(obj : any) : boolean{
        return obj != null && obj != undefined;
    }

    /**
     * Metodo che ti dice se un oggetto è vuoto
     * @param obj   : oggetto da controllare
     * @returns     : true se è vuoto, false se non lo è
     */
    static checkObjIsEmpty(obj : any) : boolean{
        return Object.keys(obj).length === 0
    }

    /**
     * Metodo utilizzato per aprire e chiudere tutte le sezioni aperte in un form
     *
     * @param expand : Variabile che indica l'azione di apertura o di chiusura
     * @param mapSectionOpened : Oggetto dove all'interno ci sono le varie sezioni del form
     */
    static openAllSections(expand : boolean, mapSectionOpened : any){
        for(let key in mapSectionOpened){
            mapSectionOpened[key] = expand;
        }
    }


    /**
     * Funzione che ritorna lo stato di una sezione (true aperto, false chiuso)
     *
     * @param idSection   : id della sezione da verificare
     * @returns           : torna il valore inserito nella mapSectionOpened
     */
    static checkSectionOpened(idSection : string,  mapSectionOpened : any) : boolean{
        return mapSectionOpened[idSection];
    }


    static openSection(idSection : string,  mapSectionOpened : any) : void
    {
      mapSectionOpened[idSection] = !mapSectionOpened[idSection]
    }

    static getStringReferenteByCodReferente(referenteTipo : number) : string{
        return StaticProperty.MAP_COD_TIPO_REFERENTE[referenteTipo];
    }

    static capitalizeText(text : string) : string {
        if (!text) return text;
        return text[0].toUpperCase() + text.slice(1);

      }

      static getCookie(name) {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop().split(';').shift();
      }


  /**
   * Funzione che converte le date della form in formato stringa dd/mm/yyyy tramite il servizio CustomDateParserFormatterService
   * @param obj
   * @returns
   */
  static formattaDateToString(date : any) : any{
    if(date != null && date != undefined && date != "")
      date = CustomDateParserFormatterService.convertNgbDateStructToString(date);
    return date;
  }

  static getUoSelezionato() : any{
    let user = this.localStorageGet(StaticProperty.UTENTE_SESSIONE);
    return user.uoAssociati.find(element => element.codAD == user.codAD);
  }

}

/**
 * Funzione che compara 2 oggetti in base a un campo per l'ordinamento.
 * Di default se non viene specificata la variabile inverted, l'elenvo verrà ordinato
 * in maniera crescente
 *
 * @param a : primo oggetto da fare il compare
 * @param b : secondo oggetto da fare il compare
 * @param field : campo dell'oggetto che serve per la comparazione
 * @param inverted : campo booleano facoltativo che se settato, inverte il valore toranto per permettere di cambiare l'ordine
 * @returns : torna un valore numerico che stabilisce:
 * - Se a < b : -1;
 * - Se a > b : 1;
 * - Se a == b : 0;
 */
 export const compareObjByField = (a : any, b : any, field : string, inverted? : boolean) : number => {
  return (a[field] < b[field]) ? -1 : (a[field] > b[field] ? 1 : 0) * (!inverted ? 1 : -1);
}

export const getLabelAgd = () : string => {
  return Utility.getGlobali()?.PRO_LABEL_UFFICIO_PERSONA ? "Ufficio" : "AGD";
}

export const getLabelUo = () : string => {
  return Utility.getGlobali()?.PRO_LABEL_UFFICIO_PERSONA ? "Persona" : "UO";
}

export const setFileDescription = (file : any) => {
  var name = file.name;
  var description;
  var token;
  //P7M
  if(file.type == "application/pkcs7-mime"){
    token = name.split(".");
    for(let i of token)
      if(i <= token.length-2)
        description += token[i];

  }else{
    description = name.substr(0, name.lastIndexOf("."));
  }

  return description;

}


export const hasCompetenza = (fasc : any) : boolean => {
  let temp = false;
  let uoSelezionato = Utility.getUoSelezionato();
  if(uoSelezionato)
  {
    temp = uoSelezionato.codAD == fasc.codRice?.codiceAGD && uoSelezionato.codUO == fasc.codRice?.uo?.codice

  }
  return temp;
}
