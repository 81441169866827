<section id="albero-titolario-container" class="pt-2 pb-2 h-100">

    <ng-container *ngIf="!pdfFullScreen">
        <ng-container *ngIf="!searchLemme; else searchLemmeActive">
            <div id="albero-titolario-header" class="d-flex pt-2 pb-3">
                <div class="form-group d-flex mr-2 mb-0 spaced-input" style="max-width: 300px;">
                    <input type="text" class="form-control text-center" placeholder="" aria-label="" aria-describedby="basic-addon1"  [(ngModel)]="titolario.titolo1">
                    <input type="text" class="form-control text-center" placeholder="" aria-label="" aria-describedby="basic-addon1"  [(ngModel)]="titolario.titolo2">
                    <input type="text" class="form-control text-center" placeholder="" aria-label="" aria-describedby="basic-addon1"  [(ngModel)]="titolario.titolo3">
                    <input type="text" class="form-control text-center" placeholder="" aria-label="" aria-describedby="basic-addon1"  [(ngModel)]="titolario.titolo4">
                    <input type="text" class="form-control text-center" placeholder="" aria-label="" aria-describedby="basic-addon1"  [(ngModel)]="titolario.titolo5">
                    <input type="text" class="form-control text-center" placeholder="" aria-label="" aria-describedby="basic-addon1"  [(ngModel)]="titolario.titolo6">
                </div>
                <div class="filter-button d-flex justify-content-center align-items-center text-primary-archipro">
                    <i class="icon-search" title="Cerca titolario" (click)="filtraListaTitolario()"></i>
                    <i class="icon-filter" title="Ripristina titolario" (click)="ripristinaTitolario()"></i>
                    <i *ngIf="GLOBALI.PRO_LEMMI" class="icon-list" title="Ricerca per parola chiave" (click)="openCercaLemme()"></i>
                    <i *ngIf="GLOBALI.PRO_PDF_TITOLARIO" class="icon-help" title="Guida" (click)="apriGuida(1)"></i> 
                </div>
            </div>
            <tree-view *ngIf="listObj.length > 0;else loading" 
                [listObj]="listObj" 
                [show]="true" 
                [f]="apriFascicolo" 
                [riporta]="riportaTitolario" 
                [modalMode]="modalMode" 
                [apriGuida]="GLOBALI.PRO_PDF_TITOLARIO ? apriGuida : undefined"></tree-view>
        </ng-container>
        <ng-template #searchLemmeActive>
            <div class="d-flex flex-column w-100 h-100">
                <h6 class="text-primary-archipro mt-2 mb-4">Ricerca per lemma</h6>
                <div class="d-flex w-100 spaced-input align-items-center">
                    <span class="text-primary-archipro cursor-pointer mb-2 white-space-nowrap" (click)="openCercaLemme()"><i class="icon-left-big"></i>Torna al titolario</span>
                    <div class="form-group mb-0 w-100">
                        <input type="text" class="form-control" placeholder="Cerca" [(ngModel)]="searchText" name="fullText">
                    </div>
                    <i class="icon-search cursor-pointer" (click)="cercaTitolario()"></i>
                    <i *ngIf="build_no_functionality" class="icon-help" title="Guida" (click)="apriGuida(1)"></i>
                </div>

                <div *ngIf="listTitolarioRicerca.length > 0;" class=" w-100 d-flex justify-content-end mt-2 mb-2" [ngClass]="{'justify-content-center flex-column' : size<3}">
    
                    <diV class="mr-2 d-flex w-100 align-items-center justify-content-{{size<3 ? 'center' : 'end'}}" [ngClass]="{'justify-content-center' : size<3}">
                        <span class="text-primary-archipro risultat-totali-class">{{elementDa}} - {{elementA}} di {{totRecords}} risultati</span>
                    </diV>
                    <div class="d-flex align-items-center justify-content-{{size<3 ? 'center' : 'end'}}"  *ngIf="numPage > 1" >
                        <nav aria-label="Page navigation example">
                            <ul class="pagination mb-0">
                                <li class="page-item" [ngClass]="{'disabled' : currentPage == 1}">
                                    <div class="page-link text-primary-archipro cursor-pointer" (click)="!searchInProgress && changePage(currentPage - 1)">
                                        <span *ngIf="size>=3" >Precedente</span>
                                        <i *ngIf="size<3" class="icon-left-open"></i>
                                    </div>
                                  </li>
                                <li  *ngFor="let page of listObjPage" class="page-item">
                                  <div class="page-link text-primary-archipro cursor-pointer" (click)="!searchInProgress && page.clickable && changePage(page.index)" [ngClass]="{'active_page text-white' : currentPage == page.index}">{{page.value}}</div>
                                </li>
                                <li class="page-item" [ngClass]="{'disabled' : currentPage == numPage}">
                                    <div class="page-link text-primary-archipro cursor-pointer" (click)="!searchInProgress && changePage(currentPage + 1)">
                                        <span *ngIf="size>=3" >Prossimo</span>
                                        <i *ngIf="size<3" class="icon-right-open"></i>
                                    </div>
                                </li>
                            </ul>
                          </nav>
                    </div>
                </div>
                
                <div class="h-100">
                    <div class="d-flex w-100 mt-3">
                        <table class="table table-striped border" *ngIf="listTitolarioRicerca.length > 0; else nessunElemento">
                            <thead class="">
                                <tr class="text-primary-archipro">
                                    <th class="size-badge-notifica"></th>                        
                                    <th scope="col" class="text-center">
                                        <span class="d-flex cursor-pointer">Titolario</span>
                                    </th>        
                                    <th scope="col">
                                        <span class="d-flex cursor-pointer">Descrizione</span>
                                    </th>     
                                    <th scope="col">Azioni</th>                                                                                                                                
                                </tr>
                            </thead>
                            <tbody *ngIf="!searchInProgress; else loadingList">
                                <tr *ngFor="let titolario of listTitolarioRicerca; let i = index" class="lista-colonne">
                                    <td class="size-badge-notifica"></td>
                                    <td class="numero-column">
                                        {{titolario.getFullTitolarioValue()}}
                                    </td>
                                    <td>
                                        {{titolario.lemma}}
                                    </td>
                                    <td class="action-icon d-flex align-items-center" ClickStopPropagation>
                                        <div class="border container-icon" (click)="riportaSuTitolario(titolario)">
                                            <i class="icon-export horizontal-speculate text-primary-archipro" title="Riporta"></i>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                            <ng-template #loadingList>
                                <tbody>
                                    <tr>
                                        <td colspan="100%">
                                            <app-loading class="w-100 h-100 d-flex justify-content-center align-items-center"></app-loading>
                                        </td>
                                    </tr>
                                </tbody>
                            </ng-template>
                        </table>
    
                        <ng-template #nessunElemento>
                            <div class="h-100 w-100 d-flex justify-content-center align-items-center" *ngIf="!searchInProgress; else loading">
                                <h2>Imposta i criteri di ricerca</h2>
                            </div>
                            <ng-template #loading>
                                <app-loading class="w-100 h-100 d-flex justify-content-center align-items-center"></app-loading>
                            </ng-template>
                        </ng-template>
                    </div>
                </div>
                <div *ngIf="listTitolarioRicerca.length > 0;" class=" w-100 d-flex justify-content-end mt-2 mb-2" [ngClass]="{'justify-content-center flex-column' : size<3}">
    
                    <diV class="mr-2 d-flex w-100 align-items-center justify-content-{{size<3 ? 'center' : 'end'}}" [ngClass]="{'justify-content-center' : size<3}">
                        <span class="text-primary-archipro risultat-totali-class">{{elementDa}} - {{elementA}} di {{totRecords}} risultati</span>
                    </diV>
                    <div class="d-flex align-items-center justify-content-{{size<3 ? 'center' : 'end'}}"  *ngIf="numPage > 1" >
                        <nav aria-label="Page navigation example">
                            <ul class="pagination mb-0">
                                <li class="page-item" [ngClass]="{'disabled' : currentPage == 1}">
                                    <div class="page-link text-primary-archipro cursor-pointer" (click)="!searchInProgress && changePage(currentPage - 1)">
                                        <span *ngIf="size>=3" >Precedente</span>
                                        <i *ngIf="size<3" class="icon-left-open"></i>
                                    </div>
                                  </li>
                                <li  *ngFor="let page of listObjPage" class="page-item">
                                  <div class="page-link text-primary-archipro cursor-pointer" (click)="!searchInProgress && page.clickable && changePage(page.index)" [ngClass]="{'active_page text-white' : currentPage == page.index}">{{page.value}}</div>
                                </li>
                                <li class="page-item" [ngClass]="{'disabled' : currentPage == numPage}">
                                    <div class="page-link text-primary-archipro cursor-pointer" (click)="!searchInProgress && changePage(currentPage + 1)">
                                        <span *ngIf="size>=3" >Prossimo</span>
                                        <i *ngIf="size<3" class="icon-right-open"></i>
                                    </div>
                                </li>
                            </ul>
                          </nav>
                    </div>
                </div>
                
            </div>
        </ng-template>
    </ng-container>

    <ng-container *ngIf="pdfFullScreen" class="h-100">
        <span class="text-primary-archipro cursor-pointer mb-2" (click)="pdfFullScreen = false"><i class="icon-left-big"></i>Torna al titolario</span>
        <app-element-pdf-viewer [url]="urlPdfGuida" [page]="pagePdf"></app-element-pdf-viewer>
    </ng-container>

    <ng-template  #loading>
        <app-loading></app-loading>
    </ng-template>
    
</section>

<app-modal #dynamicModalComponent></app-modal>