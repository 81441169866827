import { Host, Injectable, HostListener } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { SCREEN_SIZE } from './screen-size.enum';

@Injectable()
export class ResizeService {

  size : SCREEN_SIZE;
  width : number = window.outerWidth;
  height : number = window.outerHeight;

  onResize() {
    this.width = window.outerWidth;
    
    if(this.width < 576)
    {
      this.size = SCREEN_SIZE.XS
    }
    else if(this.width >= 576 && this.width < 768)
    {
      this.size = SCREEN_SIZE.SM
    }
    else if(this.width >= 768 && this.width < 992 )
    {
      this.size = SCREEN_SIZE.MD
    }
    else if(this.width >= 992 && this.width < 1200)
    {
      this.size = SCREEN_SIZE.LG
    }
    else if(this.width >= 1200)
    {
      this.size = SCREEN_SIZE.XL
    }    
    return this.size;
  }

  getWidth(){ 
    this.width = window.outerWidth;
    return this.width;  
  }  

  getHeight(){ 
    this.height = window.outerHeight;
    return this.height;  
  }
  
}