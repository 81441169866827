<section class="d-flex flex-column w-100 p-3 bg-form-color form-protocollazione-container">
    <section id="header-page" *ngIf="!modalMode">
        <div class="d-flex text-primary-archipro justify-content-between align-items-center border-bottom pb-2">
            <h4 class="">Fascicoli / {{updateMode ? "Modifica fascicolo (" + fascNumStringUpdate + ")" : "Nuovo fascicolo"}}</h4>
        </div>    
    </section>
    <section id="body-page" class="d-flex flex-column">
        <form *ngIf="!showAlberoCollocazione" class="w-100" [formGroup]="fascicoloForm">
            <fieldset class="w-100" [disabled]="disalbeForm">
                <div *ngIf="modalMode && showAlert" (click)="showAlert = false" class="alert alert-success cursor-pointer w-100 mb-4 mt-4" role="alert">
                    <span>{{messageAlert}}</span>
                </div>
                <div class="d-flex flex-column w-100">
                    <label class="title-section  d-flex justify-content-between">
                        <div class="d-flex align-items-center">
                            <span *ngIf="!GLOBALI.PRO_COLLOCAZIONE_FISICA; else titcompleto">Classificazione</span>
                            <ng-template #titcompleto>
                                <span >Classificazione e collocazione fisica</span>
                            </ng-template>
                        </div> 
                        <i (click)="openSection('sectionClassificazione')" class="cursor-pointer icon-{{checkSectionOpened('sectionClassificazione') ? 'up' : 'down'}}-open"></i>
                            
                    </label>
                    <div class="d-flex flex-column max-with-1400" *ngIf="checkSectionOpened('sectionClassificazione')">
                        <div class="d-flex spaced-input">
                            <div class="d-flex flex-column">

                                <div class="d-flex spaced-input">
                                    <div class="form-group w-100" [ngClass]="{'text-danger' : checkControlTitolario()}">
                                        <label>Classificazione</label>
                                        <div class="d-flex input-classificazione spaced-input">
                                            <input [readonly]="showEditClassificazione || updateMode" type="text" class="form-control text-center" placeholder="" aria-label="" aria-describedby="basic-addon1" maxlength="3" [ngClass]="{'border-danger' : checkControlName('titolo1')}" formControlName="titolo1">
                                            <input [readonly]="showEditClassificazione || updateMode" type="text" class="form-control text-center" placeholder="" aria-label="" aria-describedby="basic-addon1" maxlength="3" [ngClass]="{'border-danger' : checkControlName('titolo2')}" formControlName="titolo2">
                                            <input [readonly]="showEditClassificazione || updateMode" type="text" class="form-control text-center" placeholder="" aria-label="" aria-describedby="basic-addon1" maxlength="3" [ngClass]="{'border-danger' : checkControlName('titolo3')}" formControlName="titolo3">
                                            <input [readonly]="showEditClassificazione || updateMode" type="text" class="form-control text-center" placeholder="" aria-label="" aria-describedby="basic-addon1" maxlength="3" [ngClass]="{'border-danger' : checkControlName('titolo4')}" formControlName="titolo4">
                                            <input [readonly]="showEditClassificazione || updateMode" type="text" class="form-control text-center" placeholder="" aria-label="" aria-describedby="basic-addon1" maxlength="3" [ngClass]="{'border-danger' : checkControlName('titolo5')}" formControlName="titolo5">
                                            <input [readonly]="showEditClassificazione || updateMode" type="text" class="form-control text-center" placeholder="" aria-label="" aria-describedby="basic-addon1" maxlength="3" [ngClass]="{'border-danger' : checkControlName('titolo6')}" formControlName="titolo6">
                                        </div>
                                        <small *ngIf="checkControlTitolario()" id="oggettoRequired" class="form-text">Classificazione obbligatoria</small>
                                    </div>
                                    
                                    <div class="d-flex pt-2 action-icon-protocol spaced-input w-100">
                                        <div class="d-flex spaced-input" *ngIf="!updateMode && !disalbeForm && !isNuovoFascicolo;else showPulsantiClassificazione">
                                            <div class="border container-icon">
                                                <i *ngIf="!waitCheckClassificazione && !showEditClassificazione" class="cursor-pointer icon-target" title="Verifica" (click)="checkClassificazione()"></i>
                                                <i *ngIf="showEditClassificazione" class="cursor-pointer icon-edit" title="Modifica" (click)="editClassficazione()"></i>
                                                <div *ngIf="waitCheckClassificazione" class="loader-mini-spinner"></div>
                                            </div>
                                            <div class="border container-icon">
                                                <i class="cursor-pointer icon-search" title="Cerca" (click)="openNavigazioneTitolario()"></i>
                                            </div>
                                        </div>
                                        <ng-template #showPulsantiClassificazione>
                                            <div class="container-icon empty"></div>
                                            <div class="container-icon empty"></div>
                                        </ng-template>
                                        
                                        <div class="container-icon empty"></div>
                                        <div class="container-icon empty"></div>
                                        <div class="container-icon empty"></div>
                                    </div>
                                </div>

                                <div class="d-flex" *ngIf="titolarioSelezionato != null">
                                    <div class="info-titolario d-flex flex-column card-shadow border p-3 mb-2 bg-white w-100">
                                        <div class="d-flex" *ngIf="titolarioSelezionato != null">
                                            <span class="mr-2">
                                                <b>Titolario: </b>{{titolarioSelezionato.description}}
                                            </span>
                                        </div>
                                    </div>
                                </div>



                            </div>
                            <div class="d-flex flex-column" *ngIf="GLOBALI.PRO_COLLOCAZIONE_FISICA;else collocazionevuota">
                                <div class="d-flex spaced-input" >
                                    <div class="form-group w-100">
                                        <label>Collocazione fisica</label>
                                        <div class="d-flex input-classificazione spaced-input">
                                            <input type="text" [readonly]="showEditCollocazione" class="form-control text-center" placeholder="" aria-label="" aria-describedby="basic-addon1" maxlength="3" formControlName="collocazione1">
                                            <input type="text" [readonly]="showEditCollocazione" class="form-control text-center" placeholder="" aria-label="" aria-describedby="basic-addon1" maxlength="3" formControlName="collocazione2">
                                            <input type="text" [readonly]="showEditCollocazione" class="form-control text-center" placeholder="" aria-label="" aria-describedby="basic-addon1" maxlength="3" formControlName="collocazione3">
                                            <input type="text" [readonly]="showEditCollocazione" class="form-control text-center" placeholder="" aria-label="" aria-describedby="basic-addon1" maxlength="3" formControlName="collocazione4">
                                            <input type="text" [readonly]="showEditCollocazione" class="form-control text-center" placeholder="" aria-label="" aria-describedby="basic-addon1" maxlength="3" formControlName="collocazione5">
                                            <input type="text" [readonly]="showEditCollocazione" class="form-control text-center" placeholder="" aria-label="" aria-describedby="basic-addon1" maxlength="3" formControlName="collocazione6">
                                        </div>
                                    </div>
                                    <div class="d-flex pt-2 action-icon-protocol spaced-input w-100">
                                        <div class="d-flex spaced-input" *ngIf="!disalbeForm;else showPulsantiClassificazione">
                                            <div class="border container-icon">
                                                <i *ngIf="!waitCheckCollocazione && !showEditCollocazione" class="cursor-pointer icon-target" title="Verifica" (click)="checkCollocazione()"></i>
                                                <i *ngIf="showEditCollocazione" class="cursor-pointer icon-edit" title="Modifica" (click)="editCollocazione()"></i>
                                                <div *ngIf="waitCheckCollocazione" class="loader-mini-spinner"></div>
                                            </div>
                                            <div class="border container-icon">
                                                <i class="cursor-pointer icon-search" (click)="openNavigazioneCollocazione()" title="Cerca"></i>
                                            </div>
                                        </div>
                                        <ng-template #showPulsantiClassificazione>
                                            <div class="container-icon empty"></div>
                                            <div class="container-icon empty"></div>
                                        </ng-template>

                                        <div class="container-icon empty"></div>
                                        <div class="container-icon empty"></div>
                                        <div class="container-icon empty"></div>
                                    </div>
                                </div>
                                <div class="d-flex" *ngIf="collocazioneSelezionata != null">
                                    <div class="info-titolario d-flex flex-column card-shadow border p-3 mb-2 bg-white w-100">
                                        <div class="d-flex" *ngIf="collocazioneSelezionata != null">
                                            <span class="mr-2">
                                                <b>Collocazione: </b>{{collocazioneSelezionata.description}}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            
                            <ng-template #collocazionevuota>
                                <div class="d-flex w-100">

                                </div>
                            </ng-template>

                            
                        </div>
                    </div>
                </div>

                <div class="d-flex flex-column w-100">
                    <label class="title-section  d-flex justify-content-between">
                        <div class="d-flex align-items-center">
                            Dettaglio fascicolo
                        </div> 
                        <i (click)="openSection('sectionInfo')" class="cursor-pointer icon-{{checkSectionOpened('sectionInfo') ? 'up' : 'down'}}-open"></i>
                            
                    </label>
                    <div class="d-flex flex-column max-with-1400" *ngIf="checkSectionOpened('sectionInfo')">
                        
                        <div class="d-flex spaced-input" *ngIf="GLOBALI.PRO_SOGGETTO">
                            <div class="form-group w-100" [ngClass]="{'text-danger' : checkControlName('soggetto')}">
                                <label>Soggetto</label>
                                <textarea class="form-control" placeholder="" formControlName="soggetto" id="oggetto" rows="3" maxlength="200" [ngClass]="{'border-danger' : checkControlName('soggetto')}"></textarea>
                                <small *ngIf="checkControlName('soggetto')" id="oggettoRequired" class="form-text">Campo obbligatorio</small>
                            </div>
                        </div>
                        
                        
                        <div class="d-flex spaced-input"   [ngClass]="{'text-danger' : checkControlName('oggetto')}">
                            <div class="form-group w-100">
                                <label>Oggetto</label>
                                <textarea class="form-control" placeholder="" formControlName="oggetto" id="oggetto" rows="3" maxlength="1000" [ngClass]="{'border-danger' : checkControlName('oggetto')}"></textarea>
                                <small *ngIf="checkControlName('oggetto')" id="oggettoRequired" class="form-text">Campo obbligatorio</small>
                            </div>
                        </div>

                        

                        <div class="d-flex spaced-input">
                            <div class="form-group w-100">
                                <label>Parole chiave</label>
                                <textarea class="form-control" placeholder="" formControlName="note" id="oggetto" rows="3" maxlength="1000"></textarea>
                            </div>
                        </div>
                    </div>
                </div>
                
            </fieldset>
            <div class="d-flex spaced-input max-with-1400 justify-content-end">
                <button *ngIf="showSave" type="button" (click)="onSubmit()" class="btn bg-primary-archipro text-white mt-3">Salva</button>
                <button *ngIf="showNewFascicolo" (click)="resetForm()" type="button" class="btn bg-primary-archipro text-white mt-3">{{updateMode ? "Abilita modifica" : "Nuovo fascicolo"}}</button>
            </div>
        </form>



        <div *ngIf="modalMode && showAlberoCollocazione" class="w-100 h-100">
            <span (click)="showAlberoCollocazione = false" class="text-primary-archipro cursor-pointer font-weight-bold"><i class="icon-left-big "></i>Torna al form</span>
            <app-albero-collocazione [objParameterComponent]="objParameterComponent"></app-albero-collocazione>
        </div>
    </section>


    
</section>

<app-modal #dynamicModalComponent></app-modal>