import { Input, Type } from "@angular/core";

export class OptionModal{

    //Variabile che ha il compito di far vedere l'header di una modale (default : true)
    showHeaderModal : boolean = true;

    //Variabile che inserisce un height:90% alla modal dialog in modo tale da metterlo a tutto altezza
    maxSizeHeightModal : boolean = false;

    //Variabile utilizzata per aggiungere classi di stili già esistenti nel progetto o nel component (se sono più di una, seperarle con spazio " ")
    additionalClassModalBody : string = "";

    //Titolo della modale dinamica
    titolo : string;

    //serve per attivare l'inner html di textBodyModal
    textTitoloInnerHtml : boolean = false;

    //Testo della modale se si sta usando una modale semplice con solo testo o html (per html è necessario mettere a true textBodyInnerHtml)
    textBodyModal : string;

    //serve per attivare l'inner html di textBodyModal
    textBodyInnerHtml : boolean = false;

    //Grandezza della modale (questa variabile viene settata dal metodo setSizeModal)
    private classSizeModal : string = "";

    //Variabile utilizzata per far visualizzare il pulsante di chiusura nel footer (defalut false)
    showCloseButton : boolean = false;

    //Variabile utilizzata per far visualizzare il pulsante di salvataggio nel footer (defalut false)
    showSaveButton : boolean = false;

    //Variabile utilizzata per capire se caricare all'interno della modale un altro component
    private loadDynamicComponent : boolean = false;

    //Varabile che specifica quale sia il component da caricare (solo se loadDynamicComponent è true)
    dynamicComponnet : Type<any> = null;

    //Oggetto che al suo interno contiene funzioni e campi/oggetti del component che viene caricato dinamicamente
    objParameterComponent : any = null;

    //Grandezza della modale (questa variabile viene settata dal metodo setClassSizeMaxHeightModalBody)
    private classSizeMaxHeightModalBody : string = "";

    //Abilita e visualizza un pulsante opzionale (Abilitando questo, bisogna inserire il testo del pulsante e la funzione collegata al click)
    showCustomButton : boolean = false;
    
    //Testo del pulsante opzionale (per utilizzarlo settare showCustomButton = true)
    customButtonText : string = "Custom button";

    valueYesChoiceButton : any = null;
    valueNoChoiceButton : any = null;

    customFunctionChoice : (choice : any) => any;

    //Funzione del pulsante opzionale (per utilizzarlo settare showCustomButton = true)
    functionCustomButton : (args? : any) => any;

    /**
     * Metodo che setta la classe per la massima dimensione della modale
     * 
     *  se viene passato xl la variabile verrà settata con max-height-xl (90%px)
     *  se viene passato l la variabile verrà settata con max-height-l (700px)
     *  se viene passato m la variabile verrà settata con max-height-m (500px)
     *  se viene passato s la variabile verrà settata con max-height-s (300px)
     *  default vuoto
     * 
     * @param size 
     */
    public setClassSizeMaxHeightModalBody(size : string) : void{
        if(size === "s" || size === "l" || size === "m" || size === "xl")
        {
            this.classSizeMaxHeightModalBody = "max-height-" + size;
        }
        else
        {
            this.classSizeMaxHeightModalBody = "";
        }
    }

    /**
     * Metodo che prende la classe da inserire per la grandezza
     * 
     * @returns la stringa da inserire
     */
     public getClassSizeMaxHeightModalBody() : string{
        return this.classSizeMaxHeightModalBody;
    }



    /**
     * Metodo che setta la classe per la dimensione della modale
     * 
     *  se viene passato lg la variabile verrà settata con modal-xl
     *  se viene passato lg la variabile verrà settata con modal-lg
     *  se viene passato sm la variabile verrà settata con modal-sm
     *  se viene passato lg la variabile verrà settata con modal-40 (40%)
     *  se viene passato sm la variabile verrà settata con modal-50 (50%)
     *  se viene passato lg la variabile verrà settata con modal-60 (60%)
     *  se viene passato sm la variabile verrà settata con modal-70 (70%)
     *  se viene passato lg la variabile verrà settata con modal-80 (80%)
     *  se viene passato sm la variabile verrà settata con modal-90 (90%)
     *  default vuoto
     * 
     * @param size : dimensione (sm / lg) width
     */
     public setSizeModal(size : string) : void{
        if(size === "sm" 
        || size === "lg" 
        || size === "xl" 
        || size === "40" 
        || size === "50" 
        || size === "60" 
        || size === "70" 
        || size === "80" 
        || size === "90")
        {
            this.classSizeModal = "modal-" + size;
        }
        else
        {
            this.classSizeModal = "";
        }
    }

    /**
     * Metodo che prende la classe da inserire per la grandezza
     * 
     * @returns la stringa da inserire
     */
    public getSizeModal() : string{
        return this.classSizeModal;
    }


    /**
     * Metodo setter per inizializzare la variabile loadDunamiComponent e dire alla modale di caricare un ulteriore
     * 
     * @param loadDynamicComponent : boolean passato al metodo per iniializzare la variabile
     */
    public setLoadDynamicComponent(loadDynamicComponent : boolean) : void{
        this.loadDynamicComponent = loadDynamicComponent;
    }

    /** 
     * Getter della variabile loadDynamicComponent
    */
    public getLoadDynamicComponent() : boolean{
        return this.loadDynamicComponent;
    }



}