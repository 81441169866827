import { Component, Input, OnInit } from '@angular/core';
import { GruppoReferente } from 'src/app/model/GruppoReferente';
import { Referente } from 'src/app/model/Referente';
import { RequestService } from 'src/app/service/requestService/request.service';
import { Utility } from 'src/app/Utility/Utility';

@Component({
  selector: 'app-elenco-gruppi-referenti',
  templateUrl: './elenco-gruppi-referenti.component.html',
  styleUrls: ['./elenco-gruppi-referenti.component.scss']
})
export class ElencoGruppiReferentiComponent implements OnInit {

  showAlert : boolean = false;
  esitoAlert : boolean = false;

  showDettagliGruppo : boolean = false

  searchInProgress : boolean = false;

  docRequest : any = {
    nome : ""
  }

  listGruppi : GruppoReferente[] = [];

  gruppoSelezionato : GruppoReferente = null;

  @Input()
  riportaReferenti : (list : Referente[]) => void;

  constructor(private requestService : RequestService) { }

  ngOnInit(): void {
  }

  /**
   * Metodo che prende l'elenco dei gruppi dal server
   */
  ricercaGruppi() : void{
    this.searchInProgress = true;
    this.requestService.postRequest("getGruppi", this.docRequest,
      (success) => {
        this.listGruppi = [];
        let listGruppiFromServer = success.obj.list;
        if(Utility.checkObj(listGruppiFromServer) && listGruppiFromServer.length > 0)
        {
          listGruppiFromServer.forEach(element => {
            this.listGruppi.push(new GruppoReferente(element));
          });
        }
        this.searchInProgress = false;
      }
    )
  }

  /**
   * Metodo che prende i referenti di un determinato gurppo tramite chiamata server
   * 
   * @param gruppo 
   */
  dettaglioGruppo(gruppo : GruppoReferente) : void{
    
    if(gruppo.referenti.length == 0)
    {
      this.searchInProgress = true;
      this.callGetReferentiGruppo(gruppo);
    }
    else
    {
      this.gruppoSelezionato = gruppo;
    }

    this.showDettagliGruppo = true;
  }


  /**
   * Metodo che serve a riportare i referenti di un gruppo nell'elenco ricerca gruppi
   * (questo metodo deve avere anche una lamda che viene passato dall'altro component dove riportare i referenti del gruppo)
   * 
   * @param gruppo 
   */
  riportaGruppo(gruppo: GruppoReferente) : void{
    if(gruppo.referenti.length == 0)
    {
      this.searchInProgress = true;
      this.callGetReferentiGruppo(gruppo, true);
    }
    else
    {
      this.riportaReferenti(gruppo.referenti);
    }
  }

  /**
   * Metodo che elimina il gruppo selezionato
   * 
   * @param gruppo 
   */
  deleteGruppo(gruppo: GruppoReferente) : void{
    this.requestService.postRequest("deleteGruppo", gruppo, 
      (success) => {
        this.showAlertFunction(true);
        this.listGruppi = this.listGruppi.filter(element => {return element.codice != gruppo.codice});
      },
      () => {this.showAlertFunction(false);}
    )
  }

  showAlertFunction(esito : boolean) : void{
    this.showAlert = true;
    this.esitoAlert = esito;

    setTimeout(() => {this.showAlert = false}, 5000);
  }

  /**
   * Metodo che riporta alla lista dei gruppi
   */
  showList() : void{
    this.showDettagliGruppo = false;
  }

  getTipoRef(referenteTipo : number) : string{
    return Utility.getStringReferenteByCodReferente(referenteTipo);
  }

  /**
   * Funzione che effettua la chiamata al server per prendere la lista dei referenti di un gruppo
   * 
   * @param f   :   lamda che deve essere effettuata quando viene ricevuta una risposta
   * @returns 
   */
  callGetReferentiGruppo(gruppo : GruppoReferente, riporta : boolean = false) : void{
    this.requestService.postRequest("getReferentiGruppo", gruppo, 
      (success) => {
        let listFromServer = success.obj.list;
        if(Utility.checkObj(listFromServer) && listFromServer.length > 0)
        {
          listFromServer.forEach(element => {
            gruppo.referenti.push(new Referente(element))
          });
        }
        this.searchInProgress = false;

        this.gruppoSelezionato = gruppo;

        if(riporta) this.riportaReferenti(gruppo.referenti);
      }
    );
  }


}
