import { Component, Input, OnInit, HostListener, ViewChild } from '@angular/core';
import { Utility } from "src/app/Utility/Utility";
import { SCREEN_SIZE } from "src/app/service/resize/screen-size.enum";
import { RequestService } from './../../../../../../service/requestService/request.service';
import { ListaDocNavigazioneRequest } from '../../../../../../model/ListaDocNavigazioneRequest';
import { ResizeService } from './../../../../../../service/resize/resize.service';
import { NavigazioneComponent } from '../../navigazione.component';
import { ViewerComponent } from './../../../../../sub-component/viewer/viewer.component';
import { GlobalService } from 'src/app/service/globalService/global.service';
import { GlobalObj } from 'src/app/service/globalService/GlobalObj';

declare var $: any;

@Component({
  selector: "app-documenti",
  templateUrl: "./documenti.component.html",
  styleUrls: ["./documenti.component.scss"],
})
export class DocumentiComponent implements OnInit {

  @Input()
  titolarioSelezionato: any;

  @Input()
  fascicoloSelezionato: any;

  @Input()
  apriDettaglioDocumentiPerModal : (documento : any) => void;

  //Variabile di input utilizzata quando questa lista non viene utilizzata nella navigazione
  @Input() modalModeInput : boolean = false;

  listDoc: any = [];

  objRequest: ListaDocNavigazioneRequest = new ListaDocNavigazioneRequest();

  searchInProgress: boolean = false;

  size: SCREEN_SIZE;

  tipoDoc : string = "T";

  documentoViewer : any = {};
  @ViewChild('viewer')
  vieweObj : ViewerComponent;

  //Variabili utilizzate per la paginazione
  //************************************* */
  totRecords: number;
  numPage: number = 0;
  currentPage: number = 1;
  numRow: number = 10;
  listObjPage: any = [];
  elementA: number;
  elementDa: number;
  //************************************* */

  GLOBALI : GlobalObj;

  constructor(private requestService : RequestService, private resizeSvc : ResizeService, private globalService : GlobalService) {
    this.GLOBALI = this.globalService.GLOBAL;
    this.size = this.resizeSvc.onResize();
  }

  @HostListener("window:resize", [])
  private onResizeFunction() {
    this.size = this.resizeSvc.onResize();
  }


  ngOnInit(): void {
    this.objRequest.numTitolo = this.fascicoloSelezionato.fascPK.numTitolo;
    this.objRequest.annoFascicolo = this.fascicoloSelezionato.fascPK.annoFascicolo;
    this.objRequest.numeroFascicolo = this.fascicoloSelezionato.fascPK.numFascicolo;

    this.listaDocNavigazione();
  }

  get modalMode() : boolean
  {
    return NavigazioneComponent.modalMode;
  }

  /**
   * Metodo utilizzato dalla paginazione per richiedere al servere la lista
   *
   * @param page : numero della pagina cliccata
   */
  changePage(page: number) : void{
    this.currentPage = page;
    this.listaDocNavigazione();
  }
  /**
   * Questo Metodo viene utilizzata per calcolare il numero di pagine che la lista ha calcolata
   * in base al numero di record della lista e al opzione di numero di righe selezionata dall'utente
   */
  buildPaginationObject() : void{
    this.numPage = Utility.calculateNumberOfPages(this.totRecords, this.numRow);
    this.listObjPage = Utility.buildPaginationObject(
      this.numPage,
      this.currentPage
    );
    this.elementA = this.currentPage * this.numRow - (this.numRow - this.listDoc.length);
    this.elementDa = this.currentPage * this.numRow - (this.numRow - 1);
  }

  /**
   * Metodo utilizzato per settare nell'oggetto di richiesta solo i campi relativi alla paginazione
   * essendo una cosa esterna ai filtri
   */
  buildDocRequestPaginationField() : void{
    this.objRequest.setNumRow(this.numRow);
    this.objRequest.setCurrentPage(this.currentPage);
  }


  listaDocNavigazione() : void{
    this.buildDocRequestPaginationField();
    this.searchInProgress = true;
    this.requestService.postRequest("searchDocumenti", this.objRequest,
      (response) => {
        this.listDoc = response.obj.list;
        this.totRecords = response.obj.totRecords;
        this.buildPaginationObject();
        this.searchInProgress = false;
      },
      () => {
        this.searchInProgress = false;
        alert("qualcosa non va");
      }
    );
  }

  openDettaglioDocumento(documento : any) : void{

    /**
     * nel caso in cui è in una modale, non deve aprire un'altra modale per il viewer ma deve chiamare il componente apposito
     */
    if(NavigazioneComponent.modalMode || this.modalModeInput)
    {
      this.apriDettaglioDocumentiPerModal(documento);
    }
    else
    {
      this.documentoViewer = documento;

      $('#viewerDettaglioDocumentiNavigazione').modal({
        keyboard: false
      });

      setTimeout(()=>{ this.vieweObj.ngOnInit(); }, 100);
    }



  }
}
