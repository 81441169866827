export class ArchiPROInfoModel{
    titolo: string ;
    versione: string;
    server: string;
    message : any;

    constructor(){}

    setAllInfo(objInfo? : any){
        this.titolo = objInfo.titolo;
        this.versione = objInfo.versione;
        this.server = objInfo.server;
        this.message = objInfo.message;
    }

    

}