import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-elenco-agd-uo-per-utente',
  templateUrl: './elenco-agd-uo-per-utente.component.html',
  styleUrls: ['./elenco-agd-uo-per-utente.component.scss']
})
export class ElencoAgdUoPerUtenteComponent implements OnInit {


  @Input() 
  objParameterComponent : any;

  listAgdUo : any = [];
  addAgdUoScelto : (agd:any, close? : boolean) => void;
  
  constructor() { }

  ngOnInit(): void {


    if(this.objParameterComponent != null)
    {
        this.addAgdUoScelto = this.objParameterComponent.addAgdUoScelto;
        this.listAgdUo = this.objParameterComponent.listAgdUo;
    }
  }

}
