export class DizionarioOggetto{
  codice : number;
  descrizione : string;
  showEdit? : boolean = false;

  constructor(obj? : any)
  {
    if(obj){
      this.codice = obj.codice;
      this.descrizione = obj.descrizione;
    }
  }
}
