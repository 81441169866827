export class PrimaryKey{
    numProt : number;
    annoProt : number;

    constructor(pk? : any)
    {
        if(pk){
            this.annoProt = pk.annoProt;
            this.numProt = pk.numProt;
        }
    }
}