import { Component, HostListener, Input, OnInit } from '@angular/core';
import { GlobalService } from 'src/app/service/globalService/global.service';
import { ResizeService } from 'src/app/service/resize/resize.service';
import { SCREEN_SIZE } from 'src/app/service/resize/screen-size.enum';
import { Utility } from 'src/app/Utility/Utility';
import { BreadcrumbElencoRiferimenti } from './BreadcrumbElencoRiferimenti';

@Component({
  selector: 'app-elenco-riferimenti',
  templateUrl: './elenco-riferimenti.component.html',
  styleUrls: ['./elenco-riferimenti.component.scss']
})
export class ElencoRiferimentiComponent implements OnInit {


  /**
   * Variabile che indica quale elenco visualizzare per la scelta dei riferimenti.
   * I valori sono:
   *  - 0 per protocolli (default);
   *  - 1 per pratiche;
   *  - 2 per enti (Referente di tipo ente);
   */
  tipoElencoRiferimenti : number = 0;


  /**
   * Lista dei riferimenti selezionati che poi verrà riportata direttaemnte
   * nella form di protocollazione per intero tramite la funzione di riporta
   */
  listaRiferimentiSelezionata : any = [];

  @Input()
  size : SCREEN_SIZE;

  @Input()
  objParameterComponent : any;

  breadCrumb : BreadcrumbElencoRiferimenti = new BreadcrumbElencoRiferimenti();

  riportaRiferimenti : (args : any) => void;

  constructor(private resizeSvc : ResizeService, public globalService : GlobalService) {
    this.size = this.resizeSvc.onResize();
  }

  ngOnInit(): void {
    if(this.objParameterComponent != null)
    {
      this.riportaRiferimenti = this.objParameterComponent.riportaRiferimenti;
      this.listaRiferimentiSelezionata = Utility.deepCopyObject(this.objParameterComponent.listaRiferimentiScelti);

      this.listaRiferimentiSelezionata.forEach(element => {
        if(element.tipoRiferimento == "E")
        {
          element.descrizioneView = element.descrizione;
        }
      });

    }
  }

  azioneRiporta() : void {
    this.riportaRiferimenti(this.listaRiferimentiSelezionata);
  }

  @HostListener("window:resize", [])
  private onResizeFunction() {
    this.size = this.resizeSvc.onResize();
  }

  /**
   * Questo metodo permette di rimuovere un elemento dalla lista a seconda del tipo di elemento (documento, pratica o ente)
   * Questo metodo può essere richiamato anche dai component delle varie liste
   *
   * @param tipo : 0 per documento, 1 per pratica e 2 per ente
   * @param riferimento : riferimento da rimuovere
   */
  rimuoviRiferimento = (tipo : string, riferimento : any) : void => {
    if(tipo == "D")
    {
      this.listaRiferimentiSelezionata = this.listaRiferimentiSelezionata.filter(element => !(element.annoProtocollo == riferimento.annoProtocollo && element.numProtocollo == riferimento.numProtocollo))
    }
    else if (tipo == "P")
    {
      this.listaRiferimentiSelezionata = this.listaRiferimentiSelezionata.filter(element => !(element.annoPratica == riferimento.annoPratica && element.numPratica == riferimento.numPratica))
    }
    else if (tipo == "E")
    {
      this.listaRiferimentiSelezionata = this.listaRiferimentiSelezionata.filter(element => element.codRef != riferimento.codRef)
    }
  }
}
