<section class="dettagli-documento-viewer d-flex flex-column">
    <div class="d-flex justify-content-between">
        <span class="title-section-dettaglio d-flex">
            Info protocollo

            <!--Questo da vedere se si deve cancellare oppure no-->
            <ng-container *appPermission="'modificaProtocollo'">
                <i *ngIf="documentoInfo.security.possoModificareDocumento" class="icon-edit cursor-pointer text-primary-archipro mx-1" (click)="modificaProtocollo()"></i>
            </ng-container>
            <div class="composed-icon-pedice cursor-pointer" (click)="!showStampaGenerica ? stampaEtichetta.emit() : stampaEtichettaGenericaPerAllegato.emit(allegato)">
              <i class="icon-print"></i>
              <i *ngIf="showStampaGenerica" class="icon-stamp-solid pedice" style="font-size: 14px;"></i>
            </div>


        </span>
        <i (click)="openSection('sectionInfoProtocollo')" class="cursor-pointer icon-{{checkSectionOpened('sectionInfoProtocollo') ? 'up' : 'down'}}-open"></i>
    </div>
    <div class="dropdown-divider"></div>
    <div class="d-flex flex-column" *ngIf="checkSectionOpened('sectionInfoProtocollo')">
        <div class="row-dettaglio d-flex">
            <span class="label-dettaglio">
                <b>Protocollo:</b>
            </span>
            <span class="value-dettaglio d-flex align-items-center">
               <ng-container *ngIf="documentoInfo.annullato"><span class="text-danger font-weight-bold mr-1">(Annullato il {{documentoInfo.dataAnnullato | dataStringTransform}}) </span></ng-container>
               {{documentoInfo.docNumString}} del {{documentoInfo.dataProt | dataStringTransform}} - {{documentoInfo.provDest == 'E' ? 'Entrata' : (documentoInfo.provDest == 'U' ? 'Uscita' : "Interno") }}
            </span>
        </div>

        <div class="row-dettaglio d-flex" *ngIf="documentoInfo.numRepertorio">
          <span class="label-dettaglio">
              <b>Repertorio:</b>
          </span>
          <span class="value-dettaglio d-flex align-items-center">
            {{documentoInfo.numRepertorio}} / {{documentoInfo.annoRepertorio}} <ng-container *ngIf="documentoInfo.descrRepertorio">- {{documentoInfo.descrRepertorio}}</ng-container>
          </span>
        </div>

        <div class="row-dettaglio d-flex" *ngIf="documentoInfo.annullato">
            <span class="label-dettaglio">
                <b>Motivo:</b>
            </span>
            <span class="value-dettaglio d-flex align-items-center">
               {{documentoInfo.motivoAnnullato}}
            </span>
        </div>

        <div class="row-dettaglio d-flex">
            <span class="label-dettaglio">
                <b>{{GLOBALI.MAP_LABEL_FLUSSO[documentoInfo.provDest.trim()]}}:</b>
            </span>
            <span class="value-dettaglio d-flex align-items-center">
                <div *ngIf="documentoInfo.datiRefs.length > 1; else referenteSingolo" class="dropdown">
                    <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        Vedi elenco
                    </button>
                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                        <span class="dropdown-item" *ngFor="let referente of documentoInfo.datiRefs">
                            ({{getTipoRef(referente.referenteTipo)}}) {{referente.descrizione}} ({{getTipoRef(referente.referenteTipo)}} - {{referente.referenteCodice}})
                        </span>
                    </div>
                </div>

                <ng-template #referenteSingolo>
                    <span>({{getTipoRef(documentoInfo.datiRefs[0].referenteTipo)}}) {{documentoInfo.datiRefs[0].descrizione}} ({{getTipoRef(documentoInfo.datiRefs[0].referenteTipo)}} - {{documentoInfo.datiRefs[0].referenteCodice}})</span>
                </ng-template>
           </span>
        </div>
        <div class="row-dettaglio d-flex" *ngIf="showApprovaDoc">
          <span class="label-dettaglio">
              <b>Stato:</b>
          </span>
          <span class="value-dettaglio d-flex align-items-center white-space-pre-line">
              {{documentoInfo.descrStatoNP}}
          </span>
        </div>

        <div class="row-dettaglio d-flex">
            <span class="label-dettaglio">
                <b>Oggetto:</b>
            </span>
            <span class="value-dettaglio d-flex align-items-center white-space-pre-line">
                {{documentoInfo.oggetto}}
            </span>
        </div>

        <div class="row-dettaglio d-flex" *ngIf="documentoInfo.note || showApprovaDoc">
          <span class="label-dettaglio">
              <b>Note:</b>
          </span>
          <span class="value-dettaglio d-flex align-items-center white-space-pre-line">
              {{documentoInfo.note}}
          </span>
      </div>

        <div class="row-dettaglio d-flex">
            <span class="label-dettaglio">
                <b>Riservato:</b>
            </span>
            <span class="value-dettaglio d-flex align-items-center">
               <span *ngIf="documentoInfo.pgriservato">SI</span>
                <span *ngIf="!documentoInfo.pgriservato">NO</span>
            </span>
        </div>

        <!--div class="row-dettaglio d-flex">
            <span class="label-dettaglio">
                <b>Albo pretorio:</b>
            </span>
            <span class="value-dettaglio d-flex align-items-center">
                <i class="icon-note text-primary-archipro" *ngIf="documentoInfo.alboPresente"></i>
            </span>
        </div>-->

        <div class="row-dettaglio d-flex" *ngIf="documentoInfo.tipoDocumentoExt != null && documentoInfo.tipoDocumentoExt != ''">
            <span class="label-dettaglio">
                <b>Tipo documento:</b>
            </span>
            <span class="value-dettaglio d-flex align-items-center">
                {{documentoInfo.tipoDocumentoExt}}
            </span>
        </div>

        <div class="row-dettaglio d-flex" *ngIf="documentoInfo.tipoRicevutaExt != null && documentoInfo.tipoRicevutaExt != ''">
            <span class="label-dettaglio">
                <b>Ricevuta:</b>
            </span>
            <span class="value-dettaglio d-flex align-items-center">
                {{documentoInfo.tipoRicevutaExt}}
            </span>
        </div>

        <div class="row-dettaglio d-flex" *ngIf="documentoInfo.provDest == 'E' && documentoInfo.numProtAR != null && documentoInfo.numProtAR != ''">
            <span class="label-dettaglio">
                <b>Protocollo d'arrivo:</b>
            </span>
            <span class="value-dettaglio d-flex align-items-center">
                {{documentoInfo.numProtAR + " "}} <span *ngIf="documentoInfo.dataProtAR != null && documentoInfo.dataProtAR != ''"> del {{documentoInfo.dataProtAR | dataStringTransform}}</span>
            </span>
        </div>

        <div class="row-dettaglio d-flex" *ngIf="!!documentoInfo.dataEffett">
            <span class="label-dettaglio">
                <b>Data effettiva:</b>
            </span>
            <span class="value-dettaglio d-flex align-items-center">
                {{documentoInfo.dataEffett | dataStringTransform}}
            </span>
        </div>

        <div class="row-dettaglio d-flex" *ngIf="GLOBALI.PRO_GESTIONE_SCADENZE && !!documentoInfo.scadenze && documentoInfo.scadenze.length > 0">
            <span class="label-dettaglio">
                <b>Scadenze:</b>
            </span>
            <span class="value-dettaglio d-flex flex-column">
                <span *ngFor="let scadenza of documentoInfo.scadenze"> ({{scadenza.data | dataStringTransform}}) - {{scadenza.note}}</span>
            </span>
        </div>
    </div>

    <div class="d-flex justify-content-between">
        <span class="title-section-dettaglio">Competenze</span>
        <i (click)="openSection('sectionCompetenze')" class="cursor-pointer icon-{{checkSectionOpened('sectionCompetenze') ? 'up' : 'down'}}-open"></i>
    </div>
    <div class="dropdown-divider"></div>
    <div class="d-flex flex-column" *ngIf="checkSectionOpened('sectionCompetenze')">
        <div class="row-dettaglio d-flex">
            <span class="label-dettaglio">
                <b>Competenza:</b>
            </span>
            <span class="value-dettaglio d-flex flex-column">
                <span *ngFor="let c of objCompetenza">{{c.descrAgd}} ({{c.codiceAgd}}) <ng-container *ngIf="c.codiceUo">{{c.descrUo}} ({{c.codiceUo}})</ng-container></span>
            </span>
        </div>

        <div class="row-dettaglio d-flex">
            <span class="label-dettaglio">
                <b>Conoscenza:</b>
            </span>
            <span class="value-dettaglio d-flex flex-column">
                <span *ngFor="let cc of elencoCC"> {{cc.descrAgd}} ({{cc.codiceAgd}}) <ng-container *ngIf="cc.codiceUo">{{cc.descrUo}} ({{cc.codiceUo}})</ng-container></span>
            </span>
        </div>
    </div>

    <div class="d-flex justify-content-between" *ngIf="documentoInfo.descrTitolario">
        <span class="title-section-dettaglio">Classificazione e fascicolazione</span>
        <i (click)="openSection('sectionClassificazioneFascicolazione')" class="cursor-pointer icon-{{checkSectionOpened('sectionClassificazioneFascicolazione') ? 'up' : 'down'}}-open"></i>
    </div>
    <div class="dropdown-divider" *ngIf="documentoInfo.descrTitolario"></div>
    <div class="d-flex flex-column" *ngIf="documentoInfo.descrTitolario && checkSectionOpened('sectionClassificazioneFascicolazione')">
        <div class="row-dettaglio d-flex" >
            <span class="label-dettaglio">
                <b>Titolario:</b>
            </span>
            <span class="value-dettaglio d-flex align-items-center">
                {{documentoInfo.descrTitolario}}
            </span>
        </div>

        <div class="row-dettaglio d-flex">
            <span class="label-dettaglio">
                <b>Fascicolo:</b>
            </span>
            <span class="value-dettaglio d-flex align-items-center">
                {{documentoInfo.numFascicolo}}/{{documentoInfo.annoFascicolo}} aperto il {{documentoInfo.dataAperturaFascicolo | dataStringTransform}}
            </span>
        </div>

        <div class="row-dettaglio d-flex" *ngIf="GLOBALI.PRO_SOGGETTO && documentoInfo.soggettoFascicolo">
            <span class="label-dettaglio">
                <b>Soggetto fascicolo:</b>
            </span>
            <span class="value-dettaglio d-flex align-items-center">
                {{documentoInfo.soggettoFascicolo}}
            </span>
        </div>

        <div class="row-dettaglio d-flex" *ngIf="documentoInfo.oggettoFascicolo">
            <span class="label-dettaglio">
                <b>Oggetto fascicolo:</b>
            </span>
            <span class="value-dettaglio d-flex align-items-center">
                {{documentoInfo.oggettoFascicolo}}
            </span>
        </div>

        <div class="row-dettaglio d-flex">
            <span class="label-dettaglio">
                <b>In carico a:</b>
            </span>
            <span class="value-dettaglio d-flex align-items-center">
                {{documentoInfo.inCaricoFascicolo}}
            </span>
        </div>
    </div>

    <ng-container *ngIf="documentoInfo.descrTitolario && documentoInfo.estratti && false">
        <div class="d-flex justify-content-between">
            <span class="title-section-dettaglio">Estratti</span>
            <i (click)="openSection('sectionEstratti')" class="cursor-pointer icon-{{checkSectionOpened('sectionEstratti') ? 'up' : 'down'}}-open"></i>
        </div>
        <div class="dropdown-divider"></div>
        <div class="d-flex flex-column" *ngIf="checkSectionOpened('sectionEstratti')">
            <div class="row-dettaglio d-flex">
                <span class="label-dettaglio">
                    <b>Estratti:</b>
                </span>
                <span class="value-dettaglio d-flex flex-column">
                    <span *ngFor="let fascicolo of listaFascicoliEstratti" class="pt-2 pb-2">
                        {{fascicolo.getFullTitolarioAndFascicoloString()}} - <ng-container *ngIf="GLOBALI.PRO_SOGGETTO">{{fascicolo.soggetto}} - </ng-container> {{fascicolo.oggetto}} - {{fascicolo.dataAperturaFascicolo | dataStringTransform}}
                    </span>
                </span>
            </div>
        </div>
    </ng-container>


    <ng-container *ngIf="GLOBALI.PRO_GESTIONE_RIFERIMENTI && !!documentoInfo.riferimenti && documentoInfo.riferimenti.length > 0">
        <div class="d-flex justify-content-between">
            <span class="title-section-dettaglio">Riferimenti</span>
            <i (click)="openSection('sectionCompetenze')" class="cursor-pointer icon-{{checkSectionOpened('sectionCompetenze') ? 'up' : 'down'}}-open"></i>
        </div>
        <div class="dropdown-divider"></div>
        <div class="d-flex flex-column" *ngIf="checkSectionOpened('sectionCompetenze')">
            <div class="row-dettaglio d-flex">
                <span class="label-dettaglio">
                    <b>Riferimenti:</b>
                </span>
                <span class="value-dettaglio d-flex flex-column">
                    <span *ngFor="let riferimento of documentoInfo.riferimenti">
                        ({{riferimento.tipoRiferimento}}) - {{riferimento.descrizione}}
                    </span>
                </span>
            </div>
        </div>
    </ng-container>



    <div *ngIf="documentoInfo.alboPresente && documentoInfo.alboPretorio != null">

        <div class="d-flex justify-content-between">
            <span class="title-section-dettaglio">Albo pretorio</span>
            <i (click)="openSection('sectionAlboPretorio')" class="cursor-pointer icon-{{checkSectionOpened('sectionAlboPretorio') ? 'up' : 'down'}}-open"></i>
        </div>
        <div class="dropdown-divider"></div>
        <div class="d-flex flex-column" *ngIf="checkSectionOpened('sectionAlboPretorio')">
            <div class="row-dettaglio d-flex">
                <span class="label-dettaglio">
                    <b>Numero albo:</b>
                </span>
                <span class="value-dettaglio d-flex align-items-center">
                    {{documentoInfo.alboPretorio.progAlbo}}
                </span>
            </div>
            <div class="row-dettaglio d-flex">
                <span class="label-dettaglio">
                    <b>Sezione del sito:</b>
                </span>
                <span class="value-dettaglio d-flex align-items-center">
                    {{documentoInfo.alboPretorio.sezioneSito}}
                </span>
            </div>
            <div class="row-dettaglio d-flex">
                <span class="label-dettaglio">
                    <b>Data inizio pubbl.:</b>
                </span>
                <span class="value-dettaglio d-flex align-items-center">
                    {{documentoInfo.alboPretorio.dataIn | dataStringTransform}}
                </span>
            </div>
            <div class="row-dettaglio d-flex">
                <span class="label-dettaglio">
                    <b>Data fine pubbl.:</b>
                </span>
                <span class="value-dettaglio d-flex align-items-center">
                    {{documentoInfo.alboPretorio.dataFin | dataStringTransform}}
                </span>
            </div>

            <div class="row-dettaglio d-flex">
                <span class="label-dettaglio">
                    <b>Motivo aggior.:</b>
                </span>
                <span class="value-dettaglio d-flex align-items-center">
                    {{documentoInfo.alboPretorio.motivoAnnullamento}}
                </span>
            </div>

            <div class="row-dettaglio d-flex" *ngIf="false">
                <span class="label-dettaglio">
                    <b>Data restituzione:</b>
                </span>
                <span class="value-dettaglio d-flex align-items-center">
                    {{documentoInfo.alboPretorio.dataRest | dataStringTransform}}
                </span>
            </div>

            <div class="row-dettaglio d-flex">
                <span class="label-dettaglio">
                    <b>Resp. PROCEDIM:</b>
                </span>
                <span class="value-dettaglio d-flex align-items-center">
                    {{documentoInfo.alboPretorio.nomeRespProc}}
                </span>
            </div>

            <div class="row-dettaglio d-flex">
                <span class="label-dettaglio">
                    <b>Resp. PUBBL:</b>
                </span>
                <span class="value-dettaglio d-flex align-items-center">
                    {{documentoInfo.alboPretorio.nomeRespPubbl}}
                </span>
            </div>

            <div class="row-dettaglio d-flex">
                <span class="label-dettaglio">
                    <b>E-mail resp. PUBBL:</b>
                </span>
                <span class="value-dettaglio d-flex align-items-center">
                    {{documentoInfo.alboPretorio.emailRespPubbl}}
                </span>
            </div>

            <div class="row-dettaglio d-flex">
                <span class="label-dettaglio">
                    <b>Link materiali:</b>
                </span>
                <span class="value-dettaglio d-flex align-items-center">
                    {{documentoInfo.alboPretorio.linkMateriali}}
                </span>
            </div>
        </div>
    </div>
</section>
