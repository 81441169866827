import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { StaticProperty } from 'src/app/Utility/StaticProperty';
import { environment } from 'src/environments/environment';
import { GlobalService } from '../globalService/global.service';
import { RequestService } from '../requestService/request.service';
import { EtichettaObj } from './EtichettaObj';

declare var dymo : any;

@Injectable({
  providedIn: 'root'
})
export class StampaEtichettaService {

  private modelloEtichettaXml : string;
  private etichettaObj : EtichettaObj;

  constructor(private http : HttpClient, private globalService : GlobalService, private requestService : RequestService) {}

  /**
   * Metodo che prende il modello xml e successivamente stampa l'etichetta
   * @param etichettaObj
   */
  async avviaStampa(etichettaObj : EtichettaObj)
  {
    let value = null;
    this.etichettaObj = etichettaObj;




    if(this.globalService.GLOBAL.PRO_TIPO_PRINTER == "GENERICA")
    {
      await this.requestService.postRequestAsync("stampaEtichettaGenerica", etichettaObj,
        success => {
          value = success.obj?.url;
        }
      )
    }
    else
    {
      this.getModelloEtichettaXml(this.stampaEtichetta);
    }

    return value;
  }

  /**
   * Metodo che non fa altro che prendere dal server il modello xml per l'etichetta e avvia la stampa con stampaetichett()
   */
  private getModelloEtichettaXml(f : Function) : void{

    let optionsRequest : any = {
      params: {},
      withCredentials : true,
      responseType: "text"
    };

    let formato = "XML/ArchiPRO_36x89.xml";

    /*if(StaticProperty.CODICE_ENTE_CEI == this.globalService.GLOBAL.PRO_CODICE_ENTE || StaticProperty.CODICE_ENTE_DIOC_LATINA == this.globalService.GLOBAL.PRO_CODICE_ENTE){
      formato = "XML/ArchiPRO_28x89.xml";
    }*/

    if(this.globalService.GLOBAL.PRO_TIPO_PRINTER_LABEL)
    {
      formato = "XML/" + this.globalService.GLOBAL.PRO_TIPO_PRINTER_LABEL;
    }

    this.http.get(environment.url + formato, optionsRequest).subscribe(
      (response) => {
        this.modelloEtichettaXml = response as unknown as string;
        f();
      }
    );
  }

  /**
   * Metodo che avvia la stampa della dymo
   * @param etichettaObj
   */
  private stampaEtichetta = () : void => {
    try{

      let f = dymo.label.framework;

      this.modelloEtichettaXml = this.modelloEtichettaXml.replace("Data_1", this.etichettaObj.getEtichetta());

      let label = f.openLabelXml(this.modelloEtichettaXml);



      let printers = dymo.label.framework.getPrinters();
      if (printers.length == 0)
          throw "Nessuna stampante DYMO rilevata. Installare una stampante DYMO.";

      let printerName = "";
      let connected : boolean = false;
      for (let i = 0; i < printers.length; ++i)
      {
          let printer = printers[i];
          if (printer.printerType == "LabelWriterPrinter")
          {
              printerName = printer.name;
              if(printer.isConnected)
                  connected = true;
              break;
          }
      }

      if (printerName == "")
          throw "Nessuna stampante Labelwriter rilevata. Installare una stampante Labelwriter.";

      if (!connected)
          throw "Nessuna stampante Labelwriter collegata. Collegare una stampante Labelwriter.";

      // finally print the label
      label.print(printerName);

    }
    catch(e)
    {
        alert(e.message || e);
    }


  }


}
