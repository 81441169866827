import { PrimaryKey } from "./PrimaryKey";
import { Utility } from "../Utility/Utility";

export class PrimaryKeyAllegato extends PrimaryKey{
    
    progr : number;

    constructor(obj? : any){
        super(obj);
        if(Utility.checkObj(obj)){
            this.progr = obj.progr;
        }
    }

    /**
     * Funzione che contolla se i anno e numero del protocollo non sono nulli e 0
     */
    checkPrimaryKey() : boolean
    {
        return this.numProt != null 
            && this.numProt != undefined 
            && this.numProt != 0
            && this.annoProt != null 
            && this.annoProt != undefined 
            && this.annoProt != 0;
    }
}