import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ElencoAgdUoModalComponent } from 'src/app/component/sub-component/elenco-agd-uo-modal/elenco-agd-uo-modal.component';
import { ElencoAgdUoPerUtenteComponent } from 'src/app/component/sub-component/elenco-agd-uo-per-utente/elenco-agd-uo-per-utente.component';
import { ModalComponent } from 'src/app/component/sub-component/modal/modal.component';
import { OptionModal } from 'src/app/component/sub-component/modal/OptionModal';
import { Fascicolo } from 'src/app/model/Fascicolo';
import { ObjCompetenzaServer } from 'src/app/model/ObjCompetenza';
import { PrimaryKeyFascicolo } from 'src/app/model/PrimaryKeyFascicolo';
import { RequestriferimentiForm } from 'src/app/model/RequestRiferimentiForm';
import { Titolario } from 'src/app/model/Titolario';
import { GlobalService } from 'src/app/service/globalService/global.service';
import { GlobalObj } from 'src/app/service/globalService/GlobalObj';
import { RequestService } from 'src/app/service/requestService/request.service';
import { CustomValidators } from 'src/app/Utility/CustomValidators';
import { Utility } from 'src/app/Utility/Utility';
import { UtilityCompetenze } from 'src/app/Utility/UtilityCompetenze';

@Component({
  selector: 'app-trasmetti-fascicolo',
  templateUrl: './trasmetti-fascicolo.component.html',
  styleUrls: ['./trasmetti-fascicolo.component.scss']
})
export class TrasmettiFascicoloComponent implements OnInit {

  competenzaAg : any = {};
  utilityCompetenze : UtilityCompetenze;

  isTrasmAtti : boolean = false;
  titlePage : string = "Trasmissione fascicolo ";

  infoFascicolo : Fascicolo;
  listAgdUoScelti : any = [];
  listAgdUoToSearch : any = [];

  listaCompetenze : any = [];
  elencoCC : any = [];

  //****************************************************** */
  //Variabili ulitilizzate per dichiarare e inizializzare la modale
  //****************************************************** */
  @ViewChild('dynamicModalComponent')
  dynamicModal : ModalComponent;
  optionModal : OptionModal = new OptionModal();
  //****************************************************** */
  //****************************************************** */

  showForm : boolean = false;
  justChecked : boolean = false;
  disableForm : boolean = false;
  disabledMotivo : boolean = false;
  showTrasmetti : boolean = true;

  mapSectionOpened : any = {
    sectionInfoFascicolo: true,
    sectionCompetenze : true,
    sectionCompetenzeMotivo : true
  };

  trasmissioneForm : FormGroup;
  GLOBALI : GlobalObj;

  competenze =  new FormArray([]);

  constructor(private formBuilder : FormBuilder, private router : Router, private requestService : RequestService, private globalService : GlobalService ) {
    this.GLOBALI = this.globalService.GLOBAL;

    let objForm : any = {
      competenze : this.competenze,
      motivo : new FormControl(""),
      numeroFascicolo : new FormControl(),
      annoFascicolo : new FormControl(),
      numTitolo : new FormControl(),

      //Questi campi servono solo per la ricerca e infine l'inserimento nella lista competenze
      competenza : new FormControl(""),
      agd : new FormControl(""),
      uo : new FormControl(""),
      cognome : new FormControl(""),
    };

    this.trasmissioneForm = this.formBuilder.group(objForm);

    if(this.GLOBALI.PRO_MULTICOMPETENZA)
    {
      this.competenze.setValidators([Validators.required, Validators.minLength(1), CustomValidators.competenzaObbligatoria]);
    }
    else
    {
      this.competenze.setValidators([Validators.required, Validators.minLength(1), CustomValidators.competenzaObbligatoria, CustomValidators.noMultiCompetenza]);
    }

    if(this.router.getCurrentNavigation() != null && this.router.getCurrentNavigation().extras.state != undefined){
      let pkfascicolo = new PrimaryKeyFascicolo(this.router.getCurrentNavigation().extras.state);

      //avendo già la chiave del fascicolo setto già nell'oggetto del form da passare al server
      this.annoFascicolo.setValue(pkfascicolo.annoFascicolo);
      this.numeroFascicolo.setValue(pkfascicolo.numeroFascicolo);
      this.numTitolo.setValue(pkfascicolo.numTitolo);


      this.getDettagliFascicolo(pkfascicolo);
    }
    else
    {
      let pkfascicolo = new PrimaryKeyFascicolo();
      this.annoFascicolo.setValue(1);
      this.numeroFascicolo.setValue(2022);
      this.numTitolo.setValue(2321);


      this.getDettagliFascicolo(pkfascicolo);
    }

    this.isTrasmAtti = this.router.url.split("/")[this.router.url.split("/").length - 1] == "trasmettiAgliAtti";

    if(this.isTrasmAtti){
      this.titlePage = "Trasmissione agli atti ";
    }

  }

  /*****************************************************************/
  /************************ GETTER FOFM ****************************/
  /*****************************************************************/
  get annoFascicolo(){
    return this.trasmissioneForm.get("annoFascicolo");
  }

  get numeroFascicolo(){
    return this.trasmissioneForm.get("numeroFascicolo");
  }

  get numTitolo(){
    return this.trasmissioneForm.get("numTitolo");
  }

  get motivo(){
    return this.trasmissioneForm.get("motivo");
  }

  get competenza (){
    return this.trasmissioneForm.get("competenza");
  }

  get agd (){
    return this.trasmissioneForm.get("agd");
  }

  get uo (){
    return this.trasmissioneForm.get("uo");
  }

  get cognome (){
    return this.trasmissioneForm.get("cognome");
  }

  ngOnInit(): void {

  }

  ngAfterViewInit() : void
  {

    if(this.isTrasmAtti)
    {
      this.disabledMotivo = false;
      this.setAG();
    }
    else
    {
      this.utilityCompetenze = new UtilityCompetenze(this.dynamicModal, this.optionModal, this.requestService);
      this.utilityCompetenze.checkChangeDisableAgdUoCognome(this.agd, this.uo, this.cognome);
      this.addChangeCompetenza();
    }
  }

  /**
   * Metodo che effettua la chiamata al server per prendere i dettagli del fascicolo
   */
  getDettagliFascicolo(pkFascicolo : PrimaryKeyFascicolo) : void{

    this.requestService.postRequest("getDettaglioFascicolo", pkFascicolo,
      (success) => {

        this.infoFascicolo = new Fascicolo(success.obj.fascicolo);

        //setto i campi della chiave primaria direttamente da quella che ho già
        this.infoFascicolo.annoFascicolo = pkFascicolo.annoFascicolo;
        this.infoFascicolo.numeroFascicolo = pkFascicolo.numeroFascicolo;
        this.infoFascicolo.numTitolo = pkFascicolo.numTitolo;

        this.listaCompetenze = success.obj.listaCompetenze;

        //non faccio il controllo del filter perchè do per scontato che ci sia almeno una competenza del fascicolo in quanto obbligatoria
        this.infoFascicolo.setCompetenzaFromObjCompetenzaServer(this.listaCompetenze.filter((element) => {return element.flagDestCC == "D"})[0] as ObjCompetenzaServer)



        if(!this.isTrasmAtti)
        {
          this.buildObjCompetenezaAndListCC();
        }

        this.showForm = true;

      }
    )
  }
  getListAgdUo()
  {

  }

  setAG(){

    this.requestService.postRequest("getElencoAgdUo", {},
      (response) => {
        this.competenze.clear();

        this.competenzaAg = response.obj.list.find(element => element.codice == this.GLOBALI.PRO_AG);
        this.competenzaAg.activeC = true;
        this.competenzaAg.codicePadre = this.competenzaAg.codice;
        this.competenzaAg.codice = -1


        this.competenze.push(new FormControl(this.competenzaAg));
        this.competenze.updateValueAndValidity();

      }
    );

  }


  /**
   * Funzione utilizzata per aprire e chiudere le sezioni
   *
   * @param idSection : id della sezione da chiudere/aprire (l'id deve essere lo stesso che viene inserito nella mapSectionOpened)
   */
  openSection(idSection : string) : void
  {
    this.mapSectionOpened[idSection] = !this.mapSectionOpened[idSection]
  }

  /**
   * Funzione che ritorna lo stato di una sezione (true aperto, false chiuso)
   *
   * @param idSection   : id della sezione da verificare
   * @returns           : torna il valore inserito nella mapSectionOpened
   */
  checkSectionOpened(idSection : string) : boolean{
    return this.mapSectionOpened[idSection];
  }

  /**
   * Funzione che verifica se il primo tentativo di login è stato effettuato e se un determinato campo è valido
   *
   * field : nome del campo da verificare
   *
   * @returns il valore true se il campo non è valido
   */
  checkControlName(field) : boolean{
    return this.justChecked && !this.trasmissioneForm.controls[field].valid;
  }

  buildObjCompetenezaAndListCC()
  {
    this.elencoCC = [];

    for(let i = 1; i < this.listaCompetenze.length; i++){
      this.elencoCC.push(this.listaCompetenze[i]);
    }

    //Metodo che aggiunge alla lista delle competenze da modificare quelle del protocollo originario
    this.addListCompetenzeOriginarie();
  }

  //Metodo che aggiunge alla lista delle competenze da modificare quelle del protocollo originario


  //***************************************************
  //**************** CHIAMATE SERVER ******************
  //***************************************************


  /**
   * Submit della form
   */
  onSubmit() : void{
    this.justChecked = true;
    if(this.trasmissioneForm.valid)
    {
      if(this.trasmissioneForm.dirty || this.isTrasmAtti)
      {

        let url = "trasmettiFascicolo";
        let payload = this.trasmissioneForm.getRawValue();

        if(this.isTrasmAtti)
        {
          url = "trasmettiFascicoloAgliAttiLight";
          payload.motivo = "Trasmissione agli atti" + (payload.motivo ? " - " + payload.motivo : "");
        }

        this.requestService.postRequest(url, payload,
          (success) => {
            this.optionModal = new OptionModal();
            this.optionModal.titolo = "Trasmissione fascicolo (" + this.infoFascicolo.getNumString() + ")";
            this.optionModal.textBodyModal = "Il fascicolo è stato trasmesso " + (this.isTrasmAtti ? "agli atti" : "con successo");


            this.optionModal.showSaveButton = false;
            this.optionModal.showCloseButton = true;
            this.optionModal.setClassSizeMaxHeightModalBody("m");

            this.dynamicModal.initModal(this.optionModal);
            this.dynamicModal.openModal();
            this.disableForm = true;
            this.showTrasmetti = false;
          }
        );
      }
    }
  }



  //***************************************************
  //***************************************************
  //***************************************************



  addChangeCompetenza() : void{
    this.competenze.valueChanges.subscribe(list => {
      list.forEach(element => {
        if(element.activeC)
        {
          //se la competenza non è cambiata
          if(element.codicePadre == this.infoFascicolo.codAgd && (element.hasOwnProperty("children") ? true : element.codice == this.infoFascicolo.codUo)){
            this.motivo.clearValidators();
            this.disabledMotivo = true;
            this.motivo.updateValueAndValidity();
          }
          else //Se è cambiata la competenza
          {
            this.motivo.setValidators(Validators.required);
            this.disabledMotivo = false;
            this.motivo.updateValueAndValidity();
          }
        }
      })
    })
  }


    /**
   * Metodo che disabilita cognome quando agd o uo vengono compilate
   * viceversa, se cognome viene settato, il disable dei 2 input viene fatto lato html
   * per problemi di stack delle funzioni che vengono richiamate tra di loro
   */





  addListCompetenzeOriginarie() : void{
    //per prima cosa inserisco l'elemento per competenza nella list creandolo

    let competenza : any = {};

    //generale
    if(this.infoFascicolo.codUo == 0)
    {
      competenza.codice = this.infoFascicolo.codAgd;
      competenza.descrizione = this.infoFascicolo.descrizioneAgd;
      //competenza.codicePadre = this.objCompetenza.codiceAgd;
      competenza.activeC = true;
      competenza.children = [{codicePadre : this.infoFascicolo.codAgd}]
    }
    else
    {
      competenza.descrizionePadre = this.infoFascicolo.descrizioneAgd + " (" + this.infoFascicolo.codAgd + ")";
      competenza.codicePadre = this.infoFascicolo.codAgd;
      competenza.codice = this.infoFascicolo.codUo;
      competenza.descrizioneRidotta = this.infoFascicolo.descrizioneUo;
      competenza.activeC = true;
    }

    this.listAgdUoScelti.push(competenza);

    this.elencoCC.forEach(element => {

      let conoscenza : any = {
        activeCC : true,
        codicePadre : element.codiceAgd,
        codice : (element.codiceUo == 0 ? element.codiceAgd : element.codiceUo),
      };

      if(element.codiceUo == 0)
      {
        conoscenza.descrizione = element.descrAgd;
        conoscenza.children = [{codicePadre : element.codiceAgd}]
      }
      else
      {
        conoscenza.descrizionePadre = element.descrAgd + " (" + element.codiceAgd + ")";
        conoscenza.descrizioneRidotta = element.descrUo;
      }

      this.listAgdUoScelti.push(conoscenza);
    });

    this.utilityCompetenze.convertListAgdUoSceltiToCompetenzeForm(this.listAgdUoScelti, this.competenze);
  }


  updateCompetenze($event){
    this.competenze = $event;
  }

}
