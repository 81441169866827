import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { ScrivaniaComponent } from 'src/app/component/moduli/scrivania-module/scrivania.component';
import { AllegatoObj } from 'src/app/model/AllegatoObj';
import { Fascicolo } from 'src/app/model/Fascicolo';
import { DataStringTransformPipe } from 'src/app/pipe/dataStringTransform/data-string-transform.pipe';
import { GlobalService } from 'src/app/service/globalService/global.service';
import { GlobalObj } from 'src/app/service/globalService/GlobalObj';
import { RequestService } from 'src/app/service/requestService/request.service';
import { EtichettaObj } from 'src/app/service/StampaEtichettaService/EtichettaObj';
import { StampaEtichettaService } from 'src/app/service/StampaEtichettaService/stampa-etichetta.service';
import { Utility } from 'src/app/Utility/Utility';

declare var $ : any;

@Component({
  selector: 'app-dettaglio-documento-viewer',
  templateUrl: './dettaglio-documento-viewer.component.html',
  styleUrls: ['./dettaglio-documento-viewer.component.scss']
})
export class DettaglioDocumentoViewerComponent   implements OnInit {

  @Output()
  stampaEtichetta : EventEmitter<any> = new EventEmitter();

  @Output()
  stampaEtichettaGenericaPerAllegato : EventEmitter<AllegatoObj> = new EventEmitter();

  @Input()
  allegato : AllegatoObj;

  @Input()
  documentoInfo : any = {};

  @Input()
  objCompetenza : any = {};

  @Input()
  elencoCC : any = [];

  @Input()
  showStampaGenerica : boolean;

  @Input()
  idViewer : string;

  GLOBALI : GlobalObj

  listaFascicoliEstratti : Fascicolo[] = [];

  etichettaObj : EtichettaObj = new EtichettaObj();

  constructor(private router : Router, private globalService : GlobalService, private stampaEtichettaService : StampaEtichettaService, private requestService : RequestService) {

    this.GLOBALI = this.globalService.GLOBAL;
   }


  mapSectionOpened : any = {
    sectionInfoProtocollo: true,
    sectionCompetenze : true,
    sectionClassificazioneFascicolazione : true,
    sectionAlboPretorio : true,
    sectionEstratti : true
  };

  ngOnInit(): void {
  }

  ngAfterViewInit(){
    //this.getListaEstratti();
  }


  //************Gestione sezioni **************
  //*******************************************
  /**
   * Funzione utilizzata per aprire e chiudere le sezioni
   *
   * @param idSection : id della sezione da chiudere/aprire (l'id deve essere lo stesso che viene inserito nella mapSectionOpened)
   */
  openSection(idSection : string) : void
  {
    this.mapSectionOpened[idSection] = !this.mapSectionOpened[idSection]
  }

  /**
    * Funzione che ritorna lo stato di una sezione (true aperto, false chiuso)
    *
    * @param idSection   : id della sezione da verificare
    * @returns           : torna il valore inserito nella mapSectionOpened
    */
  checkSectionOpened(idSection : string) : boolean{
    return this.mapSectionOpened[idSection];
  }

  getTipoRef(referenteTipo : number) : string{
    return Utility.getStringReferenteByCodReferente(referenteTipo);
  }


  modificaProtocollo() : void{
    $('#' + this.idViewer).modal('toggle');
    this.router.navigateByUrl('/modulo/gestioneDocumenti/modificaProtocollo', { state: {numProt : this.documentoInfo.pk.numProt, annoProt : this.documentoInfo.pk.annoProt} });
  }

    /**
   * Metodo che chiama il servizio getListaEstratti e riempe la lista degli estratti
   */
     getListaEstratti() : void{

      this.documentoInfo.estratti = true;

      let fascicolo : Fascicolo = new Fascicolo();
      fascicolo.titolario.titolo1 = 1;
      fascicolo.titolario.titolo2 = 2;
      fascicolo.titolario.titolo3 = 3;
      fascicolo.titolario.titolo4 = 4;
      fascicolo.titolario.titolo5 = 5;
      fascicolo.titolario.titolo6 = 6;
      fascicolo.descrTitolario = "TEST TEST TEST TEST TEST"
      fascicolo.numeroFascicolo = 1;
      fascicolo.annoFascicolo = 2022;
      fascicolo.numTitolo = 123;
      fascicolo.soggetto = "soggetto di prova";
      fascicolo.oggetto = "oggetto di prova";
      fascicolo.dataAperturaFascicolo = "2022-06-23";

      this.listaFascicoliEstratti.push(fascicolo);

      fascicolo = new Fascicolo();
      fascicolo.descrTitolario = "FUNZIONE AMMINISTRATIVA - PATRIMONIALE - Gestione dei beni culturali";
      fascicolo.titolario.titolo1 = 9;
      fascicolo.titolario.titolo2 = 4;
      fascicolo.titolario.titolo3 = 0;
      fascicolo.titolario.titolo4 = 0;
      fascicolo.titolario.titolo5 = 0;
      fascicolo.titolario.titolo6 = 0;
      fascicolo.numeroFascicolo = 12;
      fascicolo.annoFascicolo = 2022;
      fascicolo.numTitolo = 61;
      fascicolo.soggetto = "RESTAURO FACCIATA";
      fascicolo.oggetto = "RESTAURO FACCIATA";
      fascicolo.dataAperturaFascicolo = "2022-06-22";
      this.listaFascicoliEstratti.push(fascicolo);


      /*let titolario = new Titolario();
      titolario.setTitolarioByString(this.documentoInfo.titolario);
      let payload : any = {
        numProt : this.documentoInfo.numeroProtocollo,
        annoProt : this.documentoInfo.annoProtocollo,
        titolario : titolario,
        numeroFascicolo : this.documentoInfo.numFascicolo,
        annoFascicolo : this.documentoInfo.annoFascicolo,
        numTitolo : this.documentoInfo.numTitolo
      }

      this.requestService.postRequest("/getListaEstratti", payload,
        success => {
          this.listaFascicoliEstratti = success.obj.list;
        }
      )*/


    }

      /****************** Per iter approvazione documento ********************** */
  get showApprovaDoc(){
    return (ScrivaniaComponent.getAbilitatoByNameFunction("documentiInLavorazione") || ScrivaniaComponent.getAbilitatoByNameFunction("documentiDaApprovare"))
    &&  this.documentoInfo?.pk?.annoProt < 0;
  }

  annullaProtocollo() : void{
      /*
    this.optionModal = new OptionModal();

    this.optionModal.titolo = "Annulla Protocollo";
    this.optionModal.showSaveButton = false;
    this.optionModal.setClassSizeMaxHeightModalBody("m");

    this.optionModal.setLoadDynamicComponent(true);
    this.optionModal.dynamicComponnet = EliminaProtocolloComponent;
    this.optionModal.objParameterComponent = {

    }

    this.dynamicModalannulla.initModal(this.optionModal);
    this.dynamicModalannulla.openModal();  */
  }


}
