import { Component, HostListener, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ShowObjViewer } from 'src/app/component/sub-component/viewer/ShowObjViewer';
import { AllegatoObj } from 'src/app/model/AllegatoObj';
import { DettaglioDocumentoRequest } from 'src/app/model/DettaglioDocumentoRequest';
import { PrimaryKeyFlussoMail } from 'src/app/model/PrimaryKeyFlussoMail';
import { RequestService } from 'src/app/service/requestService/request.service';
import { ResizeService } from 'src/app/service/resize/resize.service';
import { SCREEN_SIZE } from 'src/app/service/resize/screen-size.enum';
import { Utility } from 'src/app/Utility/Utility';

declare var $ : any;

@Component({
  selector: 'app-viewer-in-navigazione-modal',
  templateUrl: './viewer-in-navigazione-modal.component.html',
  styleUrls: ['./viewer-in-navigazione-modal.component.scss']
})
export class ViewerInNavigazioneModalComponent implements OnInit {

  @Input()
  documentoViewer : any = {}

  objParameterComponent : any = {};

  /**
   * Oggetto dell'allegato che viene visualizzato in quel moomento (di tipo AllegatoObj)
   */
  allegatoVisualizzato : AllegatoObj = new AllegatoObj() ; 

  /**
   * Oggetto preso da getDettaglioDocumento (vedere se fare la model di questo oggetto)
   */
  documentoInfo : any = {security : {}};

  /**
   * Lista che serve a costruire la lista delle conoscenze sulle infomrazioni prese da getDettaglioDocumento
   */
  elencoCC : any = [];

  objCompetenza : any = {};

    /**
   * Lista degli allegati di tipo AllegatoObj(model anche lato server)
   */
     @Input()
     listaAllegati : Array<AllegatoObj> = [];

  /**
   * Variabile utilizzata per passare dal component dei flussi di interoperabilità a quello del dettaglio l'oggetto
   * della mail da visualizzare nel viewer
   */
  pkFlussoMail : PrimaryKeyFlussoMail = new PrimaryKeyFlussoMail();


  /**
   * Variabile utilizzata per visualizzare i movimenti i un documento
   */
  showObjViewer : ShowObjViewer = new ShowObjViewer();

  isFirmaDigitale : boolean = false;

  loading : boolean = false;
  errorDocument : boolean = false;
  //Testo nel caso in cui ci sarà un errore di qualche tipo passato dal server
  textError : string = "";
  size : SCREEN_SIZE;

  constructor(private resizeSvc : ResizeService, private router : Router, private requestService : RequestService) { 
    this.size = this.resizeSvc.onResize();
  }

  @HostListener("window:resize", [])
  private onResizeFunction() {
    this.size = this.resizeSvc.onResize();
  }

  ngOnInit(): void {
    this.showObjViewer = new ShowObjViewer();

    if(Utility.checkObj(this.documentoViewer) && !Utility.checkObjIsEmpty(this.documentoViewer) && this.documentoViewer.documenti.length > 0) 
    {
      this.listaAllegati = this.documentoViewer.documenti;
      this.allegatoVisualizzato = this.listaAllegati[0];
      this.getAllegatoSelezionato(this.allegatoVisualizzato);
    }
    //prendo i dettagli del documento
    
    this.getDettaglioDocumento();
    
    
  }

  openMailViewer = (pkFlussoMail : PrimaryKeyFlussoMail) : void => {
    this.pkFlussoMail = pkFlussoMail;
    this.showObjViewer.setDettaglioEmail();
  }

  /*trasmettiDocumento() : void{
    $('#dynamicModal').modal('toggle');
    this.router.navigateByUrl('/modulo/gestioneDocumenti/trasmettiDocumento', { state: {numProt : this.documentoInfo.numeroProtocollo, annoProt : this.documentoInfo.annoProtocollo} });
  }*/

  buildObjCompetenezaAndListCC()
  {
    this.elencoCC = [];
    this.objCompetenza = this.documentoInfo.competenzaDoc[0];

    for(let i = 1; i < this.documentoInfo.competenzaDoc.length; i++){
      this.elencoCC.push(this.documentoInfo.competenzaDoc[i]);
    }
  }

  //*********************************************************** */
  //********************* RICHIESTE SERVER ******************** */
  //*********************************************************** */

  getAllegatoSelezionato(allegato){
    this.errorDocument = false;    
    this.allegatoVisualizzato = allegato;
    let pkAllegato = allegato.pk
    this.loading = true;

    
    this.requestService.postRequest("getDocumentoProtocollo", pkAllegato,
      (response) => {
        this.allegatoVisualizzato.signer = response.obj.signer;
        this.allegatoVisualizzato.url = response.obj.url;
        this.allegatoVisualizzato.converted = response.obj.converted;

        //Visto che se non è firma digitale il firmatario è solamente 1 e avrà solamente il campo descrizione compilato,
        // se signer ha più di 1 elemento, controllo solamente se il primo elemento ha nome, cognome o cf compilati.
        //Se sono compilati, allora la firma è digitale, altrimenti no.
        if(this.allegatoVisualizzato.signer != null && this.allegatoVisualizzato.signer.length > 0)
        {
          this.isFirmaDigitale = this.allegatoVisualizzato.signer[0].descrizione == null;
        }

        this.loading = false;
      },
      (error) => {
        this.loading = false;
        this.errorDocument = true;
        this.textError = Utility.getMessageByCode(error.message);
      }
    );
  }

    /**
   * Funzione utilizzata per prendere i dettagli del documento (non è lo stesso dei dettagli dell'allegato)
   */
     getDettaglioDocumento(){
      if(Utility.checkObj(this.allegatoVisualizzato.pk))
      { 
        let payload : DettaglioDocumentoRequest = new DettaglioDocumentoRequest();

        payload.setAnnoProtocollo(this.allegatoVisualizzato.pk.annoProt);
        payload.setNumeroProtocollo(this.allegatoVisualizzato.pk.numProt);
        this.requestService.postRequest("getDettaglioDocumento", payload,
          (response) => {
            this.documentoInfo = response.obj.Documento;
  
            this.objParameterComponent.dettaglioDocumentoRequest = payload;
            this.objParameterComponent.docNumString = this.documentoInfo.docNumString;
            
            this.buildObjCompetenezaAndListCC();
          }
        );
      }
    }

  //*********************************************************** */
  //*********************************************************** */
  //*********************************************************** */

}
