import { Injectable } from '@angular/core';
import { Utility } from 'src/app/Utility/Utility';

@Injectable({
  providedIn: 'root'
})
export class UserFunctionService {


  public static perm : string = "";

  constructor() {
    UserFunctionService.perm = Utility.getUoSelezionato().userFunction;
  }

    /**
   *
   *
   * @param i
   * @returns
   */
    getAccessFunction(i : number) : Boolean{
    let accessArray = UserFunctionService.perm.split("");
    return accessArray[i - 1] == "1";
  }

   /**
   *
   *
   * @param i
   * @returns
   */
    static getAccessFunction(i : number) : Boolean{
      let accessArray = this.perm.split("");
      return accessArray[i - 1] == "1";
    }



}
