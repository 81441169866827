<div class="d-flex justify-content-between mb-3 mt-1">
    <span><b>Nome:</b> {{user.nome}}</span>
    <span><b>Cognome:</b> {{user.cognome}}</span>
    <span><b>Matricola:</b> {{user.matricola}}</span>
</div>

<div class="form-group" *ngIf="user.aooAssociati.length >= 1">
    <label for="aoo">Selezionare AOO</label>
    <select (change)="getUoByCodAoo(user.codAoo)" [disabled]="user.aooAssociati.length == 1" class="form-control" id="aoo" [(ngModel)]="user.codAoo">
        <option selected  [ngValue]="null">Seleziona...</option>
        <option *ngFor="let aoo of user.aooAssociati" [ngValue]="aoo.cod_aoo">{{aoo.descrizione}}</option>
    </select>
</div>

<div class="form-group" *ngIf="user.uoAssociati.length > 1">
    <label for="uo">Selezionare Unità Organizzativa/AGD</label>
    <select [disabled]="user.codAoo == null || listUo.length == 0" class="form-control" id="uo" [(ngModel)]="user.codUOAD">
        <option [ngValue]="null">Seleziona...</option>
        <option *ngFor="let uo of listUo" [ngValue]="uo.codUO" >{{uo.desUO}}</option>
    </select>
</div>



