import { Injectable } from '@angular/core';
import { NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

@Injectable({
  providedIn: 'root'
})
export class CustomDateParserFormatterService extends NgbDateParserFormatter {
 
  readonly DELIMITER = '/';
 
  parse(value: string): NgbDateStruct | null {
    if (value) {
      let date = value.split(this.DELIMITER);
      return {
        day : parseInt(date[0], 10),
        month : parseInt(date[1], 10),
        year : parseInt(date[2], 10)
      };
    }
    return null;
  }

  format(date: NgbDateStruct | null): string {
    return date ? (date.day < 10 ? "0" + date.day : date.day) + this.DELIMITER + (date.month < 10 ? "0" + date.month : date.month) + this.DELIMITER + date.year : '';
  }

  static formatJson(date: any | null): string {
    return date ? (date.day) + "/" + (date.month) + "/" + date.year : '';
  }

  static convertNgbDateStructToDate(ngbDate : any){
      return new Date(ngbDate.year, ngbDate.month - 1, ngbDate.day);
  }

  static convertNgbDateStructToString(ngbDate : any){
    return (ngbDate.day < 10 ? ("0" + ngbDate.day) : ngbDate.day) + "/" +  (ngbDate.month < 10 ? ("0" + ngbDate.month) : ngbDate.month) + "/"  +ngbDate.year;
}

}


