import { Utility } from "src/app/Utility/Utility";

export const initFormWithData = (obj : any) : any =>  {
  let objProtocollo : any = obj;

  /************************************************* */
  //Per la data del datapicker pgArrivodel
  if(objProtocollo.pgArrivoDel != null)
  {
    objProtocollo.pgArrivoDel = objProtocollo.pgArrivoDel.split("-")[2]+"/"+objProtocollo.pgArrivoDel.split("-")[1]+"/"+objProtocollo.pgArrivoDel.split("-")[0];
  }

  if(objProtocollo.dataEffett != null)
  {
    objProtocollo.dataEffett = objProtocollo.dataEffett.split("-")[2]+"/"+objProtocollo.dataEffett.split("-")[1]+"/"+objProtocollo.dataEffett.split("-")[0];
  }
  /************************************************* */
  return objProtocollo;
}

export const buildCompetenze = (competenze : any[]) : any[] => {
  competenze.map(element => {
    let obj = Utility.deepCopyObject(element);

    if(element.hasOwnProperty("children"))
    {
      obj.codicePadre = element.codice;
      obj.codice = -1;
    }
  });

  return competenze;
}
