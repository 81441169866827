import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { LoginComponent } from './component/login/login.component';
import { ViewerExtComponent } from './component/viewer-ext/viewer-ext.component';
import { ViweDocComponent } from './component/viwe-doc/viwe-doc.component';
//import { CanDeactivateGuardService } from './service/CanDeactivateService/can-deactivate-guard.service';



const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  { path: 'viewer', component: ViewerExtComponent },
  { path: 'viewDoc/:token', component: ViweDocComponent },
  {
    path: 'modulo',
    //canDeactivate: [CanDeactivateGuardService],
    loadChildren: () => (import('./component/moduli/scrivania-module/scrivania-module.module')).then(c => c.ScrivaniaModuleModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled', useHash: true })],


  exports: [RouterModule]
})
export class AppRoutingModule { }
