import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ModalComponent } from 'src/app/component/sub-component/modal/modal.component';
import { OptionModal } from 'src/app/component/sub-component/modal/OptionModal';
import { Collocazione } from 'src/app/model/Collocazione';
import { FascicoliRequest } from 'src/app/model/FascicoliRequest';
import { Titolario } from 'src/app/model/Titolario';
import { DataStringTransformPipe } from 'src/app/pipe/dataStringTransform/data-string-transform.pipe';
import { GlobalService } from 'src/app/service/globalService/global.service';
import { GlobalObj } from 'src/app/service/globalService/GlobalObj';
import { RequestService } from 'src/app/service/requestService/request.service';
import { Utility } from 'src/app/Utility/Utility';
import { AlberoCollocazioneComponent } from '../../ricerche/albero-collocazione/albero-collocazione.component';
import { NavigazioneComponent } from '../../ricerche/navigazione/navigazione.component';

@Component({
  selector: 'app-form-fascicolo',
  templateUrl: './form-fascicolo.component.html',
  styleUrls: ['./form-fascicolo.component.scss']
})
export class FormFascicoloComponent implements OnInit {

  @Output() fascicoloModificatoEvent : EventEmitter<null> = new EventEmitter();

  objParameterComponent : any = {};

  @Input()
  isNuovoFascicolo : boolean = false;

  @Input()
  modalMode : boolean = false;
  showAlert : boolean = false;
  messageAlert : string = "";
  showAlberoCollocazione : boolean = false;

  @Input()
  modalModificaFascicolo : FascicoliRequest;

  waitCheckClassificazione : boolean = false;
  waitCheckCollocazione : boolean = false;

  /**DA PENSARE COME GENERALIZZARE PER FARE UNA SOLA CHIAMATA */
  GLOBALI : GlobalObj;

  mapSectionOpened : any = {
    sectionClassificazione : true,
    sectionInfo : true
  }

  titolarioSelezionato : any = null;
  collocazioneSelezionata : any = null;


  //****************************************************** */
  //Variabili ulitilizzate per dichiarare e inizializzare la modale
  //****************************************************** */
  @ViewChild('dynamicModalComponent')
  dynamicModal : ModalComponent;
  optionModal : OptionModal = new OptionModal();
  //****************************************************** */
  //****************************************************** */

  //Variabile per capire se la form è in modalità update o insert
  updateMode : boolean = false;



  disalbeForm : boolean = false;
  justChecked : boolean = false;
  showEditClassificazione : boolean = false;
  showEditCollocazione : boolean = false;

  //Variabile che al suo interno contiene
  fascNumStringUpdate : string = "";

  //Variabile utilizzata per visualizzare e nascondere il pulsante di submit
  showSave : boolean = true;
  showNewFascicolo : boolean = false;

  fascicoloForm : FormGroup;

  constructor(
    private formBuilder : FormBuilder,
    private requestService : RequestService,
    private globalService : GlobalService,
    private router : Router
    ) {
      this.GLOBALI = this.globalService.GLOBAL;
      this.initForm();

    if(this.router.url == "/modulo/gestioneFascicoli/modificaFascicolo" && this.router.getCurrentNavigation() != null && this.router.getCurrentNavigation().extras.state != undefined)
    {
      let objMod : FascicoliRequest = this.router.getCurrentNavigation().extras.state as FascicoliRequest;
      this.updateMode = true;
      this.initModificaFascicolo(objMod);
    }
  }

  ngOnInit(): void {
    //Per la modifica deve arrivare un oggetto di tipo FascicoliRequest dal component chiamante

    if(this.modalMode && !!this.modalModificaFascicolo)
    {
      this.updateMode = !this.isNuovoFascicolo;
      if(this.isNuovoFascicolo)
      {

        for(let i = 1; i <= 6; i++) this["titolo"+i].setValue(this.modalModificaFascicolo["titolo"+i]);
        this.checkClassificazione();
      }
      else
      {
        this.initModificaFascicolo(this.modalModificaFascicolo);
      }

      //this.initModificaFascicolo(this.modalModificaFascicolo);
    }


    this.checkValidatorTitolarioFascicolo();
    this.setValidatorWithGlobal();
  }



  /**
   * Metodo utilizzato per inizializzare il form
   */
  initForm() : void{

    //Creo oggetto con i campi che ci sono sempre e a seconda le globali riempo l'oggetto con i vari formControl
    let objForm : any = {
      //Campi sezione classificazione
      titolo1 : new FormControl(""),
      titolo2 : new FormControl(""),
      titolo3 : new FormControl(""),
      titolo4 : new FormControl(""),
      titolo5 : new FormControl(""),
      titolo6 : new FormControl(""),
      checkedTitolario : new FormControl(false, Validators.requiredTrue),

      //Campi sezione info
      oggetto : new FormControl("", Validators.required),
      note : new FormControl(""),
      collocazione1 : new FormControl(""),
      collocazione2 : new FormControl(""),
      collocazione3 : new FormControl(""),
      collocazione4 : new FormControl(""),
      collocazione5 : new FormControl(""),
      collocazione6 : new FormControl(""),
      checkedCollocazione : new FormControl(false), //, Validators.requiredTrue
      soggetto : new FormControl(""),
      progr_anno : new FormControl(),
      numTitolo : new FormControl(),
      dataApertura : new FormControl(),
      dataEvidenza : new FormControl(),
      dataMessaAtti : new FormControl(),

      numCollocazione : new FormControl(),
      numProced : new FormControl(),
      codiceScarto : new FormControl(),
      statoAtti : new FormControl(),
      agdMittenteAgliAtti : new FormControl(),
      uoMittenteAgliAttti : new FormControl(),
      agliAttiDaRicevere : new FormControl(),
      riservato : new FormControl()
    }

    objForm.numeroFascicolo = new FormControl();
    objForm.annoFascicolo = new FormControl();

    this.fascicoloForm = this.formBuilder.group(objForm);
  }

  initModificaFascicolo(datifascicolo : any) : void{
    this.requestService.postRequest("loadFascicolo", datifascicolo,
      (success) => {
        this.fascicoloForm.patchValue(success.obj);
        this.fascNumStringUpdate = datifascicolo.numeroFascicolo + " / " + datifascicolo.annoFascicolo + (success.obj.dataApertura ? " aperto il " +  new DataStringTransformPipe().transform(success.obj.dataApertura) : "");
        this.checkClassificazione();

        if(this.GLOBALI.PRO_COLLOCAZIONE_FISICA && this.collocazione1.value > 0)
        {
          this.checkCollocazione();
        }
      }
    )
  }

  //******************************************************************** */
  //*************************** GETTER FORM **************************** */
  //******************************************************************** */

  get numeroFascicolo(){
    return this.fascicoloForm.get("numeroFascicolo");
  }

  get annoFascicolo(){
    return this.fascicoloForm.get("annoFascicolo");
  }

  get note(){
    return this.fascicoloForm.get("note");
  }

  get titolo1(){
    return this.fascicoloForm.get("titolo1");
  }

  get titolo2(){
    return this.fascicoloForm.get("titolo2");
  }

  get titolo3(){
    return this.fascicoloForm.get("titolo3");
  }

  get titolo4(){
    return this.fascicoloForm.get("titolo4");
  }

  get titolo5(){
    return this.fascicoloForm.get("titolo5");
  }

  get titolo6(){
    return this.fascicoloForm.get("titolo6");
  }

  get oggetto(){
    return this.fascicoloForm.get("oggetto");
  }

  get soggetto(){
    return this.fascicoloForm.get("soggetto");
  }

  get paroleChiave(){
    return this.fascicoloForm.get("paroleChiave");
  }

  get collocazione1(){
    return this.fascicoloForm.get("collocazione1");
  }

  get collocazione2(){
    return this.fascicoloForm.get("collocazione2");
  }

  get collocazione3(){
    return this.fascicoloForm.get("collocazione3");
  }

  get collocazione4(){
    return this.fascicoloForm.get("collocazione4");
  }

  get collocazione5(){
    return this.fascicoloForm.get("collocazione5");
  }

  get collocazione6(){
    return this.fascicoloForm.get("collocazione6");
  }

  get checkedTitolario()
  {
    return this.fascicoloForm.get("checkedTitolario");
  }

  get checkedCollocazione()
  {
    return this.fascicoloForm.get("checkedCollocazione");
  }


  /**
 * Funzione che verifica se il primo tentativo di login è stato effettuato e se un determinato campo è valido
 *
 * field : nome del campo da verificare
 *
 * @returns il valore true se il campo non è valido
 */
    checkControlName(field) : boolean{
    return this.justChecked && !this.fascicoloForm.controls[field].valid;
  }

  //******************************************************************** */
  //******************************************************************** */
  //******************************************************************** */

  /**
 * Funzione che verifica la validazione dei vari campi del titolario (da titolo1 a titolo6)
 *
 * @returns il valore true o false se è valido o no
 */
    checkControlTitolario() : boolean{

    let valid : boolean = true;
    for(let i = 1; i <= 6; i++)
    {
      valid = valid && !this.checkControlName("titolo"+i);
    }
    return this.justChecked && !valid;
  }


  /**
   * Metodo che setta il required ai campi della classificazione a seconda se sono visibili (due_fasi = si)
   * TODOOOOOOOOOOOOOOOOOOOOOOO
   */
   checkValidatorTitolarioFascicolo(){

    this.titolo1.setValidators([Validators.required, Validators.minLength(1), Validators.maxLength(3)]);
    this.titolo2.setValidators([Validators.required, Validators.minLength(1), Validators.maxLength(3)]);
    this.titolo3.setValidators([Validators.required, Validators.minLength(1), Validators.maxLength(3)]);
    this.titolo4.setValidators([Validators.required, Validators.minLength(1), Validators.maxLength(3)]);
    this.titolo5.setValidators([Validators.required, Validators.minLength(1), Validators.maxLength(3)]);
    this.titolo6.setValidators([Validators.required, Validators.minLength(1), Validators.maxLength(3)]);
  }

  setValidatorWithGlobal() : void{
    if(this.GLOBALI.PRO_SOGGETTO)
    {
      this.soggetto.setValidators(Validators.required);
    }
  }

  //******************************************************************** */
  //******************* FUNZIONI DI GESTIONE SEZIONI ******************* */
  //******************************************************************** */

  openSection(idSection : string) : void
  {
    Utility.openSection(idSection, this.mapSectionOpened);
  }

  checkSectionOpened(idSection : string) : boolean{
    return Utility.checkSectionOpened(idSection, this.mapSectionOpened);
  }

  //******************************************************************** */
  //******************************************************************** */
  //******************************************************************** */


  /**
   * Funzione passata alla modale dinamica della navigazione per permettere di riportare indietro e settare i campi riguardanti il titolario
   *
   * @param titolario : elemento selezionato e da riportare
   */
   riportaTitolario = (titolario : any) : void =>{

    this.setTitolarioFromNavigazioneObj(titolario, "titolo");

    //Set altre info da titolario
    this.titolarioSelezionato = titolario;
    this.dynamicModal.closeModal();
    this.checkedTitolario.setValue(true);
    this.showEditClassificazione = true;
  }

  setTitolarioFromNavigazioneObj(object : any, type : string){
    let titolarioValue = object.valore.split(".");
    for(let i = 0; i < 6; i ++)
    {
      this[type + (i+1)].setValue(i < titolarioValue.length ? titolarioValue[i] : 0);
    }
  }


  /**
   *
   * Metodo utilizzato per inizializzare e aprire la modale
   * con la lista degli agd uo da scegliere (questo avviene per utente)
   */
   openNavigazioneTitolario() : void{

    this.optionModal = new OptionModal();
    this.optionModal.showHeaderModal = false;
    this.optionModal.additionalClassModalBody = "p-0";

    this.optionModal.setSizeModal("90");
    this.optionModal.maxSizeHeightModal = true;
    this.optionModal.showSaveButton = false;


    let titolarioFromForm : Titolario = null;
    if(this.titolo1.value != "")
    {
      titolarioFromForm = new Titolario();
      for(let i = 1; i <= 6; i++)
      {
        titolarioFromForm["titolo"+i] = this["titolo"+i].value;
      }
    }


    //Opzioni per settare il component da caricare dinamicamente
    //******************************************************* */
    this.optionModal.setLoadDynamicComponent(true);
    this.optionModal.dynamicComponnet = NavigazioneComponent;
    this.optionModal.objParameterComponent = {
      modalMode : true,

      //Funzione di riporto per titolario
      riportaTitolario : this.riportaTitolario,

      titolarioFromForm : titolarioFromForm
    }

    this.dynamicModal.initModal(this.optionModal);
    this.dynamicModal.openModal();
  }

  /**
   * Funzione passata alla modale dinamica della navigazione per permettere di riportare indietro e settare i campi riguardanti il titolario
   *
   * @param titolario : elemento selezionato e da riportare
   */
   riportaCollocazione = (collocazione : any) : void =>{

    this.setTitolarioFromNavigazioneObj(collocazione, "collocazione");

    //Set altre info da titolario
    this.collocazioneSelezionata = collocazione;

    this.checkedCollocazione.setValue(true);
    this.showEditCollocazione = true;

    if(this.modalMode)
      this.showAlberoCollocazione = false;
    else
      this.dynamicModal.closeModal();
  }



  openNavigazioneCollocazione(): void{

    let collocazioneFromForm : Collocazione = null;
    if(this.collocazione1.value != "")
    {
      collocazioneFromForm = new Collocazione();
      for(let i = 1; i <= 6; i++)
      {
        collocazioneFromForm["col"+i] = this["collocazione"+i].value;
      }
    }

    this.objParameterComponent = {
      modalMode : true,
      riportaCollocazione : this.riportaCollocazione, //Funzione di riporto per collocazione
      collocazioneFromForm : collocazioneFromForm
    }


    if(!this.modalMode)
    {
      this.optionModal = new OptionModal();
      this.optionModal.titolo = "Collocazione fisica";


      this.optionModal.setSizeModal("90");
      this.optionModal.maxSizeHeightModal = true;
      this.optionModal.showSaveButton = false;

      this.optionModal.objParameterComponent = this.objParameterComponent;

      //Opzioni per settare il component da caricare dinamicamente
      //******************************************************* */
      this.optionModal.setLoadDynamicComponent(true);
      this.optionModal.dynamicComponnet = AlberoCollocazioneComponent;

      this.dynamicModal.initModal(this.optionModal);
      this.dynamicModal.openModal();
    }
    else
    {
      this.showAlberoCollocazione = true;
    }
  }


  checkClassificazione(){

    this.waitCheckClassificazione = true;

    let titolario : Titolario = new Titolario();
    this.titolarioSelezionato = null;


    //1 elemento come risposta
    let valid : boolean = true;
    for(let i = 1; i <= 6; i++)
    {
      this["titolo"+i].setValue((this["titolo"+i].value == "" || this["titolo"+i].value == undefined || this["titolo"+i].value == null) ? 0 : this["titolo"+i].value);
      valid = valid && !this.checkControlName("titolo"+i);
      titolario["titolo"+i] = this["titolo"+i].value;
    }

    if(valid)
    {
      this.requestService.postRequest("searchTitolario", titolario,
        (response) => {
          let listTitolario = response.obj.list;
          if(listTitolario.length == 0)
          {
            this.optionModal = new OptionModal();
            this.optionModal.titolo = "Verifica classificazione";
            this.optionModal.textBodyModal = "Non è stato trovato alcun valore con il titolario inserito";

            this.optionModal.showSaveButton = false;
            this.optionModal.showCloseButton = true;
            this.optionModal.setClassSizeMaxHeightModalBody("m");

            this.dynamicModal.initModal(this.optionModal);
            this.dynamicModal.openModal();
            this.waitCheckClassificazione = false;
          }
          else if (listTitolario.length == 1 && listTitolario[0].terminale)
          {
            let titolarioTrovato = listTitolario[0];
            this.setTitolarioFromNavigazioneObj(titolarioTrovato, "titolo");
            this.waitCheckClassificazione = false;
            this.showEditClassificazione = true;
            this.checkedTitolario.setValue(true);

            this.titolarioSelezionato = titolarioTrovato;
          }
          else
          {
            this.waitCheckClassificazione = false;
            this.openNavigazioneTitolario();
          }

        },
        () => {
          this.waitCheckClassificazione = false;
        }
      );
    }
  }

  checkCollocazione() : void{
    this.waitCheckCollocazione = true;
    let collocazione : Collocazione = new Collocazione();
    this.collocazioneSelezionata = null;


    //1 elemento come risposta
    let valid : boolean = true;
    for(let i = 1; i <= 6; i++)
    {
      this["collocazione"+i].setValue((this["collocazione"+i].value == "" || this["collocazione"+i].value == undefined || this["collocazione"+i].value == null) ? 0 : this["collocazione"+i].value);
      valid = valid && !this.checkControlName("collocazione"+i);
      collocazione["col"+i] = this["collocazione"+i].value;
    }


    if(valid)
    {

      this.requestService.postRequest("searchCollocazione", collocazione,
        (response) => {
          let listCollocazione = response.obj.list;
          if(listCollocazione.length == 0)
          {
            this.optionModal = new OptionModal();
            this.optionModal.titolo = "Verifica Collocazione";
            this.optionModal.textBodyModal = "Non è stato trovato alcun valore con la collocazione inserita";

            this.optionModal.showSaveButton = false;
            this.optionModal.showCloseButton = true;
            this.optionModal.setClassSizeMaxHeightModalBody("m");

            this.dynamicModal.initModal(this.optionModal);
            this.dynamicModal.openModal();
            this.waitCheckClassificazione = false;
          }
          else if (listCollocazione.length == 1 && listCollocazione[0].terminale)
          {
            let collocazione = listCollocazione[0];
            this.setTitolarioFromNavigazioneObj(collocazione, "collocazione");
            this.waitCheckCollocazione = false;
            this.showEditCollocazione = true;
            this.checkedCollocazione.setValue(true);

            this.collocazioneSelezionata = collocazione;
          }
          else
          {
            this.waitCheckCollocazione = false;
            this.openNavigazioneCollocazione();
          }

        },
        () => {
          this.waitCheckCollocazione = false;
        }
      );

    }
  }

  editClassficazione() : void{
    this.showEditClassificazione = false;
    this.titolarioSelezionato = null;
    this.checkedTitolario.setValue(false);
  }

  editCollocazione() : void{
    this.showEditCollocazione = false;
    this.collocazioneSelezionata = null;
    this.checkedCollocazione.setValue(false);
  }



  onSubmit() : void{
    let urlRequest : string = this.updateMode ? "modificaFascicolo" : "creaFascicolo"

    this.justChecked = true;

    if(this.fascicoloForm.valid)
    {
      this.requestService.postRequest(urlRequest, this.fascicoloForm.getRawValue(),
        (success) => {
          if(this.updateMode)
          {
            this.fascicoloModificatoEvent.emit();
          }
          let messaggio = "La " + (this.updateMode ? "modifica del fascicolo" : "creazione del fascicolo (" + success.obj.numFascicolo + " / " +  success.obj.annoFascicolo + ")" ) + "  è avvenuta con successo";

          if(this.modalMode)
          {
            this.showAlert = true;
            this.messageAlert = messaggio;
          }
          else
          {
            this.optionModal = new OptionModal();
            this.optionModal.titolo = this.updateMode ? "Modifica fascicolo " + this.fascNumStringUpdate : "Creazione fascicolo";
            this.optionModal.textBodyModal = messaggio;

            this.optionModal.showSaveButton = false;
            this.optionModal.showCloseButton = true;
            this.optionModal.setClassSizeMaxHeightModalBody("m");

            this.dynamicModal.initModal(this.optionModal);
            this.dynamicModal.openModal();
          }

          this.disalbeForm = true;
          this.showSave = false;

          this.showNewFascicolo = true;
        }
      );
    }
  }


  resetForm(){
    this.disalbeForm = false;
    this.showSave = true;
    this.justChecked = false;
    this.showNewFascicolo = false;

    if(!this.updateMode)
    {
      this.titolarioSelezionato = null;
      this.showEditClassificazione = false;
      this.initForm();
      this.ngOnInit();
    }
  }
}
