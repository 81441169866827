<div class="d-flex spaced-input">
    <div class="d-flex spaced-input  w-100">
        <div class="form-group w-100" [ngClass]="{'text-danger' : checkControlTitolario()}">
            <label>Titolario*</label>
            <div class="d-flex input-classificazione spaced-input">
                <input [readonly]="showEditClassificazione" type="text" class="form-control text-center" placeholder="" aria-label="" aria-describedby="basic-addon1" [ngClass]="{'border-danger' : checkControlName('titolo1')}" [formControl]="titolo1" maxlength="3">
                <input [readonly]="showEditClassificazione" type="text" class="form-control text-center" placeholder="" aria-label="" aria-describedby="basic-addon1" [ngClass]="{'border-danger' : checkControlName('titolo2')}" [formControl]="titolo2" maxlength="3">
                <input [readonly]="showEditClassificazione" type="text" class="form-control text-center" placeholder="" aria-label="" aria-describedby="basic-addon1" [ngClass]="{'border-danger' : checkControlName('titolo3')}" [formControl]="titolo3" maxlength="3">
                <input [readonly]="showEditClassificazione" type="text" class="form-control text-center" placeholder="" aria-label="" aria-describedby="basic-addon1" [ngClass]="{'border-danger' : checkControlName('titolo4')}" [formControl]="titolo4" maxlength="3">
                <input [readonly]="showEditClassificazione" type="text" class="form-control text-center" placeholder="" aria-label="" aria-describedby="basic-addon1" [ngClass]="{'border-danger' : checkControlName('titolo5')}" [formControl]="titolo5" maxlength="3">
                <input [readonly]="showEditClassificazione" type="text" class="form-control text-center" placeholder="" aria-label="" aria-describedby="basic-addon1" [ngClass]="{'border-danger' : checkControlName('titolo6')}" [formControl]="titolo6" maxlength="3">
            </div>
            <small *ngIf="checkControlTitolario()" id="oggettoRequired" class="form-text">Classificazione obbligatoria</small>
        </div>

        <div class="form-group w-100" [ngClass]="{'text-danger' : checkControlName('numeroFascicolo')}">
            <label>N° fascicolo*</label>
            <input [readonly]="showEditClassificazione" maxlength="3" type="text" class="form-control" [formControl]="numeroFascicolo" aria-describedby="numeroFascicoloRequired" placeholder="" [ngClass]="{'border-danger' : checkControlName('numeroFascicolo')}">
            <small *ngIf="checkControlName('numeroFascicolo')" id="numeroFascicoloRequired" class="form-text">Campo obbligatorio</small>
        </div>
        
        <div class="form-group w-100" [ngClass]="{'text-danger' : checkControlName('annoFascicolo')}">
            <label>Anno fascicolo*</label>
            <input [readonly]="showEditClassificazione" maxlength="4" type="text" class="form-control" [formControl]="annoFascicolo" aria-describedby="AnnoFascicoloRequired" placeholder="" [ngClass]="{'border-danger' : checkControlName('annoFascicolo')}">
            <small *ngIf="checkControlName('annoFascicolo')" id="AnnoFascicoloRequired" class="form-text">Campo obbligatorio</small> 
        </div>

        <div class="form-group w-100"></div>


        <div class="d-flex action-icon-protocol pt-1 justify-content-end">

            <div class="container-icon border">
                <i *ngIf="!showEditClassificazione && !waitCheckClassificazione" class="cursor-pointer icon-target" (click)="checkClassificazione()" title="Verifica titolario e fascicolo"></i>
                <div *ngIf="waitCheckClassificazione" class="loader-mini-spinner"></div>
                <i *ngIf="showEditClassificazione" class="icon-edit" (click)="enableEditClassificazione()"></i>
            </div>

            <div class="container-icon border">
                <i class="cursor-pointer icon-trash" (click)="deleteAllFiledClassificazione()" title="Pulischi campi classificazione"></i>
            </div>

            <div class="container-icon border">
                <i class="cursor-pointer icon-search" (click)="openNavigazioneTitolario()" title="Apri navigazione titolario e fascicoli"></i>
            </div>
            <div class="container-icon border">
                <i class="cursor-pointer icon-file-pdf" title="Stampa carpetta fascicolo"></i> 
            </div>
            <div class="container-icon empty"></div>
        </div>

    </div>
</div>

<div class="d-flex" *ngIf="titolarioSelezionato != null || fascicoloSelezionato != null">
    <div class="info-titolario d-flex flex-column card-shadow border p-3 mb-2 bg-white w-100">
        <div class="d-flex" *ngIf="titolarioSelezionato != null">
            <span class="mr-2">
                <b>Titolario: </b>{{titolarioSelezionato.description}}
            </span>
        </div>
        <div class="d-flex" *ngIf="fascicoloSelezionato != null">    
            <span class="mr-2" >
                <b>Fascicolo: </b> {{fascicoloSelezionato.fascPK.numFascicolo}} / {{fascicoloSelezionato.fascPK.annoFascicolo}} (<b>Progr:</b> {{fascicoloSelezionato.fascPK.numTitolo}} / {{fascicoloSelezionato.fascPK.annoFascicolo}}) -
                <div *ngIf="GLOBALI.PRO_SOGGETTO && fascicoloSelezionato.soggetto != null && fascicoloSelezionato.soggetto != undefined && fascicoloSelezionato.soggetto != ''"><b>Soggetto:</b> {{fascicoloSelezionato.soggetto}} </div>
                <b>Oggetto:</b> {{fascicoloSelezionato.oggetto}}
            </span>
        </div>
        <div class="d-flex" *ngIf="fascicoloSelezionato != null">       
            <span>
                <b> In carico a: </b> {{fascicoloSelezionato.codRice.descrizione}} ({{fascicoloSelezionato.codRice.codice}}) 
                <ng-template  [ngIf]="fascicoloSelezionato.codRice.uo != null">{{fascicoloSelezionato.codRice.uo.descrizione}} ({{fascicoloSelezionato.codRice.uo.codice}}) </ng-template> 
            </span>
        </div>   

    </div>

    <div class="d-flex action-icon-protocol spaced-input pt-1 justify-content-end">
        <div class="container-icon empty"></div>
        <div class="container-icon empty"></div>
        <div class="container-icon empty"></div>
        <div class="container-icon empty"></div>
        <div class="container-icon empty"></div>
    </div>
   
</div>