import { getLabelUo, Utility } from "../Utility/Utility";

export class Referente{
    cf : string;
    email : string;
    inviaSemprePerMail : boolean;
    bloccaReferente : boolean;
    viaResidenza : string;
    numResidenza : string;
    comuneResidenza : string;
    capResidenza : string;
    provinciaResidenza : string;
    telefono1 : string;
    telefono2 : string;
    referenteTipo: number;
    referenteCodice: number;
    descrizione : string; //Questo campo è fittizio e viene usato solo per la visualizzazione delle info

    constructor(obj : any){
        this.cf = obj.cf;
        this.email = obj.email;
        this.inviaSemprePerMail = obj.inviaSemprePerMail;
        this.bloccaReferente = obj.bloccaReferente;
        this.viaResidenza = obj.viaResidenza;
        this.numResidenza = obj.numResidenza;
        this.comuneResidenza = obj.comuneResidenza;
        this.capResidenza = obj.capResidenza;
        this.provinciaResidenza = obj.provinciaResidenza;
        this.telefono1 = obj.telefono1;
        this.telefono2 = obj.telefono2;
        this.referenteTipo= obj.referenteTipo;
        this.referenteCodice= obj.referenteCodice;
        this.descrizione = obj.descrizione;
    }

    getDescrizioneForView() : string{
        let tipo = Utility.getStringReferenteByCodReferente(this.referenteTipo);
        if(tipo == "UO" && getLabelUo() != "UO")
        {
          tipo = Array.from(getLabelUo())[0];
        }
        return "("+ tipo +") " + this.descrizione + " (" + tipo + " - " + this.referenteCodice + ")";
    }
}
