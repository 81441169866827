export class OpzioniMenu{
    id : number;
    voce : string;                  //descrizione lato codice
    descrizione : string;           //descrizione per l'utente
    idPadre : number;               //corrisponde all'id del padre
    ordine : number;
    posizione : number;             //posizione nella stringa userFunction
    visibile : boolean;             //indica se deve essere visualizzato oppure no la voce di menu

    /************************* */
    children : Array<OpzioniMenu>;  //lista che contiene i figli di una voce di primo livello (viene riempita dopo lato client per ogni valore che possiede figli)
    routerLink : string = "";            //link di route per raggiungere la pagina dell'elemento di menu selezionato
    

    constructor(obj : any){
        if(obj){
            this.id = Number(obj.id);
            this.voce = obj.voce;
            this.descrizione = obj.descrizione;
            this.idPadre = Number(obj.idPadre);
            this.ordine = Number(obj.ordine);
            this.posizione = Number(obj.posizione);
            this.visibile = obj.visibile;
        }
    }


}