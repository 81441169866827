import { Component, Input, OnInit } from '@angular/core';
import { ArchiPROInfoModel } from './../../../model/ArchiPROInfoModel';
import { RequestService } from './../../../service/requestService/request.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent{


  @Input()
  infoArchiPro : ArchiPROInfoModel = new ArchiPROInfoModel();
  
  constructor() {
    
  }
}
