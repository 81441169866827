<form class="d-flex flex-column" [formGroup]="formObj">
  <div class="form-group w-100" >
    <label>Stato</label>
    <select id="statoNP" class="form-control" formControlName="statoNP">
        <option [value]=""></option>
        <option *ngFor="let option of optionStato" [value]="option.value">{{option.label}}</option>
    </select>
  </div>

  <div class="form-group w-100" [ngClass]="{'text-danger' : false}">
    <label>Note</label>
    <textarea class="form-control" placeholder="" formControlName="note" id="note" rows="3"></textarea>
    <small *ngIf="false" id="oggettoRequired" class="form-text">Campo obbligatorio</small>
  </div>


    <!--Gestione file protocollo-->
    <div class="d-flex align-items-center  spaced-input">
      <div class="form-group w-100">
          <label class="">Allega documenti*</label>
          <div class="custom-file cursor-pointer">
              <input #inputFiles type="file" class="custom-file-input form-control" id="files" multiple (change)="changeListFiles($event)">
              <label class="custom-file-label " for="files">Scegli </label>
          </div>
      </div>
      <div class="d-flex form-group w-100">
          <div *ngIf="globalService.GLOBAL.PRO_VEDI_SCANSIONE" class="d-flex w-100 text-nowrap align-items-center action-icon-protocol cursor-pointer spaced-input pt-4 text-primary-archipro" (click)="addScansioneCartaceo('scansione')">
              <div class="position-relative composed-icon-pedice">
                  <i class="icon-attach"  title="Allega scansione"></i>
                  <i class="icon-plus pedice"  title="Allega scansione"></i>
              </div>
              Aggiungi scansione
          </div>

          <div *ngIf="globalService.GLOBAL.PRO_VEDI_CARTACEO" class="d-flex w-100 text-nowrap align-items-center action-icon-protocol cursor-pointer spaced-input pt-4 text-primary-archipro" (click)="addScansioneCartaceo('cartaceo')">
              <div class="position-relative composed-icon-pedice">
                  <i class="icon-attach"  title="Allega scansione"></i>
                  <i class="icon-plus pedice"  title="Allega scansione"></i>
              </div>
              Aggiungi cartaceo
          </div>
      </div>
      <div class="form-group w-100"></div>
      <div class="d-flex action-icon-protocol spaced-input pt-2">
          <div class="container-icon empty"></div>
          <div class="container-icon empty"></div>
          <div class="container-icon empty"></div>
          <div class="container-icon empty"></div>
          <div class="container-icon empty"></div>
      </div>
  </div>

  <!--container lista file-->
  <div class="w-100 d-flex flex-column container-file"  *ngIf="files.length > 0">

      <div class="d-flex pl-2 pr-2 w-100">
          <i class="tmp"></i>
          <div class="form-group mb-0 w-100" [ngClass]="{'text-danger' : false}">
              <label class="mb-0">Files</label>
          </div>
          <div class="form-group mb-0 w-100" [ngClass]="{'text-danger' : false}">
              <label class="mb-0">Descrizione</label>
          </div>
          <div class="d-flex action-icon-protocol spaced-input">
              <div class="container-icon empty mt-0"></div>
              <div class="container-icon empty mt-0"></div>
              <div class="container-icon empty mt-0"></div>
              <div class="container-icon empty mt-0"></div>
              <div class="container-icon empty mt-0"></div>
          </div>
      </div>

      <div class="d-flex align-items-center file-element spaced-input border bg-white {{(file.errors && file.errors.noPadesOrP7m) ? 'border-danger' : ''}}" formArrayName="files" *ngFor="let file of files.controls; let i = index;">
          <div class="d-flex w-100 align-items-center">
              <!--<i class="{{getFileClass(file.value.file.name)}}"></i>-->
              <span *ngIf="i == 0; else allegato" class="icona-conoscenza pr-2 pl-2 text-primary-archipro text-center">P</span>
              <ng-template #allegato>
                  <span class="icona-conoscenza pr-2 pl-2 text-primary-archipro text-center">A</span>
              </ng-template>

              <div class="ml-1 title-file-upload">
                  <span>{{file.value.file.name}}</span>
              </div>
          </div>

          <div class="d-flex w-100">
              <div class="form-group mb-0 w-100">
                  <span [ngClass]="{'text-danger' : false, 'd-none' : file.edit}">{{file.value.file.description}}</span>
                  <input type="text" class="form-control" [ngClass]="{'text-danger' : false, 'd-none' : !file.edit}" placeholder="{{file.value.file.description}}" value="{{file.value.file.description}}" #tempDescriptionFile />
              </div>
          </div>

          <div class="d-flex action-icon-protocol spaced-input pt-1">
              <div *ngIf="file.value.allegatoObj != null && file.value.allegatoObj.daScansire else noScan"
                  class="container-icon mt-0" title="scansiona" (click)="scansionaFile(i)">
                  <i class="icon-doc-new"></i>
              </div>

              <ng-template #noScan>
                  <!--div *ngIf="(file.value.allegatoObj.applic == 'scansione') && (file.value.allegatoObj == null || file.value.allegatoObj.applic != 'cartaceo')" class="container-icon mt-0"
                      title="Preview - {{file.value.file.name}}" (click)="uploadFileToPreView(file, i)">
                      <i class="{{getFileClass(file.value.file.name)}}"></i>
                  </div-->
              </ng-template>

              <ng-container>
                  <div class="container-icon mt-0">
                      <i class="cursor-pointer icon-edit" [ngClass]="{'text-icon-disabled' : noEditOtherFile}" title="Modifica descrizione" (click)="!noEditOtherFile && file.edit = true; noEditOtherFile = true;" *ngIf="!file.edit;"></i>
                      <i class="cursor-pointer icon-ok" title="Conferma" (click)="editDescriptionFile(file, tempDescriptionFile.value, i) && file.edit = false;noEditOtherFile = false" *ngIf="file.edit;"></i>
                  </div>
                  <div class="container-icon mt-0">
                      <i class="cursor-pointer icon-trash" (click)="rimuoviFile(file, i)" title="Elimina"></i>
                  </div>
                  <div class="container-icon mt-0" [ngClass]="{'empty bg-white' : i == files.controls.length-1}">
                      <i *ngIf="i < files.controls.length-1" class="cursor-pointer icon-down" (click)="spostaGiu(i)" title="Sposta giù"></i>
                  </div>
                  <div class="container-icon mt-0" [ngClass]="{'empty bg-white' : i == 0}">
                      <i *ngIf="i > 0" class="cursor-pointer icon-up" (click)="spostaSu(i)" title="Sposta su"></i>
                  </div>
                  <div class="container-icon empty mt-0 bg-white"></div>
              </ng-container>


          </div>
      </div>
  </div>

</form>

<div class="d-flex justify-content-end w-100">
  <button (click)="salva()" type="button" class="btn bg-primary-archipro text-white text-nowrap mt-3">Salva</button>
</div>
