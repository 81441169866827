export class BreadcrumbElencoRiferimenti{

    scegliRiferimento : boolean = false;
   
    back() : void{
        this.scegliRiferimento = false;
    }

    setScegliRiferimento() : void{
        this.scegliRiferimento = true;
    }

    /**
     * Verifica che tutte siano false
     * 
     * @returns torna true se sono tutte false (quindi schermat di elenco anagrafche)
     */
    checkAllFalse() : boolean{
        return !(this.scegliRiferimento);
    }
}