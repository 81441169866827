<section class="d-flex flex-column w-100 h-100">
  <div *ngIf="showAlert" (click)="showAlert = false" class="alert alert-{{classAlert}} text-center cursor-pointer w-100 mb-4 mt-4" role="alert">
    <span>{{messageAlert}}</span>
  </div>

  <div class="mb-3 d-flex spaced-input">
    <div class="form-group mb-0 w-100">
      <input type="text" class="form-control" placeholder="Cerca" [(ngModel)]="docRequest.fullText">
    </div>
    <button title="Cerca" type="button" class="btn justify-content-center d-flex bg-primary-archipro text-white text-nowrap" (click)="currentPage = 1;searchDizionario()">
      <i class="icon-search"></i>Cerca
    </button>
    <button (click)="setShowOggetto(true)" *ngIf="!showNuovoOggetto;else annullaNuovoOggetto" title="Nuovo" type="button" class="btn justify-content-center d-flex bg-primary-archipro text-white text-nowrap">
      <div class="composed-icon-pedice mr-2">
        <i class="icon-book"></i>
        <i class="icon-plus pedice" style="font-size: 10px;"></i>
      </div>
      Nuovo
    </button>
    <ng-template #annullaNuovoOggetto>
      <button title="Annulla inserimento" type="button" class="btn justify-content-center d-flex bg-primary-archipro text-white text-nowrap" (click)="setShowOggetto(false)">
        <i class="icon-cancel"></i>Annulla
      </button>
    </ng-template>
  </div>

  <!--Nuovo-->
  <div class="my-2 d-flex spaced-input" *ngIf="showNuovoOggetto">
    <div class="form-group mb-0 w-100">
      <input type="text" class="form-control" placeholder="Nuovo oggetto" [(ngModel)]="oggetto">
    </div>


    <div class="action-icon-protocol d-flex align-items-center spaced-input">
      <div class="border container-icon mt-0" (click)="updateDizionario()">
        <i class="cursor-pointer icon-floppy" title="Salva"></i>
      </div>
    </div>



  </div>

  <div *ngIf="listaDizionario.length > 0;" class=" w-100 d-flex justify-content-end mt-2 mb-2">

    <diV class="mr-2 d-flex w-100 align-items-center justify-content-end">
        <span class="text-primary-archipro risultat-totali-class">{{elementDa}} - {{elementA}} di {{totRecords}} risultati</span>
    </diV>
    <div class="d-flex align-items-center justify-content-end"  *ngIf="numPage > 1" >
        <nav aria-label="Page navigation example">
            <ul class="pagination mb-0">
                <li class="page-item" [ngClass]="{'disabled' : currentPage == 1}">
                    <div class="page-link text-primary-archipro cursor-pointer" (click)="!searchInProgress && changePage(currentPage - 1)">
                        <span>Precedente</span>
                    </div>
                  </li>
                <li  *ngFor="let page of listObjPage" class="page-item">
                  <div class="page-link text-primary-archipro cursor-pointer" (click)="!searchInProgress && page.clickable && changePage(page.index)" [ngClass]="{'active_page text-white' : currentPage == page.index}">{{page.value}}</div>
                </li>
                <li class="page-item" [ngClass]="{'disabled' : currentPage == numPage}">
                    <div class="page-link text-primary-archipro cursor-pointer" (click)="!searchInProgress && changePage(currentPage + 1)">
                        <span>Prossimo</span>
                    </div>
                </li>
            </ul>
          </nav>
    </div>
</div>

  <div class="d-flex justify-content-center align-items-center h-100">
    <table *ngIf="listaDizionario.length > 0;else listaVuota" class="table table-striped border">
      <thead>
        <tr class="text-primary-archipro">
          <th scope="col" *ngIf="false">Codice</th>
          <th scope="col">Oggetto</th>
          <th scope="col">Azioni</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let element of listaDizionario; let i = index">
          <th *ngIf="false" scope="row" class="align-middle">
            {{element.codice}}
          </th>
          <td [title]="element.descrizione" class="align-middle">
            <span class="cut-text-2-lines">
              <input *ngIf="element.showEdit; else showElementInfo" type="text" class="form-control" placeholder="Nuovo oggetto" [(ngModel)]="element.descrizione">
              <ng-template #showElementInfo>{{element.descrizione}}</ng-template>
            </span>
          </td>
          <td class="action-icon align-middle">
            <div class="d-flex">
              <div class="border container-icon cursor-pointer"  data-dismiss="modal" (click)="riportaOggetto(element.descrizione)">
                <i class="icon-export horizontal-speculate text-primary-archipro" title="Riporta"></i>
              </div>

              <div *ngIf="!element.showEdit;else pulsantiModifica" class="border container-icon cursor-pointer" (click)="enableEdit(element)">
                <i class="icon-edit text-primary-archipro" title="Modifica"></i>
              </div>

              <ng-template #pulsantiModifica>
                <div class="border container-icon cursor-pointer" (click)="updateDizionario(element)">
                  <i class="icon-floppy text-primary-archipro" title="Salva"></i>
                </div>

                <div class="border container-icon cursor-pointer" (click)="ripristinaOggetto(element)">
                  <i class="icon-cancel text-primary-archipro" title="Annulla"></i>
                </div>
              </ng-template>

              <div *ngIf="!element.showEdit" class="border container-icon cursor-pointer" (click)="deleteOggetto(element)">
                <i class="icon-trash text-primary-archipro" title="Elimina"></i>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <ng-template #listaVuota>
      <div class="h-100 w-100 d-flex justify-content-center align-items-center" *ngIf="!searchInProgress; else loading">
        <h4>Non è stato trovato nessun risultato con i filtri selezionati</h4>
      </div>
    </ng-template>

    <ng-template #loading>
      <app-loading></app-loading>
  </ng-template>
  </div>
</section>
