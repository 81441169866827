import { Referente } from "./Referente";

export class ReferenteEnteDitta extends Referente{
    ragioneSociale : string;
    partitaIva : string;
    fax : string;

    constructor(obj : any){
        super(obj);
        this.ragioneSociale = obj.ragioneSociale;
        this.partitaIva = obj.partitaIva;
        this.fax = obj.fax;
    }
}