import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoadingComponent } from '../../sub-component/loading/loading.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DataStringTransformPipe } from './../../../pipe/dataStringTransform/data-string-transform.pipe';
import { InfoUtenteInlineComponent } from '../../sub-component/info-utente-inline/info-utente-inline.component';
import { ClickStopPropagationDirective } from './../../../directive/click-stop-propagation/click-stop-propagation.directive';
import { TreeViewComponent } from '../../sub-component/tree-view/tree-view.component';
import { ViewerComponent } from './../../sub-component/viewer/viewer.component';
import { ElencoMovimentiComponent } from '../elenchi/elenco-movimenti/elenco-movimenti.component';
import { ModalComponent } from '../../sub-component/modal/modal.component';
import { DinamicComponentDirective } from 'src/app/directive/DinamicComponentDirective/dinamic-component.directive';
import { ElencoAgdUoTreeviewComponent } from '../../sub-component/elenco-agd-uo-treeview/elenco-agd-uo-treeview.component';
import { ElencoAgdUoModalComponent } from '../../sub-component/elenco-agd-uo-modal/elenco-agd-uo-modal.component';
import { NavigazioneComponent } from './../ricerche/navigazione/navigazione.component';
import { AlberoTitolarioComponent } from '../ricerche/navigazione/pagenavigazione/albero-titolario/albero-titolario.component';
import { FascicoliComponent } from '../ricerche/navigazione/pagenavigazione/fascicoli/fascicoli.component';
import { DocumentiComponent } from '../ricerche/navigazione/pagenavigazione/documenti/documenti.component';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { AutocompleteComponent } from './../../sub-component/autocomplete/autocomplete.component';
import { ElencoRiferimentiComponent } from '../elenchi/elenco-riferimenti/elenco-riferimenti.component';
import { PdfJsViewerModule } from 'ng2-pdfjs-viewer';
import { DettaglioDocumentoViewerComponent } from '../../sub-component/viewer/dettaglio-documento-viewer/dettaglio-documento-viewer.component';
import { ViewerFormProtocollazioneComponent } from '../../sub-component/viewer-form-protocollazione/viewer-form-protocollazione.component';
import { FirmatarioComponent } from '../../sub-component/viewer/firmatario/firmatario.component';
import { DettaglioEmailComponent } from './../elenchi/email-in-arrivo/dettaglio-email/dettaglio-email.component';
import { InteroperabilitaComponent } from './../elenchi/interoperabilita/interoperabilita.component';
import { FormReferenteComponent } from '../gestione-utente/gestione-referente/form-referente/form-referente.component';
import { ElencoGruppiReferentiComponent } from '../elenchi/elenco-gruppi-referenti/elenco-gruppi-referenti.component';
import { ViewerInNavigazioneModalComponent } from '../ricerche/navigazione/pagenavigazione/viewer-in-navigazione-modal/viewer-in-navigazione-modal.component';
import { PermissionDirective } from 'src/app/directive/permissionDirective/permission.directive';
import { PraticheComponent } from '../elenchi/elenco-riferimenti/pratiche/pratiche.component';
import { ProtocolliComponent } from '../elenchi/elenco-riferimenti/protocolli/protocolli.component';
import { EntiComponent } from '../elenchi/elenco-riferimenti/enti/enti.component';
import { ElementPdfViewerComponent } from '../../sub-component/element-pdf-viewer/element-pdf-viewer.component';
import { EliminaProtocolloComponent } from '../gestione-documenti/elimina-protocollo/elimina-protocollo.component';
import { FormFascicoloComponent } from '../gestione-fascicoli/form-fascicolo/form-fascicolo.component';
import { AlberoCollocazioneComponent } from '../ricerche/albero-collocazione/albero-collocazione.component';
import { TrasmettiFascicoloComponent } from '../gestione-fascicoli/trasmetti-fascicolo/trasmetti-fascicolo.component';
import { NavigateService } from 'src/app/service/NavigateService/navigate.service';
import { RouteReuseStrategy } from '@angular/router';
import { ClassificazioneFascicolazioneComponent } from '../../sub-component/forms/classificazione-fascicolazione/classificazione-fascicolazione.component';
import { CompetenzaConoscenzaComponent } from '../../sub-component/forms/competenza-conoscenza/competenza-conoscenza.component';
import { CollocazioneFisicaComponent } from '../../sub-component/forms/collocazione-fisica/collocazione-fisica.component';
import { ApprovaDocumentoComponent } from '../../sub-component/approva-documento/approva-documento.component';
import { CambioPasswordComponent } from '../../sub-component/cambio-password/cambio-password.component';
import { DizionarioOggettoComponent } from '../../sub-component/dizionario-oggetto/dizionario-oggetto.component';

@NgModule({
  declarations: [
    LoadingComponent,
    DataStringTransformPipe,
    InfoUtenteInlineComponent,
    ClickStopPropagationDirective,
    DinamicComponentDirective,
    PermissionDirective,
    TreeViewComponent,
    ViewerComponent,
    ElencoMovimentiComponent,
    ModalComponent,
    ElencoAgdUoTreeviewComponent,
    ElencoAgdUoModalComponent,
    ElencoRiferimentiComponent,
    AutocompleteComponent,
    EliminaProtocolloComponent,
    NavigazioneComponent, AlberoTitolarioComponent, FascicoliComponent, DocumentiComponent,ViewerInNavigazioneModalComponent,AlberoCollocazioneComponent,

    DettaglioDocumentoViewerComponent,
    ViewerFormProtocollazioneComponent,
    FirmatarioComponent,
    DettaglioEmailComponent,
    InteroperabilitaComponent,
    FormReferenteComponent,
    ElencoGruppiReferentiComponent,
    PraticheComponent,
    ProtocolliComponent,
    EntiComponent,
    ElementPdfViewerComponent,
    FormFascicoloComponent,
    TrasmettiFascicoloComponent,
    ClassificazioneFascicolazioneComponent,
    CompetenzaConoscenzaComponent,
    CollocazioneFisicaComponent,
    ApprovaDocumentoComponent,
    CambioPasswordComponent,
    DizionarioOggettoComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    NgbModule,
    ReactiveFormsModule,
    PdfJsViewerModule // Viewer dei pdf
  ],
  exports: [
    CommonModule,FormsModule,NgbModule,ReactiveFormsModule,PdfJsViewerModule,
    NavigazioneComponent, AlberoTitolarioComponent, FascicoliComponent, DocumentiComponent,
    ClickStopPropagationDirective,DinamicComponentDirective,PermissionDirective,
    DataStringTransformPipe,
    LoadingComponent,
    InfoUtenteInlineComponent,
    TreeViewComponent,
    ViewerComponent,
    ElencoMovimentiComponent,
    ModalComponent,
    ElencoAgdUoTreeviewComponent,
    ElencoAgdUoModalComponent,
    ElencoRiferimentiComponent,
    //DA cancellare se quello nostro funziona
    AutocompleteLibModule,
    AutocompleteComponent,
    DettaglioDocumentoViewerComponent,
    ViewerFormProtocollazioneComponent,
    FirmatarioComponent,
    DettaglioEmailComponent,
    InteroperabilitaComponent,
    FormReferenteComponent,
    ElencoGruppiReferentiComponent,
    PraticheComponent,
    ProtocolliComponent,
    ElementPdfViewerComponent,
    EliminaProtocolloComponent,
    EntiComponent,
    FormFascicoloComponent,
    AlberoCollocazioneComponent,
    TrasmettiFascicoloComponent,
    ClassificazioneFascicolazioneComponent,
    CompetenzaConoscenzaComponent,
    CollocazioneFisicaComponent,
    ApprovaDocumentoComponent,
    CambioPasswordComponent,
    DizionarioOggettoComponent,
    ViewerInNavigazioneModalComponent

  ],
  providers:[,
    {provide: RouteReuseStrategy, useClass: NavigateService}
  ]
})
export class SharedModule { }
