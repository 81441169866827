import { StaticProperty } from "src/app/Utility/StaticProperty";
import { Utility } from "src/app/Utility/Utility";
import { GlobalObj } from "../globalService/GlobalObj";

export class EtichettaObj{
    numProt : number;
    annoProt : number;

    numProtUff : number;
    annoProtUff : number;

    //NB: ricordarsi di settarla nell'oggetto già normalizzata;
    dataProt : string;
    siglaUff : string;

    numPrat : string;
    flusso : string;

    //Titolario
    titolo1 : number;
    titolo2 : number;
    titolo3 : number;
    titolo4 : number;
    titolo5 : number;
    titolo6 : number;

    //Collocazione
    collocazione1 : number;
    collocazione2 : number;
    collocazione3 : number;
    collocazione4 : number;
    collocazione5 : number;
    collocazione6 : number;

    numFascicolo : number;
    annoFascicolo : number;

    inCaricoA : string;

    progr : number;

    GLOBALI : GlobalObj;

    constructor()
    {
        this.GLOBALI = Utility.getGlobali();
    }

    /**
     * Metodo utilizzato per inizializzare le voci del titolario dalla stringa intera
     * @param titolario
     */
    public setTitolarioFromString(titolario : string) : void{
        if(!!titolario && titolario.split(".")[0] != "0")
        {
            let temp = titolario.split(".");
            for(let i = 0; i < 6; i++)
            {
                this["titolo"+(i+1)] = temp[i];
            }
        }
        else
        {
            this.titolo1 = null;
        }

    }

    /**
     * Funzione che restituisce il titolario o la collocazione a seconda del tipo passato
     * @param type  :   Può avere valore titolo o collocazione
     * @returns
     */
    private buildTitolarioOrCollocazione(type : string) : string {
        let titolario : string = "";
        for(let i = 1; i <= 6; i++)
        {
            titolario += this[type+i] + (i < 6 ? "." : "");

        }
        return titolario;
    }


    /**
     * Restituisce se esiste il fascicolo da inserire nell'etichetta
     * ps: all'inizio mette lo /
     * @returns
     */
    private buildFascicolazioneString() : string{
        let fascicoloString = "";
        if(!!this.numFascicolo && !!this.annoFascicolo)
        {
            fascicoloString = "/" + this.numFascicolo + "/" + this.annoFascicolo;
        }
        return fascicoloString;
    }

    /**
     * Funzione che a seconda della globale restituisce la stringa con il formato dell'etichetta specializzato
     * @returns
     */
    public getEtichetta() : string{
        let etichetta : string;

        switch(this.GLOBALI.PRO_CODICE_ENTE){
            case StaticProperty.CODICE_ENTE_CEI:
                etichetta = this.getEtichettaCei();
                break;
            case StaticProperty.CODICE_ENTE_PADOVA:
                etichetta = this.getEtichettaPadova();
                break;
            case StaticProperty.CODICE_ENTE_IZS:
                etichetta = this.getEtichettaIzs();
                break;
            default:
              etichetta = this.getEtichettaGenerica();
              break;

        }

        return etichetta;
    }

    /**
     * Funzione che costruisce e ritorna il formato dell'etichetta per la cei
     * @returns
     */
    private getEtichettaCei() : string{
        let label : string =
        this.GLOBALI.PRO_PROVINCIA + "\n" +
        "</String>"+
					"<Attributes>"+
					"	<Font Family=\"Arial\" Size=\"12\" Bold=\"False\" Italic=\"False\" Underline=\"False\" Strikeout=\"False\" />"+
					"	<ForeColor Alpha=\"255\" Red=\"0\" Green=\"0\" Blue=\"0\" HueScale=\"100\" />"+
					"</Attributes>"+
				"</Element>"+
				"<Element>"+
				"	<String xml:space=\"preserve\">Protocollo: " + this.numProtUff + "/" + this.annoProtUff + (this.siglaUff ? ("/" + this.siglaUff) : "") + "\n</String>"+/*manca la sigla ufficio*/
				"	<Attributes>"+
				"		<Font Family=\"Arial\" Size=\"12\" Bold=\"True\" Italic=\"False\" Underline=\"False\" Strikeout=\"False\" />"+
				"		<ForeColor Alpha=\"255\" Red=\"0\" Green=\"0\" Blue=\"0\" HueScale=\"100\" />"+
				"	</Attributes>"+
				"</Element>"+
                "<Element>"+
					"<String xml:space=\"preserve\">"+
                        "Data: " + this.dataProt + "\n" +
                        (this.titolo1 != null ? ("Classificazione: " + this.buildTitolarioOrCollocazione("titolo") + this.buildFascicolazioneString() + "\n") : ("\n"));
                        //"Collocazione: " + this.buildTitolarioOrCollocazione("collocazione");



        return label;
    }


    /**
     * Funzione che costruisce e ritorna il formato dell'etichetta per la cei
     * @returns
     */
    private getEtichettaGenerica() : string{
      let stringaProtocollo = this.numProtUff ?
      (this.numProtUff + "/" + this.annoProtUff + (this.siglaUff ? ("/" + this.siglaUff) : ""))
      :
      (this.numProt + "/" + this.annoProt)

      let label : string =
      this.GLOBALI.PRO_PROVINCIA + "\n" +
      "</String>"+
        "<Attributes>"+
        "	<Font Family=\"Arial\" Size=\"12\" Bold=\"False\" Italic=\"False\" Underline=\"False\" Strikeout=\"False\" />"+
        "	<ForeColor Alpha=\"255\" Red=\"0\" Green=\"0\" Blue=\"0\" HueScale=\"100\" />"+
        "</Attributes>"+
      "</Element>"+
      "<Element>"+
      "	<String xml:space=\"preserve\">Protocollo: " + stringaProtocollo + "\n</String>"+/*manca la sigla ufficio*/
      "	<Attributes>"+
      "		<Font Family=\"Arial\" Size=\"12\" Bold=\"True\" Italic=\"False\" Underline=\"False\" Strikeout=\"False\" />"+
      "		<ForeColor Alpha=\"255\" Red=\"0\" Green=\"0\" Blue=\"0\" HueScale=\"100\" />"+
      "	</Attributes>"+
      "</Element>"+
              "<Element>"+
        "<String xml:space=\"preserve\">"+
                      "Data: " + this.dataProt + "\n" +
                      (this.titolo1 != null ? ("Classificazione: " + this.buildTitolarioOrCollocazione("titolo") + this.buildFascicolazioneString() + "\n") : ("\n"));
                      //"Collocazione: " + this.buildTitolarioOrCollocazione("collocazione");



      return label;
  }

    /**
     * Funzione che costruisce e ritorna il formato dell'etichetta per la cei
     * @returns
     */
     private exgetEtichettaCei() : string{
        let label : string =
        this.GLOBALI.PRO_PROVINCIA + "\n</String>"+

        "<Attributes>"+
        " <Font Family=\"Arial\" Size=\"12\" Bold=\"False\" Italic=\"False\" Underline=\"False\" Strikeout=\"False\" />"+
        " <ForeColor Alpha=\"255\" Red=\"0\" Green=\"0\" Blue=\"0\" HueScale=\"100\" />"+
        "</Attributes>"+
        "</Element>"+

        "<Element>"+
        " <String xml:space=\"preserve\">Protocollo: " + this.numProtUff + "/" + this.annoProtUff + (this.siglaUff ? ("/" + this.siglaUff) : "") + "\n</String>"+
        " <Attributes>"+
        "   <Font Family=\"Arial\" Size=\"12\" Bold=\"True\" Italic=\"False\" Underline=\"False\" Strikeout=\"False\" />"+
        "   <ForeColor Alpha=\"255\" Red=\"0\" Green=\"0\" Blue=\"0\" HueScale=\"100\" />"+
        " </Attributes>"+
        "</Element>"+

        "<Element>"+
        " <String xml:space=\"preserve\">Data: " + this.dataProt + "\n</String>"+
        " <Attributes>"+
        "   <Font Family=\"Arial\" Size=\"12\" Bold=\"False\" Italic=\"False\" Underline=\"False\" Strikeout=\"False\" />"+
        "   <ForeColor Alpha=\"255\" Red=\"0\" Green=\"0\" Blue=\"0\" HueScale=\"100\" />"+
        " </Attributes>"+
        "</Element>"+


        "<Element>"+
        "<String xml:space=\"preserve\">"+(this.titolo1 != null ? ("Classificazione: " + this.buildTitolarioOrCollocazione("titolo") + this.buildFascicolazioneString() + "") : (""));
        //"Collocazione: " + this.buildTitolarioOrCollocazione("collocazione");

        return label;
    }

    /**
     * Funzione che costruisce e ritorna il formato dell'etichetta per Padova
     * @returns
     */
    private getEtichettaPadova() : string{
        let label : string =
        this.GLOBALI.PRO_PROVINCIA + "\n" +
        "N° Prat. " + this.numPrat + "\n" +
        this.flusso + " " +this.numProt + "/" + this.annoProt + " del " + this.dataProt;
        return label;
    }

    /**
     * Funzione che costruisce e ritorna il formato dell'etichetta per IZS
     * @returns
     */
    private getEtichettaIzs() : string{
        let label : string =
        this.GLOBALI.PRO_PROVINCIA + "\n" +
        "Prot. n. " + this.numProt + " del " + this.dataProt + "\n" +
        this.buildTitolarioOrCollocazione("titolo") + "/" + this.numFascicolo + "/" + this.annoFascicolo + " - in carico a " + this.inCaricoA;
        return label;
    }



}
