import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { RequestService } from 'src/app/service/requestService/request.service';
import { CustomValidators } from 'src/app/Utility/CustomValidators';
import { StaticProperty } from 'src/app/Utility/StaticProperty';

@Component({
  selector: 'app-cambio-password',
  templateUrl: './cambio-password.component.html',
  styleUrls: ['./cambio-password.component.scss']
})
export class CambioPasswordComponent implements OnInit {

  formPass : FormGroup;

  oldPassword : FormControl = new FormControl("", Validators.required);
  newPassword : FormControl = new FormControl("", [Validators.required, Validators.pattern(StaticProperty.patternStrongPassword)]);
  confirmPassword : FormControl = new FormControl("", Validators.required);

  showAlert : boolean = false;
  classAlert : string = "danger";
  messageAlert : string = "";

  showFooter : boolean = true;

  constructor(private formBuilder : FormBuilder, private requestService : RequestService) { }

  ngOnInit(): void {

    this.formPass = this.formBuilder.group({
      oldPassword : this.oldPassword,
      newPassword : this.newPassword,
      confirmPassword : this.confirmPassword
    },
    {
      validator: [
        Validators.required ,
        CustomValidators.ConfirmedValidator("newPassword", "confirmPassword"),
        CustomValidators.notEqualOldAndNewPassword("oldPassword", "newPassword")
      ]
    });
  }

  submit(){
    this.formPass.markAllAsTouched();
    if(!this.formPass.invalid)
    {
      this.requestService.postRequest("cambiaPasswordUtente", this.formPass.getRawValue(),
        (success) => {
          this.formPass.disable();
          this.messageAlert = "Password modificata con successo";
          this.classAlert = "success";
          this.showAlert = true;
          this.showFooter = false;
        },
        (error) => {
          if(error.message == "pwdErr"){
            this.messageAlert = "Vecchia password errata"
          }
          else
          {
            this.messageAlert = "Errore nella richiesta"
          }
          this.classAlert = "danger";
          this.showAlert = true;
        }
      )
    }
  }
}
