<div id="info-utente-inline-class" class="w-100 d-flex justify-content-end">
    <small>
        <b>Utente: </b> {{user.nome}} {{user.cognome}}
    </small>
    <small>
        <b>Matricola: </b> {{user.matricola}}
    </small>
    <small>
        <b>
            Agd: </b> {{user.codAD}}
    </small>
    <small>
        <b>Uo: </b> {{user.codUO}}
    </small>
</div>