import { Component, Input, OnInit } from '@angular/core';
import { Referente } from 'src/app/model/Referente';
import { RequestService } from 'src/app/service/requestService/request.service';
import { SCREEN_SIZE } from 'src/app/service/resize/screen-size.enum';
import { Utility } from 'src/app/Utility/Utility';

@Component({
  selector: 'app-enti',
  templateUrl: './enti.component.html',
  styleUrls: ['./enti.component.scss']
})
export class EntiComponent implements OnInit {

  readonly mapCodTipoReferente : any = {
    1 : "P", //Persona
    2 : "E", //Ente - Ditta
    3 : "D", //Dipendente
    4 : "UO",//Unità organizzativa
    5 : "PA" //Pubblica amministrazione
  } 

  readonly tipoRiferimento : string = "E";

  searchInProgress : boolean = false;

  //Variabili utilizzate per la paginazione
  //************************************* */
  totRecords : number;  
  numPage : number = 0;
  currentPage : number = 1;
  numRow : number = 10;
  listObjPage : any = [];
  elementA : number;
  elementDa : number;
  //************************************* */

  //Lista generale passato dal component container
  @Input() listaRiferimentiSelezionata : any;

  //Oggetto che indica la misura del container
  @Input() size : SCREEN_SIZE;

  //Funzione per rimuovere dalla lista generale il riferimento
  @Input() rimuoviRiferimento : (tipo : string, riferimento : any) => void;

  //Oggetto utilizzato per il filtro richiesta
  docRequest : any = {
    descrizione : "",
    numRow: 10,
    order: 1,
    referenteTipo: 2

  }

  //Lista dei riferimenti di un singolo tipo
  listRiferimenti : any = [];

  constructor(private requestService : RequestService) { }

  ngOnInit(): void {
  }

  /**
   * Questo Metodo viene utilizzata per calcolare il numero di pagine che la lista ha calcolata
   * in base al numero di record della lista e al opzione di numero di righe selezionata dall'utente
   */
  buildPaginationObject()
  {
    this.numPage = Utility.calculateNumberOfPages(this.totRecords, this.numRow);
    this.listObjPage = Utility.buildPaginationObject(this.numPage, this.currentPage);
    this.elementA = (this.currentPage * this.numRow) - (this.numRow - this.listRiferimenti.length);
    this.elementDa = (this.currentPage * this.numRow) - (this.numRow - 1);
  }

  /**
   * Metodo utilizzato dalla paginazione per richiedere al servere la lista
   * 
   * @param page : numero della pagina cliccata
   */
  changePage(page : number)
  {
    this.currentPage = page;
    this.getList();
  }

  getList() : void {
    this.docRequest.currentPage = this.currentPage;
    
    this.searchInProgress = true;
    this.requestService.postRequest("searchReferenti", this.docRequest,
      
      (success) => {
        this.listRiferimenti = []
        let listTemp = success.obj.list; 


        listTemp.forEach(element => {
          element.referenteCodice = element.codice;
          element.referenteTipo = element.tipo.codTipoReferente;
          element.inviaSemprePerMail = element.tipo1NotificaMail == 1;
          element.bloccaReferente = element.tipo2Bloccato == 1;
          element.descrizione = element.tipo2Description;

          
          this.listRiferimenti.push(element);
        });

        /**
         * Questa parte viene fatta per fare in modo che quelle 
         * precedentemente selezionate compaiano con il check nelle varie liste
         * (in questo caso quella dei protocolli)
         */
        
        let listToCheck = this.listaRiferimentiSelezionata.filter(element => element.tipoRiferimento == this.tipoRiferimento);
        listToCheck.forEach(element => {
          this.listRiferimenti.forEach(riferimento => {
            if(element.codRef == riferimento.codice)
            {
              riferimento.active = true;
              riferimento.tipoRiferimento = this.tipoRiferimento;
            }
          })
        })

        this.totRecords = success.obj.totRecords;

        this.buildPaginationObject();
        this.searchInProgress = false;
        
      },
      () => {
        this.searchInProgress = false;
      }
    )
  }

  addToGeneralList(referente: any){
   
    if(!referente.active)
    {
      referente.active = true;
      referente.tipoRiferimento = this.tipoRiferimento;
      referente.codRef = referente.referenteCodice;
      referente.descrizioneView = new Referente(referente).getDescrizioneForView();
      this.listaRiferimentiSelezionata.push(referente);

    }
    else
    {
      referente.active = false;
      this.rimuoviRiferimento(this.tipoRiferimento, referente);
    }
    
  }

}
