import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from './../../../environments/environment';
import { Utility } from 'src/app/Utility/Utility';
import { StaticProperty } from 'src/app/Utility/StaticProperty';

@Injectable({
  providedIn: 'root'
})
export class RequestService {

  private headers: any;
  private static options: any = {};
  payload: any = {};
  url = environment.url;

  constructor(private http : HttpClient) {

      this.headers = new HttpHeaders({
        'Content-Type': 'application/json; charset=utf-8',
        responseType: 'arraybuffer',
        observe: 'response' as 'response'

      });
   }

  getRequestClient(additionalUrl: any, params: any): Observable<any> {

    this.payload = {};
    this.payload.data = !params ? {} : params;
    //var utentesessione = JSON.parse(Utility.localStorageGet(StaticProperty.UTENTE_SESSIONE));

      RequestService.options = {
        data: params,
        headers: this.headers,
        withCredentials : true
      };


      return this.http.get(this.url + additionalUrl, RequestService.options);
  }

  postRequestClient(additionalUrl: any, params: any): Observable<any> {

    this.payload = !params ? {} : params;

      RequestService.options = {
        data: params,
        headers: this.headers,
        whitCredential : "true"
      };
      return this.http.post(this.url + additionalUrl, params, RequestService.options);
  }


  gestioneRichiesta(request : Observable<any>, successRequest : (value: any) => void, errorRequest? : (value?: any) => void)
  {
    request.subscribe(
      success => {
        if(success != null)
        {
          if(success.status){
            successRequest(success);
          }
          else if (success.message == "MS_1000"){
            alert(Utility.getMessageByCode(success.message));
            this.logout();
          }
          else
          {
            errorRequest ? errorRequest(success) : alert(Utility.getMessageByCode(success.message));
          }
        }
      },
      error => {
      }
    );
  }




  getRequest(additionalUrl: any, params: any,  successRequest : (value: any) => void, errorRequest? : (value?: any) => void, fullUrl? : boolean ): void {
    if(Utility.checkUtenteSessione())
    {
      this.payload = !params ? {} : params;



      RequestService.options = {
        params: params,
        withCredentials : true,
        responseType: "text"
      };

      let request : Observable<any> = this.http.get(!fullUrl ? (this.url + additionalUrl) : additionalUrl, RequestService.options);
      this.gestioneRichiesta(request, successRequest, errorRequest);

    }
    else
    {
      this.logout();
    }
  }


  postRequest(additionalUrl: any, params: any,  successRequest : (value: any) => void, errorRequest? : (value?: any) => void, fullUrl? : boolean ): void {

    if(Utility.checkUtenteSessione())
    {
      this.payload = !params ? {} : params;

      RequestService.options = {
        data: params,
        headers: this.headers,
        withCredentials : true
      };

      let request : Observable<any> = this.http.post(!fullUrl ? (this.url + additionalUrl) : additionalUrl, params, RequestService.options);
      this.gestioneRichiesta(request, successRequest, errorRequest);
    }
    else
    {
      this.logout();
    }
  }


  async postRequestAsync(additionalUrl: any, params: any, successRequest : (value: any) => void, errorRequest? : (value?: any) => void ) : Promise<any> {

    if(Utility.checkUtenteSessione())
    {
      this.payload = !params ? {} : params;

      RequestService.options = {
        data: params,
        headers: this.headers,
        whitCredential : "true"
      };

      let objResponse : any;

      await this.http.post(this.url + additionalUrl, params, RequestService.options).toPromise().then(
        response => {
          if(response != null)
          {
            objResponse = response;
          }
        }
      );

      if(objResponse.status){
        successRequest(objResponse);
      }
      else if (objResponse.message == "MS_1000"){
        alert(Utility.getMessageByCode(objResponse.message));
        this.logout();
      }
      else
      {
        errorRequest ? errorRequest() : null;
        alert(Utility.getMessageByCode(objResponse.message));
      }

    }
    else
    {

    }
  }



  postMultipartRequestClient(additionalUrl: any, formdata: any, successRequest : (value: any) => void, errorRequest? : (value?: any) => void ): void {

    if(Utility.checkUtenteSessione())
    {

      this.payload = !formdata ? {} : formdata;

      let headers = new HttpHeaders({
        //'Content-Type': 'multipart/form-data',
        responseType: 'arraybuffer',
        observe: 'response' as 'response'

      });

      let options = {
        data: formdata,
        headers: headers,
        whitCredential : "true"
      };

      let request : Observable<any> = this.http.post(this.url + additionalUrl, formdata, options);
      this.gestioneRichiesta(request, successRequest, errorRequest);

    }
    else
    {
      this.logout();
    }

  }

  async postMultipartRequestClientAsync(additionalUrl: any, formdata: any, successRequest : (value: any) => void, errorRequest? : (value?: any) => void ):  Promise<any> {

    if(Utility.checkUtenteSessione())
    {

      this.payload = !formdata ? {} : formdata;

      let headers = new HttpHeaders({
        //'Content-Type': 'multipart/form-data',
        responseType: 'arraybuffer',
        observe: 'response' as 'response'

      });

      let options = {
        data: formdata,
        headers: headers,
        whitCredential : "true"
      };

      let request : Observable<any> = this.http.post(this.url + additionalUrl, formdata, options);
      this.gestioneRichiesta(request, successRequest, errorRequest);

    }
    else
    {
      this.logout();
    }

  }

  jsonpCallback(data){

    alert(data.message);
  }

  postPopClientRequest(additionalUrl: any, params: any,  successRequest : (value: any) => void, errorRequest? : (value?: any) => void ): void {

    if(Utility.checkUtenteSessione())
    {
      this.payload = !params ? {} : params;

      RequestService.options = {
        data: params,
        headers: new HttpHeaders({
            'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8',
            responseType: 'text'
            //observe: 'response' as 'response'

          }),
        whitCredential : "true"
      };

      let request : Observable<any> = this.http.post(additionalUrl, params, RequestService.options);

      request.subscribe(
        success => {

          if(success != null)
          {
            if(success.status){
              successRequest(success);
            }
            else if (success.message == "MS_1000"){
              alert(Utility.getMessageByCode(success.message));
              this.logout();
            }
            else
            {
              errorRequest ? errorRequest() : null;
              alert(Utility.getMessageByCode(success.message));
            }
          }
        },
        error => {
         this.logout();
        }
      );
    }
    else
    {
      this.logout();
    }
  }

  logout(){
    this.postRequestClient("doLogout", "")
      .subscribe(
        response => {
          if(response.status)
          {

          }
        },
        error => {

        });
        Utility.localStorageDelete(StaticProperty.UTENTE_SESSIONE);
        Utility.localStorageDelete(StaticProperty.GLOBALI);
        window.location.href = "/ArchiPRO/";
  }
}



