export class MailDto{

    id: number;
    dataInvio30 : string;
    mittente : string;
    destinatario : string;
    oggetto: string;
    listaAllegati : Allegato[] = [];
    bodyMail : any;


    /**
     * Metodo che mappa la lista degli 
     * 
     * @param lista 
     */
    setListaAllegatiByListaAllegatiWeb(lista : any[]) : void{
        this.listaAllegati = [];
        lista.map(element => {
            let temp : Allegato = new Allegato();
            temp.nome = element.nomeFile;
            temp.nomeOriginale = element.nomeOriginaleFile;
            temp.url = element.webFile;
            temp.formato = this.getFormatoByName(element.nomeFile);
            this.listaAllegati.push(temp);
        });
    }

      /**
     * Da rivedere
     * Questa funzione prende il formato del file dal nome cosi da farlo vedere negli allegati
     * 
     * @param nomeFile  : nome del file
     * @returns         : formato del file da visualizzare
     */
    getFormatoByName(nomeFile : string) : string{
        return nomeFile ? nomeFile.split(".")[nomeFile.split(".").length-1] : "";
    }
}


class Allegato{
    active: boolean = false;
    nome : string;
    nomeOriginale : string;
    formato : string;
    url : string;
}