import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-element-pdf-viewer',
  templateUrl: './element-pdf-viewer.component.html',
  styleUrls: ['./element-pdf-viewer.component.scss']
})
export class ElementPdfViewerComponent implements OnInit {

  @Input() url : string;

  @Input() page : number = 0;

  @Input() objParameterComponent : any;

  constructor() { }

  ngOnInit(): void {
    if(this.objParameterComponent != null)
    {
      this.url = this.objParameterComponent.url;
      this.page = !!this.objParameterComponent.page ? this.objParameterComponent.page : 0;
    }
  }

}
