<section class="d-flex flex-column h-100 scrivania-container" *ngIf="showApp"><!--full-screen-application-height-->
    <app-nav-bar></app-nav-bar>
    <div class="d-flex h-100 under-navbar position-relative">

        <app-sidebar class="" *ngIf="getShowSidebar()" [getAbilitato]="getAbilitato" [menu]="menu" [ngClass]="{'position-absolute z-index-1020 bg-white' : size <= 1}"></app-sidebar><!--animate__animated animate__fadeInLeft-->
        <div class="body-module" [ngClass]="{'calc-with-sidebar' : size > 1 , 'w-100' : size <= 1 || !getShowSidebar()}">

       <!-- <app-sidebar class="sidebar-position-default" [ngClass]="{'close-sidebar' : !getShowSidebar()} "></app-sidebar>
        <div class="body-module body-default" [ngClass]="{'calc-with-sidebar' : size > 1 , 'full-body-animation' : !getShowSidebar()}">-->
            <router-outlet></router-outlet>
        </div>
    </div>
    
</section> 