
<ng-container *ngIf="!!statusMessage; else formTemplate">
    <h5 class="text-center">{{statusMessage}}</h5>
</ng-container>

<ng-template #formTemplate>
    <form *ngIf="!loading; else loadingState" class="d-flex flex-column" [formGroup]="annullamentoForm" (ngSubmit)="annullaProtocollo()">

        <div class="form-group w-100" [ngClass]="{'text-danger' : checkControlName('motivoAnnullamento')}">
            <label>Motivo*</label>
            <textarea class="form-control" placeholder="" formControlName="motivoAnnullamento" id="motivoAnnullamento" rows="6" [ngClass]="{'border-danger' : checkControlName('motivoAnnullamento')}"></textarea>
            <small *ngIf="checkControlName('motivoAnnullamento')" id="motivoRequired" class="form-text">Motivo obbligatorio</small>
        </div>
    
        <button type="submit" class="ml-auto btn bg-primary-archipro text-white mt-3">Conferma</button>
    </form>
    
    <ng-template #loadingState>
        <app-loading></app-loading>
    </ng-template>
</ng-template>


