import { Flusso } from "./Flusso";

export class DettaglioFlussi{
    intestazione : Intestazione = new Intestazione();
    list : Flusso[] = [];

    setList(list : any) : void{
        this.list = [];

        list.map(element => {
            let temp : Flusso = new Flusso();
            temp.dataOperazione = element.dataOperazione.split("&nbsp;&nbsp;")[0];
            temp.oraOperazione = element.dataOperazione.split("&nbsp;&nbsp;")[1];
            temp.flussoOperazione = element.flussoOperazione;
            temp.idmail = element.idmail;
            temp.oggetto = element.oggetto;
            temp.progr = element.progr;
            temp.tipoMessaggio = element.tipoMessaggio;
            temp.tipoOperazioneDescr = element.tipoOperazioneDescr;
            temp.trasmessa = element.trasmessa;

            this.list.push(temp);
        });
    }
}

class Intestazione{
    DATA : string;
    DESTINATARIO : string;
    MITTENTE : string;
    NUMERO : string;
}

