import { Component, Input, OnInit, ViewChild, ElementRef } from '@angular/core';


@Component({
  selector: 'app-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.scss']
})
export class AutocompleteComponent implements OnInit {


  @ViewChild("searchInput")
  searchInput : ElementRef;

  //Label dell'input
  @Input() labelInput : string = "";

  //Testo che deve essere visualizzato se la lista è vuota
  @Input() textEmptyList : string = "Nessun elemento trovato";
  
  //Lista degli elementi da visualizzare
  @Input() list : any = [];

  //Campo dell'oggetto della lista dove cercare
  @Input() keyword : string = ""; 

  //Testo che deve comparire in caso di errore di validazione
  @Input() textError : string = "";

  //Variabile che attiva gli effetti d'errore nell'input
  @Input() conditionError : boolean  = false;

  //Valore dove far partire la ricerca (default 3)
  @Input() lengthStartSearch : number  = 3;

  //Funzione che ti recupera i dati in remoto
  @Input() callData : (text : string) => void;

  @Input() nameForFormControl : string = "";

  @Input() externalFunction : () => void;

  @Input() disabled : boolean = false;


  listToView : any = [];

  objSelected : any = {};



  //Campi per il funzionamento del component
  showList : boolean = false; 

  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges() : void {
      if(this.callData != undefined)
      {
        this.listToView = this.list;
      }
  }   


  showHiddenList() : void{
    setTimeout(()=>{this.showList = !this.showList;}, 240);
  }

  filterList() : void{

    if(this.callData == undefined)
    {
      if(this.checkLengthValue())
      {
        this.listToView = this.list.filter((element) => {
          return element[this.keyword].toLowerCase().includes(this.searchInput.nativeElement.value.toLowerCase());
        })
      }
      else
      {
        this.listToView = this.list;
      }
    }
    else
    {
      if(this.checkLengthValue())
      {
        this.callData(this.searchInput.nativeElement.value);
        //this.listToView = this.list;
      }
    } 
  }

  checkLengthValue() : boolean
  {
    return this.searchInput.nativeElement.value.length >= this.lengthStartSearch;
  }


  selectItem(element : any) : void
  {
    this.searchInput.nativeElement.value = element[this.keyword];
    this.objSelected = element;
    if(this.externalFunction) this.externalFunction();
  }

  reset() : void{
    this.searchInput.nativeElement.value = "";
    this.objSelected = {};
    this.listToView = [];
    this.list = [];
  }

  public get value() : string{
    return this.searchInput.nativeElement.value;
  }

  public get ObjSelected() : any{
    return this.objSelected;
  }
}
