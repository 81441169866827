import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { ScrivaniaComponent } from 'src/app/component/moduli/scrivania-module/scrivania.component';

@Directive({
  selector: '[appPermission]'
})
export class PermissionDirective {

  //private hasView = false;

  constructor(private templateRef: TemplateRef<any>, private viewContainer: ViewContainerRef) { }
  
  @Input()set appPermission(functionName: string) {
    
    setTimeout(() => {
      let perm = ScrivaniaComponent.getAbilitatoByNameFunction(functionName);
      if (perm) {
        this.viewContainer.createEmbeddedView(this.templateRef);      
      } else {
        this.viewContainer.clear();
      }
    }, 10);
    
    
  }
}
