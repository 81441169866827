<section class="d-flex flex-column w-100 p-3 bg-form-color form-protocollazione-container">
    <section id="header-page">
        <div class="d-flex text-primary-archipro justify-content-between align-items-center border-bottom pb-2">
            <h4 class="">{{updateMode ? "Modifica" : "Aggiungi"}} anagrafica</h4>
            <div class="form-group mb-2 w-100 max-width-135">
                <!--<label for="intervallo" class="text-primary-archipro">N° righe</label>-->
                <select class="form-control" [(ngModel)]="referenteTipo" (ngModelChange)="initForm()">
                    <option class="text-primary-archipro placeholder-select" disabled>Tipo anagrafica</option>
                    <option [ngValue]="'P'">PERSONA</option>
                    <option [ngValue]="'E'">ENTE/DITTA</option>
                </select>
            </div>
        </div>    
        <section id="body-page" class="d-flex flex-column">
            <div class="w-100 d-flex justify-content-end pt-1 spaced-input">
                <div class="d-flex align-items-center expand-action">
                    <div (click)="openAllSections(true)" class="cursor-pointer">
                        <i class="cursor-pointer icon-angle-double-down" title="CHIUDI TUTTI"></i>    
                    </div>    
                    <div (click)="openAllSections(false)" class="cursor-pointer">
                        <i class="cursor-pointer icon-angle-double-up" title="APRI TUTTI"></i>    
                    </div>    
                </div>    
            </div>    
            <form class="w-100" [formGroup]="referenteForm">
                <fieldset class="w-100" [disabled]="disalbeForm">
                    <div class="w-100 d-flex flex-column">

                        <div *ngIf="modalMode && showAlert" (click)="showAlert = false" class="alert alert-success cursor-pointer w-100 mb-4 mt-4" role="alert">
                            <span>{{messageAlert}}</span>
                        </div>

                        <!--Sezione Dati Anagrafici-->
                        <div class="d-flex flex-column w-100" *ngIf="referenteTipo == 'P'">
                            <label class="title-section  d-flex justify-content-between">
                                
                                <div class="d-flex align-items-center">
                                    Dati anagrafici
                                </div> 
                                
                                <i (click)="openSection('sectionDatiAnagrafici')" class="cursor-pointer icon-{{checkSectionOpened('sectionDatiAnagrafici') ? 'up' : 'down'}}-open"></i>
                            </label>
                            
                            <div class="d-flex flex-column max-with-1400" *ngIf="checkSectionOpened('sectionDatiAnagrafici')">
                                <div class="d-flex spaced-input align-items-center pt-2 pb-2" *ngIf="updateMode">
                                    <div class="form-check w-100 align-items-center">
                                        <input type="checkbox" class="form-check-input" id="bloccaReferente" formControlName="bloccaReferente">
                                        <label class="form-check-label" for="bloccaReferente">Blocca</label>
                                    </div>
                                </div>
                                <div class="d-flex spaced-input">
                                    <div class="form-group w-100" [ngClass]="{'text-danger' : false}">
                                        <label>Cognome</label>
                                        <input type="text" class="form-control" id="cognome" formControlName="cognome" aria-describedby="cognomeRequired" placeholder="" [ngClass]="{'border-danger' : false}">
                                        <small *ngIf="false" id="pgArrivoRequired" class="form-text">Campo obbligatorio</small>
                                    </div>

                                    <div class="form-group w-100" [ngClass]="{'text-danger' : false}">
                                        <label>Nome</label>
                                        <input type="text" class="form-control" id="nome" formControlName="nome" aria-describedby="nomeRequired" placeholder="" [ngClass]="{'border-danger' : false}">
                                        <small *ngIf="false" id="pgArrivoRequired" class="form-text">Campo obbligatorio</small>
                                    </div>

                                    <div class="form-group w-100" [ngClass]="{'text-danger' : false}">
                                        <label>E-mail</label>
                                        <input type="text" class="form-control" id="email" formControlName="email" aria-describedby="emailRequired" placeholder="" [ngClass]="{'border-danger' : false}">
                                        <small *ngIf="false" id="pgArrivoRequired" class="form-text">Campo obbligatorio</small>
                                    </div>

                                    <div class="form-group w-100" [ngClass]="{'text-danger' : false}">
                                        <label>Invia sempre per email</label>
                                        <select class="form-control" formControlName="inviaSemprePerMail"  [ngClass]="{'border-danger' : false}">
                                            <option [ngValue]="false">No</option>
                                            <option [ngValue]="true">Si</option>
                                        </select>
                                    </div>

                                    <div class="form-group w-100" [ngClass]="{'text-danger' : false}">
                                        <label>Codice fiscale</label>
                                        <input maxlength="16" type="text" class="form-control" id="cf" formControlName="cf" aria-describedby="cfRequired" placeholder="" [ngClass]="{'border-danger' : false}">
                                        <small *ngIf="false" id="pgArrivoRequired" class="form-text">Campo obbligatorio</small>
                                    </div>
                                </div>

                                <div class="d-flex spaced-input">
                                    <div class="form-group w-100"[ngClass]="{'text-danger' : false}">
                                        <label>Nato il</label>
                                        <input type="text" class="form-control" [ngClass]="{'border-danger' : false}" #dataNascitaInput="ngbDatepicker" ngbDatepicker (click)="dataNascitaInput.toggle();">
                                        <input type="hidden" formControlName="dataNascita">
                                    </div>

                                    <div class="form-group w-100" [ngClass]="{'text-danger' : false}">
                                        <label>A </label>
                                        <input type="text" class="form-control" id="natoA" formControlName="natoA" aria-describedby="natoARequired" placeholder="" [ngClass]="{'border-danger' : false}">
                                        <small *ngIf="false" id="pgArrivoRequired" class="form-text">Campo obbligatorio</small>
                                    </div>

                                    <div class="form-group w-100" [ngClass]="{'text-danger' : false}">
                                        <label>Provincia </label>
                                        <input maxlength="2" type="text" class="form-control" id="provinciaNascita" formControlName="provinciaNascita" aria-describedby="provinciaNascitaRequired" placeholder="" [ngClass]="{'border-danger' : false}">
                                        <small *ngIf="false" id="pgArrivoRequired" class="form-text">Campo obbligatorio</small>
                                    </div>

                                    <div class="form-group w-100" [ngClass]="{'text-danger' : false}">
                                        <label>Nazione </label>
                                        <input type="text" class="form-control" id="paese" formControlName="paese" aria-describedby="paeseRequired" placeholder="" [ngClass]="{'border-danger' : false}">
                                        <small *ngIf="false" id="pgArrivoRequired" class="form-text">Campo obbligatorio</small>
                                    </div>
                                </div>
                            </div>                           
                
                        </div>

                        <!--Sezione Dati Fiscali-->
                        <div class="d-flex flex-column w-100" *ngIf="referenteTipo == 'E'">
                            <label class="title-section  d-flex justify-content-between">
                                
                                <div class="d-flex align-items-center">
                                    Dati Fiscali
                                    <!--<span  *ngIf="justChecked || checkSezioniCompilate('sectionFlusso')" class="exlamation-icon">
                                        <i *ngIf="!justChecked && checkSezioniCompilate('sectionFlusso')" title="Campi compilati" class="icon-pencil"></i>
                                        <i *ngIf="justChecked && checkSezioniCompilate('sectionFlusso')" title="Campi obbligatori compilati" class="icon-ok text-success"></i>
                                        <i *ngIf="justChecked && !checkSezioniCompilate('sectionFlusso')" title="Campi obbligatori non compilati" class="icon-attention-alt text-danger"></i>
                                    </span>-->
                                </div> 
                                
                                <i (click)="openSection('sectionDatiFiscali')" class="cursor-pointer icon-{{checkSectionOpened('sectionDatiFiscali') ? 'up' : 'down'}}-open"></i>
                            </label>
                            
                            <div class="d-flex flex-column max-with-1400" *ngIf="checkSectionOpened('sectionDatiFiscali')">
                                <div class="d-flex spaced-input align-items-center pt-2 pb-2" *ngIf="updateMode">
                                    <div class="form-check w-100">
                                        <input type="checkbox" class="form-check-input" id="bloccaReferente" formControlName="bloccaReferente">
                                        <label class="form-check-label" for="bloccaReferente">Blocca</label>
                                    </div>
                                </div>
                                <div class="d-flex spaced-input">
                                    <div class="form-group w-100" [ngClass]="{'text-danger' : false}">
                                        <label>Ragione sociale</label>
                                        <input type="text" class="form-control" id="ragioneSociale" formControlName="ragioneSociale" aria-describedby="ragioneSocialeRequired" placeholder="" [ngClass]="{'border-danger' : false}">
                                        <small *ngIf="false" id="pgArrivoRequired" class="form-text">Campo obbligatorio</small>
                                    </div>

                                    <div class="form-group w-100" [ngClass]="{'text-danger' : false}">
                                        <label>Partita IVA</label>
                                        <input maxlength="12" type="text" class="form-control" id="partitaIva" formControlName="partitaIva" aria-describedby="partitaIvaRequired" placeholder="" [ngClass]="{'border-danger' : false}">
                                        <small *ngIf="false" id="pgArrivoRequired" class="form-text">Campo obbligatorio</small>
                                    </div>

                                    <div class="form-group w-100" [ngClass]="{'text-danger' : false}">
                                        <label>E-mail</label>
                                        <input type="text" class="form-control" id="email" formControlName="email" aria-describedby="emailRequired" placeholder="" [ngClass]="{'border-danger' : false}">
                                        <small *ngIf="false" id="pgArrivoRequired" class="form-text">Campo obbligatorio</small>
                                    </div>

                                    <div class="form-group w-100" [ngClass]="{'text-danger' : false}">
                                        <label>Invia sempre per email</label>
                                        <select class="form-control" formControlName="inviaSemprePerMail"  [ngClass]="{'border-danger' : false}">
                                            <option [ngValue]="false">No</option>
                                            <option [ngValue]="true">Si</option>
                                        </select>
                                    </div>

                                    <div class="form-group w-100" [ngClass]="{'text-danger' : false}">
                                        <label>Codice fiscale</label>
                                        <input maxlength="16" type="text" class="form-control" id="cf" formControlName="cf" aria-describedby="cfRequired" placeholder="" [ngClass]="{'border-danger' : false}">
                                        <small *ngIf="false" id="pgArrivoRequired" class="form-text">Campo obbligatorio</small>
                                    </div>
                                </div>
                            </div>                           
                
                        </div>
                        <!--Sezione Residenza-->
                        <div class="d-flex flex-column w-100">
                            <label class="title-section  d-flex justify-content-between">
                                
                                <div class="d-flex align-items-center">
                                    {{referenteTipo == 'P' ? 'Residenza' : 'Indirizzo'}}
                                </div> 
                                
                                <i (click)="openSection('sectionResidenza')" class="cursor-pointer icon-{{checkSectionOpened('sectionResidenza') ? 'up' : 'down'}}-open"></i>
                            </label>
                            
                            <div class="d-flex flex-column max-with-1400" *ngIf="checkSectionOpened('sectionResidenza')">
                                <div class="d-flex spaced-input">
                                    <div class="form-group w-100" [ngClass]="{'text-danger' : false}">
                                        <label>Via </label>
                                        <input type="text" class="form-control" id="viaResidenza" formControlName="viaResidenza" aria-describedby="viaResidenzaRequired" placeholder="" [ngClass]="{'border-danger' : false}">
                                        <small *ngIf="false" id="pgArrivoRequired" class="form-text">Campo obbligatorio</small>
                                    </div>

                                    <div class="form-group w-100" [ngClass]="{'text-danger' : false}">
                                        <label>Numero</label>
                                        <input type="text" class="form-control" id="numResidenza" formControlName="numResidenza" aria-describedby="numResidenzaRequired" placeholder="" [ngClass]="{'border-danger' : false}">
                                        <small *ngIf="false" id="pgArrivoRequired" class="form-text">Campo obbligatorio</small>
                                    </div>

                                    <div class="form-group w-100" [ngClass]="{'text-danger' : false}">
                                        <label>Comune</label>
                                        <input type="text" class="form-control" id="comuneResidenza" formControlName="comuneResidenza" aria-describedby="comuneResidenzaRequired" placeholder="" [ngClass]="{'border-danger' : false}">
                                        <small *ngIf="false" id="pgArrivoRequired" class="form-text">Campo obbligatorio</small>
                                    </div>

                                    <div class="form-group w-100" [ngClass]="{'text-danger' : false}">
                                        <label>CAP</label>
                                        <input type="text" class="form-control" id="capResidenza" formControlName="capResidenza" aria-describedby="capResidenzaRequired" placeholder="" [ngClass]="{'border-danger' : false}">
                                        <small *ngIf="false" id="pgArrivoRequired" class="form-text">Campo obbligatorio</small>
                                    </div>

                                    <div class="form-group w-100" [ngClass]="{'text-danger' : false}"> 
                                        <label>Provincia</label>
                                        <input maxlength="2" type="text" class="form-control" id="provinciaResidenza" formControlName="provinciaResidenza" aria-describedby="provinciaResidenzaRequired" placeholder="" [ngClass]="{'border-danger' : false}">
                                        <small *ngIf="false" id="pgArrivoRequired" class="form-text">Campo obbligatorio</small>
                                    </div>
                                </div>

                                <div class="d-flex spaced-input">
                                  
                                    <div class="form-group w-100">
                                        <label>Telefono 1</label>
                                        <input type="text" class="form-control" id="telefono1" formControlName="telefono1" aria-describedby="telefono1Required" placeholder="" [ngClass]="{'border-danger' : false}">
                                        <small *ngIf="false" id="pgArrivoRequired" class="form-text">Campo obbligatorio</small>
                                    </div>

                                    <div class="form-group w-100">
                                        <label>Telefono 2</label>
                                        <input type="text" class="form-control" id="telefono2" formControlName="telefono2" aria-describedby="telefono2Required" placeholder="" [ngClass]="{'border-danger' : false}">
                                        <small *ngIf="false" id="pgArrivoRequired" class="form-text">Campo obbligatorio</small>
                                    </div>

                                    <div class="form-group w-100" [ngClass]="{'text-danger' : false}" *ngIf="referenteTipo == 'E'">
                                        <label>FAX</label>
                                        <input type="text" class="form-control" id="fax" formControlName="fax" aria-describedby="faxRequired" placeholder="" [ngClass]="{'border-danger' : false}">
                                        <small *ngIf="false" id="pgArrivoRequired" class="form-text">Campo obbligatorio</small>
                                    </div>
                                </div>
                            </div>                           
                
                        </div>

                        <!--Sezione Domicilio-->
                        <div class="d-flex flex-column w-100" *ngIf="referenteTipo == 'P'">
                            <label class="title-section  d-flex justify-content-between">
                                
                                <div class="d-flex align-items-center">
                                    Domicilio
                                </div> 
                                
                                <i (click)="openSection('sectionDomicilio')" class="cursor-pointer icon-{{checkSectionOpened('sectionDomicilio') ? 'up' : 'down'}}-open"></i>
                            </label>
                            
                            <div class="d-flex flex-column max-with-1400" *ngIf="checkSectionOpened('sectionDomicilio')">
                                <div class="d-flex spaced-input">
                                    <div class="form-group w-100" [ngClass]="{'text-danger' : false}">
                                        <label>Via </label>
                                        <input type="text" class="form-control" id="viaDomicilio" formControlName="viaDomicilio" aria-describedby="viaDomicilioRequired" placeholder="" [ngClass]="{'border-danger' : false}">
                                        <small *ngIf="false" id="pgArrivoRequired" class="form-text">Campo obbligatorio</small>
                                    </div>

                                    <div class="form-group w-100" [ngClass]="{'text-danger' : false}">
                                        <label>Numero</label>
                                        <input type="text" class="form-control" id="numDomicilio" formControlName="numDomicilio" aria-describedby="numDomicilioRequired" placeholder="" [ngClass]="{'border-danger' : false}">
                                        <small *ngIf="false" id="pgArrivoRequired" class="form-text">Campo obbligatorio</small>
                                    </div>

                                    <div class="form-group w-100" [ngClass]="{'text-danger' : false}">
                                        <label>Comune</label>
                                        <input type="text" class="form-control" id="comuneDomicilio" formControlName="comuneDomicilio" aria-describedby="comuneDomicilioRequired" placeholder="" [ngClass]="{'border-danger' : false}">
                                        <small *ngIf="false" id="pgArrivoRequired" class="form-text">Campo obbligatorio</small>
                                    </div>

                                    <div class="form-group w-100" [ngClass]="{'text-danger' : false}">
                                        <label>CAP</label>
                                        <input type="text" class="form-control" id="capDomicilio" formControlName="capDomicilio" aria-describedby="capDomicilioRequired" placeholder="" [ngClass]="{'border-danger' : false}">
                                        <small *ngIf="false" id="pgArrivoRequired" class="form-text">Campo obbligatorio</small>
                                    </div>

                                    <div class="form-group w-100" [ngClass]="{'text-danger' : false}">
                                        <label>Provincia</label>
                                        <input maxlength="2" type="text" class="form-control" id="provinciaDomicilio" formControlName="provinciaDomicilio" aria-describedby="provinciaDomicilioRequired" placeholder="" [ngClass]="{'border-danger' : false}">
                                        <small *ngIf="false" id="pgArrivoRequired" class="form-text">Campo obbligatorio</small>
                                    </div>
                                </div>
                            </div>                  
                        </div>
                    </div>
                </fieldset>
                <div class="d-flex spaced-input max-with-1400 justify-content-end">
                    <button *ngIf="showSave" type="button" class="btn bg-primary-archipro text-white mt-3" (click)="submit()">Salva</button>
                    <button *ngIf="!updateMode && showInit" type="button" class="btn bg-primary-archipro text-white mt-3" (click)="!updateMode && initForm()">Nuovo referente</button>
                    
                </div>
            </form>
        </section>
    </section>
</section>

<app-modal #dynamicModalComponent></app-modal>