export class Flusso{
    dataOperazione : string;
    oraOperazione : string;
    flussoOperazione : string;
    idmail : number;
    oggetto : string;
    progr : number;
    tipoMessaggio : string;
    tipoOperazioneDescr : string;
    trasmessa : boolean;
}