<section class="w-100 treeview" *ngIf="show">
    <div *ngFor="let element of listObj" class="pl-4" >
        <div class="" [ngClass]="{'d-flex height_24px align-items-center' : element.terminale}">
            <i *ngIf="modalMode && element.terminale && riporta != undefined" title="Riporta su form" class="icon-export horizontal-speculate text-primary-archipro cursor-pointer"
            (click)="riporta(element)"></i>
            <i *ngIf="apriGuida" class="icon-help cursor-pointer" (click)="apriGuida(element.paginaPdf)"></i>
            <i *ngIf="hasChild(element) && element.permettiCreazioneFascicolo" class="icon-docs cursor-pointer" title="{{element.description}}" (click)=" doAction(element)"></i>
            <span class="m-2 cursor-pointer white-space-nowrap cut-text" (click)="hasChild(element) ? changeShowElement(element) : doAction(element)">
                <i class="{{element.icona}} color-icon"></i>
                <span title="{{element.description}}">
                    {{element.description}}
                </span>
            </span>

            <tree-view *ngIf="hasChild(element)" [listObj]="element.children" [show]="element.show" [f]="f" [riporta]="riporta" [modalMode]="modalMode" [apriGuida]="apriGuida"></tree-view>
        </div>
    </div>
</section>
