export class DettaglioDocumentoRequest{

    titolo1 : number;
    titolo2 : number;
    titolo3 : number;
    titolo4 : number;
    titolo5 : number;
    titolo6 : number;    

    tipoDoc : string;  // "T"=tutti P=protocollati NP
    
    numeroFascicolo : number;
    annoFascicolo : number;
    numTitolo : number;
    
    collocazione : string; // SI o NO
    currentPage : number;
    numRow : number;

  
    oggetto : string;    

    numeroProtocollo : number;
    annoProtocollo : number;


    constructor(){

    }

    setNumeroProtocollo(numeroProtocollo : number){
        this.numeroProtocollo = numeroProtocollo;
    }

    getNumeroProtocollo() : number{
        return this.numeroProtocollo;
    }

    setAnnoProtocollo(annoProtocollo : number){
        this.annoProtocollo = annoProtocollo;
    }

    getAnnoProtocollo() : number{
        return this.annoProtocollo;
    }


}