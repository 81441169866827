export class PrimaryKeyPratica{

    annoPrat : number;
    numPrat : number;


    /**
     * Funzione che contolla se i anno e numero del protocollo non sono nulli e 0
     */
     checkPrimaryKey() : boolean
     {
         return this.numPrat != null 
             && this.numPrat != undefined 
             && this.numPrat != 0
             && this.annoPrat != null 
             && this.annoPrat != undefined 
             && this.annoPrat != 0;
     }

}