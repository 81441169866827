export class Titolario{
    titolo1 : number;
    titolo2 : number;
    titolo3 : number;
    titolo4 : number;
    titolo5 : number;
    titolo6 : number;
    lemma : string;


    constructor(obj?){

        if(obj){
            this.titolo1 = obj.titolo1;
            this.titolo2 = obj.titolo2;
            this.titolo3 = obj.titolo3;
            this.titolo4 = obj.titolo4;
            this.titolo5 = obj.titolo5;
            this.titolo6 = obj.titolo6;
            this.lemma = obj.lemma ? obj.lemma : null;
        }
        else{
            this.titolo1 = 0;
            this.titolo2 = 0;
            this.titolo3 = 0;
            this.titolo4 = 0;
            this.titolo5 = 0;
            this.titolo6 = 0;
            this.lemma = "";
        }

        
    }

    getFullTitolarioValue()
    {
        let titolario = "";
        for(let i = 1; i <= 6; i++)
        {
            let nameVariabile = "titolo" + i;
            titolario = i == 1 ? (titolario + this[nameVariabile]) : this.buildTitolarioString(titolario, nameVariabile);
        }

        return titolario;
    }


    buildTitolarioString(titolario:string, nameVariabile : string)
    {
        return titolario + "." + this[nameVariabile];
    }

    setTitolarioByString(titolario : string) : void{
        let titolarioSplitted = titolario.split(".");
        for(let i = 1; i <= 6; i++){
            this["titolo"+i] = titolarioSplitted[i-1];
        }
    }



}