import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { sha512 } from 'js-sha512';
import { interval, Subscription } from 'rxjs';
import { AllegatoObj } from 'src/app/model/AllegatoObj';
import { Referente } from 'src/app/model/Referente';
import { Riferimenti } from 'src/app/model/Riferimenti';
import { VerifyLoginResult } from 'src/app/model/VerifyLoginResult';
import { GlobalService } from 'src/app/service/globalService/global.service';
import { RequestService } from 'src/app/service/requestService/request.service';
import { StaticProperty } from 'src/app/Utility/StaticProperty';
import { setFileDescription, Utility } from 'src/app/Utility/Utility';
import { buildCompetenze, initFormWithData } from './UtilityIterApprovazione';

declare var DocumentSigner : any;

@Component({
  selector: 'app-approva-documento',
  templateUrl: './approva-documento.component.html',
  styleUrls: ['./approva-documento.component.scss']
})
export class ApprovaDocumentoComponent implements OnInit {

  user : VerifyLoginResult;
  docNumString = "";

  @ViewChild('inputFiles') inputFiles : ElementRef;

  @Input() objParameterComponent : any;

  afterApprovazione : (docNumString : string) => void;

  //Variabile utilizzata per disattivare gli altri edit nella lista dei file
  noEditOtherFile : boolean = false;

  /**
   * Oggetto protocollo preso da loadProtocollo
   */
  protocollo : any = {};

  /**
   * Lista opzioni dello stato del protocollo
   */
  optionStato : any = [];

  /**
   * Campi form
   */
  formObj : FormGroup;

  statoNP : FormControl = new FormControl("");
  note : FormControl = new FormControl("");
  oggetto : FormControl = new FormControl("");
  files : FormArray = new FormArray([]);
  competenze : FormArray = new FormArray([]);
  referenti : FormArray = new FormArray([]);
  riferimenti : FormArray = new FormArray([]);
  scadenze : FormArray = new FormArray([]);
  /**************** */


  constructor(private requestService : RequestService, public globalService : GlobalService, private formBuilder : FormBuilder) {
    this.formObj = this.formBuilder.group({
      //CAMPI NON MODIFICABILI
      annoProt : null,
      numProt : null,
      tipoTrasm : null,
      tipoDocumento : null,
      tipoTrasmissione : null,
      firmatario : null,
      flusso : null,
      pgArrivo : null,
      pgArrivoDel : null,
      dataEffett : null,
      titolo1 : null,
      titolo2 : null,
      titolo3 : null,
      titolo4 : null,
      titolo5 : null,
      titolo6 : null,
      numeroFascicolo : null,
      annoFascicolo : null,
      rifRegistro : null,
      riservato : null,

      //CAMPI NON MODIFICABILI
      files : this.files,
      oggetto : this.oggetto,
      statoNP : this.statoNP,
      competenze : this.competenze,
      referenti : this.referenti,
      scadenze : this.scadenze,
      riferimenti : this.riferimenti,
      note : this.note
    });

    this.user =  Utility.localStorageGet(StaticProperty.UTENTE_SESSIONE);
  }

 async ngOnInit() {
    await this.requestService.postRequestAsync("getFiltriElencoDocumenti", "S",
      (response) => {
        this.optionStato = response.obj.filter(element => element.value != 5);
    });

    this.afterApprovazione = this.objParameterComponent.afterApprovazione;
    this.docNumString = this.objParameterComponent.docNumString;
    delete this.objParameterComponent.afterApprovazione;
    delete this.objParameterComponent.docNumString;

    this.getDatiProtocollo();

  }

  getDatiProtocollo() : void{
    this.requestService.postRequest("loadProtocollo", this.objParameterComponent,
    (success) => {
      let objProtocollo : any = initFormWithData(success.obj);


       /*************************************************/
       //Per lista competenze

       buildCompetenze(objProtocollo.competenze).forEach(element => this.competenze.push(new FormControl(element)));
       delete objProtocollo.competenze;
       /************************************************* */

       /************************************************* */
      //Per referenti vedere se è possibile passare il campo tipo2Description cosi da poter fare la ricerca su searchReferenteAutocomplete
      if(objProtocollo.referenti != null && objProtocollo.referenti.length > 0)
      {
        /*s
        Cancello mittente, referenteTipo e referenteCodice perchè una volta settati il patchValue leggendoli dal server
        uguali a null, me li riscrive con i null e non mi vengono compilati correttamente
        */
        delete objProtocollo.mittente;
        delete objProtocollo.referenteCodice;
        delete objProtocollo.referenteTipo;

        objProtocollo.referenti.forEach(element => {
          this.referenti.push(new FormControl(new Referente(element)));
        });
      }

      delete objProtocollo.referenti;
      /************************************************* */


       /************************************************* */
       //da objProtocollo.allegatiMail mi creo la lista dei file
       objProtocollo.metadati.forEach(element => {


         let metadati : AllegatoObj = new AllegatoObj(element);
         let file = this.creaOggettoFile({file :  new File([""], metadati.fileOrig )});

         file.value.file.description = element.descrizione;
         file.value.allegatoObj = metadati;

         this.files.push(file);
     });
     /************************************************* */

        /*************** GESTIONE SCADENZE *************** */

        if(this.globalService.GLOBAL.PRO_GESTIONE_SCADENZE)
        {

          if(Utility.checkObj(objProtocollo.scadenze))
          {
            objProtocollo.scadenze.forEach(element => {
              this.scadenze.push(new FormControl(
                //{ data : new DataStringTransformPipe().transform(element.data), note: element.note }
                { data :element.data, note: element.note }
              ));
            })
          }
        }
        delete objProtocollo.scadenze;

        /************************************************* */

        /*************** GESTIONE RIFERIMENTI *************** */

        if(this.globalService.GLOBAL.PRO_GESTIONE_RIFERIMENTI)
        {
          if(Utility.checkObj(objProtocollo.riferimenti))
          {
            objProtocollo.riferimenti.forEach(element => {
              this.riferimenti.push(new FormControl(new Riferimenti(element)));
            })
          }
        }
        delete objProtocollo.riferimenti;


     //Funzione per convertire il json del server e rivisto dalle funzioni precedenti al formato del formbuilder
     this.formObj.patchValue(objProtocollo)

    }
    )
  }

  salva() : void{


    if(this.timer != undefined) this.timer.unsubscribe();
    let formData : FormData = new FormData();
    //Sistemo la data con il servizio ngbdatestructtstring per convertire l'oggetto di ngbDatepicker in string
    let obj = Utility.deepCopyObject(this.formObj.getRawValue());

    obj.metadati = [];

    for(let i = 0; i < this.files.controls.length; i++)
    {
      let metadatiObj : AllegatoObj = this.files.controls[i].value.allegatoObj;
      if(!Utility.checkObj(metadatiObj))
      {
        metadatiObj = new AllegatoObj();
        metadatiObj.applic = "elettronico";
      }
      metadatiObj.descrizione = this.files.controls[i].value.file.description;


      obj.metadati.push(metadatiObj);
      formData.append("files",(this.files.controls[i] as FormGroup).controls.file.value);
    }

    obj.tipoOperazione = "MODIFICA";
    formData.append("dati", JSON.stringify(obj));


    this.requestService.postMultipartRequestClient("modificaProtocollo", formData,
    success => {
      this.afterApprovazione(this.docNumString);
    })
  }


  /**
   * Gestione sezione file
   */

   spostaGiu(i){
    var selected = this.files.at(i);
    var next = this.files.at(i+1);
    this.files.removeAt(i);
    this.files.insert(i,next);
    this.files.removeAt(i+1);
    this.files.insert(i+1,selected);

  }

  spostaSu(i){
    var selected = this.files.at(i);
    var prev = this.files.at(i-1);
    this.files.removeAt(i);
    this.files.insert(i,prev);
    this.files.removeAt(i-1);
    this.files.insert(i-1,selected);
  }

   rimuoviFile(file : FormGroup, i){
    if(this.timer != undefined) this.timer.unsubscribe();

    var allegatoObj = file.value.file.allegatoObj;


    //TEnto di eliminare anche dal server un file per cui avevo già richiesto l'anteprima
    if(allegatoObj != undefined && allegatoObj.url != null && allegatoObj.url != ''){

      let formData : FormData = new FormData();
      formData.append("url", allegatoObj.url);

      this.requestService.postMultipartRequestClient("removeFileFromPreview", formData,
        (response) => { },
        ()=>{}
      );

    }

    this.files.removeAt(i);

	}

  editDescriptionFile(file, value, i){
    file.value.file.description = value;
    return true;
  }

   creaOggettoFile(fileDaInserire) : FormGroup{
    return this.formBuilder.group(fileDaInserire);
  }

   changeListFiles(event) {
    let formData : FormData = new FormData();

    let files = event.target.files;
    if (files) {
      for (let file of files) {

        file.description = setFileDescription(file);

        let reader = new FileReader();

        reader.readAsDataURL(file);

        reader.onload = (e: any) => {
            this.files.push(this.creaOggettoFile({
                file,
                //url: e.target.result  //Base64 string for preview image
            }));

        }

     }
    }
    this.inputFiles.nativeElement.value = '';
  }

  /**
   * Metodo che aggiunge alla lista dei file un file cartaceo o da scansionare
   * @param type : cartaceo/scansione
   */
  addScansioneCartaceo(type : string) : void{

    let metadati : AllegatoObj = new AllegatoObj();
    let typeCapitalized = Utility.capitalizeText(type); //"Scansione" o "Cartaceo"

    metadati.descrizione = typeCapitalized;
    metadati.applic = type;
    metadati.daScansire = (type == "scansione");



    let file = this.creaOggettoFile({file :  new File([""], typeCapitalized )});

    file.value.file.description = typeCapitalized;
    file.value.allegatoObj = metadati;

    this.files.push(file);

  }

    /**************************************************************** */
    /***************** Interazione con adobe ************************ */
    /**************************************************************** */
    timer : Subscription;
    scanIndex : number;
    scansionaFile(index : number){
      if(this.timer != undefined) this.timer.unsubscribe();
      DocumentSigner._path = 'http://localhost:18080/dwr';

      let timestamp = Date.now();
      let fakejsession = sha512((this.user.matricola.toString()+timestamp.toString()));
      let maxFileSize = this.globalService.GLOBAL.PRO_MAXFILE_UPLOAD;

      let url = location.origin + location.pathname + "uploadScansione?jsessionid=" + fakejsession;

      //Tipo scansione
      url += "&applic=elettronico";

      //principale
      url += "&principale=" + (index == 0 ? "S" : "N");

      //Descrizione
      url += "&descrizione=Scan_" + this.user.matricola + "_" + timestamp;
      url += "&TipoUpload=auto";

      this.scanIndex = index;



      DocumentSigner.openAdobe(url, maxFileSize, this.checkFinish);

      this.timer = interval(1000).subscribe(
        this.checkFinish
      );
    }

    checkFinish = () :void => {
      DocumentSigner.readStatus(this.getResponse);
    }

    getResponse = (status) : void =>{
      //Se la scansione è terminata
      if(!(status == "false"))
      {
        this.timer.unsubscribe();
        DocumentSigner.readResponse(this.chiudiTutto)
      }
	  }

    chiudiTutto = (response) : void =>{
        let responseJSON = JSON.parse(response);
        this.files.controls[this.scanIndex].value.allegatoObj.url = responseJSON.obj.url;
        this.files.controls[this.scanIndex].value.allegatoObj.daScansire = false;
	  }

}
