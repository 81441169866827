export class PrimaryKeyFascicolo{
    numeroFascicolo : number;
    annoFascicolo : number;
    numTitolo : number;

    constructor(obj?){
        if(obj)
        {
            this.numeroFascicolo = obj.numeroFascicolo;
            this.annoFascicolo = obj.annoFascicolo;
            this.numTitolo = obj.numTitolo;
        }
    }
}