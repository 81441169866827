export class LoginModel{

    user : string;
    password: string;
    remove : boolean = false;
    token : string;


    constructor(user?: string, password?: string){
        this.user = user;
        this.password = password;
    }

    setUser(user: string)
    {
        this.user = user;
    }

    setPassword(password: string)
    {
        this.password = password;
    }

    setToken(token: string)
    {
        this.token = token;
    }

}