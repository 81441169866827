<section class="h-100 w-100 elenco-agd-uo-container">
    <div class="list-agd-uo-per-cognome">
        <table class="table w-100 table-striped">
            <thead>
              <tr>
                
                <th scope="col">Utente</th>
                <th scope="col">AGD</th>
                <th scope="col">UO</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let element of listAgdUo" class="cursor-pointer" (click)="addAgdUoScelto(element, true)">
                <td class="min-width-10rem">{{(element.descrizioneUtente == undefined || element.descrizioneUtente == null) ? element.descrizioneRidotta : element.descrizioneUtente}}</td>
                <td>{{element.descrizionePadre}}</td>
                <td>{{element.descrizioneRidotta}} ({{element.codice}})</td>
              </tr>
            </tbody>
          </table>
    </div>

</section>