import { Component, OnInit, HostListener, ViewChild, ChangeDetectorRef } from '@angular/core';
import { SCREEN_SIZE } from 'src/app/service/resize/screen-size.enum';
import { ResizeService } from './../../../service/resize/resize.service';
import { VerifyLoginResult } from './../../../model/VerifyLoginResult';
import { getLabelAgd, getLabelUo, Utility } from './../../../Utility/Utility';
import { ScrivaniaComponent } from '../../moduli/scrivania-module/scrivania.component';
import { RequestService } from 'src/app/service/requestService/request.service';
import { StaticProperty } from 'src/app/Utility/StaticProperty';
import { GlobalService } from 'src/app/service/globalService/global.service';
import { ModalComponent } from '../modal/modal.component';
import { OptionModal } from '../modal/OptionModal';
import { CambioPasswordComponent } from '../cambio-password/cambio-password.component';


@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss']
})
export class NavBarComponent {

  //****************************************************** */
  //Variabili ulitilizzate per dichiarare e inizializzare la modale
  //****************************************************** */
  @ViewChild('dynamicModalComponentNavbar')
  dynamicModal : ModalComponent;
  optionModal : OptionModal = new OptionModal();
  //****************************************************** */
  //****************************************************** */

  size : SCREEN_SIZE;

  user : VerifyLoginResult;

  getLabelAgd : () => string = getLabelAgd;
  getLabelUo : () => string = getLabelUo;


  constructor(private resizeSvc: ResizeService, private requestService : RequestService, private readonly changeDetectorRef: ChangeDetectorRef, public globalService : GlobalService) {}

  @HostListener("window:resize", [])
  private onResizeFunction() {
    this.size = this.resizeSvc.onResize();
  }

  ngAfterViewInit(){


    this.size = this.resizeSvc.onResize();
    this.user = Utility.localStorageGet(StaticProperty.UTENTE_SESSIONE);

    if(this.user.uoAssociati.length > 0)
    {
      let uoSelezionato = Utility.getUoSelezionato();
      this.user.descrAgd = uoSelezionato.desAD;
      this.user.descrUo = uoSelezionato.desUO;
    }
  }


  ngAfterViewChecked(): void {
    this.changeDetectorRef.detectChanges();
  }


  goToDetermine(){
    location.href = "/Determine";
  }

  goToProcedo(){
    location.href = "/Procedo";
  }

  actionInfoUtente()
  {
    alert("infoutente");
  }

  logout()
  {
    this.requestService.logout();
   }

  switchArchiPRO()
  {
    window.location.href = "/ArchiPRO/PantareiFramesetScrivania";
  }

  toggleSidebar(){
    ScrivaniaComponent.setShowSidebar(!ScrivaniaComponent.showSidebar);
  }

  getShowSidebar() : boolean
  {
    return ScrivaniaComponent.getShowSidebar();
  }

  workInProgress()
  {
    alert("Work in progress")
  }

  openCambioPassword(){
    this.optionModal = new OptionModal();

    this.optionModal.titolo = "Cambio password"

    this.optionModal.additionalClassModalBody ="p-0";

    //Opzioni per settare il component da caricare dinamicamente
    //******************************************************* */
    this.optionModal.setLoadDynamicComponent(true);
    this.optionModal.dynamicComponnet = CambioPasswordComponent;

    this.optionModal.objParameterComponent = {
    }

    this.dynamicModal.initModal(this.optionModal);
    this.dynamicModal.openModal();
  }

}
