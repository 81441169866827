import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { NavigazioneComponent } from './../../moduli/ricerche/navigazione/navigazione.component';

@Component({
  selector: 'tree-view',
  templateUrl: './tree-view.component.html',
  styleUrls: ['./tree-view.component.scss']
})
export class TreeViewComponent implements OnInit {

  /**
   * Lista degli oggetti ad albero che deve essere visualizzata
   * la struttura deve essere:
   * 
   * {
   *     description : "Label della riga",
   *     valore : "valore della riga",
   *     icona : "icona da visualizzare",  -----> l'icona può essere qualunque (di fontello), però se l'oggetto ha l'elemento figlio allora 
   *                                              l'icona da inserire sara icon-folder-close (che poi verrà switchata con quella aperta
   *                                              a seconda se verrà aperto il sottolivello oppure no) 
   *     children : [Lista di altri oggetti simile a questo]
   * }
   * 
   */
  @Input()
  listObj = [];


  /**
   * Variabile utilizzata per capire se far vedere il sottolivello oppure no.
   * 
   * Alla prima inizializzazione del componente deve essere per forza true
   */
  @Input()
  show : boolean;

  /**
   * funzione di callback passata dal component genitore
   * 
   * NB: anche se ci sono sottolivelli di sottolivelli, la callback sarà sempre quella
   * del component genitore dove è stato inizializzato la prima volta perchè è passata
   * ricorsivamente
   */
  @Input() f: (args: any) => void;



  /**
   * Funzione di callback passata dal component genitore (a sua volta passata dalla modale)
   */
  @Input() riporta : (obj : any) => void;


  @Input() apriGuida : (page : number) => void; 

  @Input()  modalMode : boolean;


  constructor() { 

  }

  /**
   * Funzione utilizzata per aprire e chiudere un sottolivello.
   * La funzione, oltre che cambiare la variabile per visualizzare o no il sottolivello, 
   * si occupa di cambiare l'icona del folder (da aperta a chiusa e viceversa)
   * 
   * @param element : Elemento dove è stato effettuato il click
   */
  changeShowElement(element)
  {
    if(this.hasChild(element))
    {
      element.show = !element.show
      element.icona = element.show ? "icon-folder-open-empty" : "icon-folder-close";
    }
  }

  /**
   * Funzione che indica se un elemento ha figli (quindi un sottolivello) oppure no
   * 
   * @param element : Elemento dove è stato effettuato il click
   * @returns : booleano che indica se c'è un sottolivello dell'elemento
   */
  hasChild(element)
  {
    return element.children && element.children.length >= 1;
  }

  /**
   * funzione che fa partire la callback del genitore
   * 
   * @param value 
   */
  doAction(element)
  {
    this.f(element);
  }

  ngOnInit(): void {
    this.listObj.forEach(x => { x.icona = x.terminale ? ( !x.riservato ? 'icon-doc' : "icon-lock-filled") : (x.show ? "icon-folder-open-empty" : "icon-folder-close") });
  }

}

