import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { RequestService } from 'src/app/service/requestService/request.service';
import { StaticProperty } from 'src/app/Utility/StaticProperty';
import { Utility } from 'src/app/Utility/Utility';

@Component({
  selector: 'app-viwe-doc',
  templateUrl: './viwe-doc.component.html',
  styleUrls: ['./viwe-doc.component.scss']
})
export class ViweDocComponent implements OnInit {

  token : string = "";
  constructor(private activateRoute : ActivatedRoute, private requestService : RequestService) {

    this.activateRoute.paramMap.subscribe(params => {
      if(params.get('token')){
        this.token = params.get('token') as string;
        this.getProtocolloByToken();
      }
    });
  }

  ngOnInit(): void {
  }

  getProtocolloByToken() : void{

    if(Utility.localStorageGet(StaticProperty.UTENTE_SESSIONE))
    {
      this.requestService.postRequest("getDocumentoByToken", this.token,
        success => {
          window.location.href = "#/modulo/ricerche/ricercaDocumenti/" + success.obj.numeroProtocollo + "/" + success.obj.annoProtocollo + (success.obj.siglaUff ? "/"+success.obj.siglaUff : "");
        },
        error => {
          this.redirectLogin();
        }
      );
    }
    else
    {
      this.redirectLogin();
    }

  }

  redirectLogin(){
    Utility.localStorageSet(StaticProperty.REDIRECT_AFTER_LOGIN, ("#/viewDoc/" + this.token));
    window.location.href = "/ArchiPRO/#/login";
  }

}
