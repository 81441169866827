import { Component, OnInit, Input, HostListener } from '@angular/core';
import { PrimaryKey } from 'src/app/model/PrimaryKey';
import { Utility } from 'src/app/Utility/Utility';
import { RequestService } from 'src/app/service/requestService/request.service';
import { DettaglioFlussi } from './../../../../model/DettaglioFlussi';
import { PrimaryKeyFlussoMail } from 'src/app/model/PrimaryKeyFlussoMail';
import { Flusso } from 'src/app/model/Flusso';
import { SCREEN_SIZE } from 'src/app/service/resize/screen-size.enum';
import { ResizeService } from 'src/app/service/resize/resize.service';


@Component({
  selector: 'app-interoperabilita',
  templateUrl: './interoperabilita.component.html',
  styleUrls: ['./interoperabilita.component.scss']
})
export class InteroperabilitaComponent implements OnInit {

  mail : any = {
    dataInvio30 : "2019-09-24",
    mittente : "g.cicero@idsunitelm.it",
    destinatario : "\"gruppo.pa@legalmail.it\" <gruppo.pa@legalmail.it>",
    oggetto : "test attach nome lungo",
    id : 5036
  }

  
  //Questo metodo viene passato e viene avviato solo se la pagina viene chiamata dal viewer
  @Input() openMailViewer: (pkFlussoMail : PrimaryKeyFlussoMail) => void;

  @Input() pk : PrimaryKey;

  searchInProgress : boolean = false;

  size : SCREEN_SIZE;

  dettaglioFlussi : DettaglioFlussi = new DettaglioFlussi();

  constructor(private requestService : RequestService, private resizeSvc: ResizeService) { 

    this.size = this.resizeSvc.onResize();
  }


  @HostListener("window:resize", [])
  private onResizeFunction() {
    this.size = this.resizeSvc.onResize();
  }

  ngOnInit(): void {
    this.getFlussi();
  }

  getFlussi() : void{
    if(Utility.checkObj(this.pk))
    {
      this.searchInProgress = true;
      this.requestService.postRequest("dettaglioFlussi", this.pk,
        (success) =>{
          
          this.dettaglioFlussi.intestazione = success.obj.intestazione;
          this.dettaglioFlussi.setList(success.obj.list);
          this.searchInProgress = false;
        },
        () => {
          this.searchInProgress = false;
        }
      )
    }
  }


  openMail(flusso : Flusso) : void{

    let pkFlussoMail : PrimaryKeyFlussoMail = new PrimaryKeyFlussoMail();
    pkFlussoMail.annoProt = this.pk.annoProt;
    pkFlussoMail.numProt = this.pk.numProt;
    pkFlussoMail.progr = flusso.progr;
    pkFlussoMail.idmailMov = flusso.idmail;

    if(this.openMailViewer)
    {
      this.openMailViewer(pkFlussoMail);
    }
    else
    {
      /*Qua si definisce se la chiamata non è passata dal viewer 
      * (vuol dire che il component viene chiamato da una pagina e non nella modale del viewer)
      */
    }
  }

}
