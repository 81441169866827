<div class="sidebar border-right d-block flex-column h-100 position-relative">

    <div class="">
        <div class="dropdown-information d-flex flex-column text-primary-archipro ">

            <div class="righe-info d-flex justify-content-center mb-2">
                <div class="w-100 d-flex justify-content-center">
                    <i class="icon-user photo-profile"></i>
                </div>
            </div>

            <div class="righe-info d-flex justify-content-center mb-2">
                <!--<span><b>Nome:</b> {{user.nome}}</span>
                <span><b>Cognome:</b> {{user.cognome}}</span>-->
               <b style="font-size: 18px;">{{user.nome}} {{user.cognome}}</b>
                <!--<b  style="font-size: 18px;">
                    Giuseppa Basile
                </b>-->

            </div>
            <div class="righe-info d-flex justify-content-between">
                <span><b>Matr:</b> {{user.matricola}}</span>
                <span title="{{user.descrAgd}}"><b>{{getLabelAgd()}}:</b> {{user.codAD}}</span>
                <span title="{{user.descrUo}}"><b>{{getLabelUo()}}:</b> {{user.codUO}}</span>
            </div>
            <div class="righe-info d-flex justify-content-between">

            </div>
        </div>

        <section *ngIf="false">
            <div class="sidebar-item-container" (click)="refreshActiveElement()" *ngIf="false">
                <div class="sidebar-element w-100 p-3 border-bottom d-flex justify-content-between">
                    <div class="sidebar-element-name">
                        <i class="icon-home"></i>
                        <span>Dashboard</span>
                    </div>
                </div>
            </div>
            <div class="sidebar-item-container">
                <div class="sidebar-element w-100 p-3 border-bottom d-flex justify-content-between" data-toggle="collapse" data-target="#elenchi-sub-menu" aria-controls="elenchi-sub-menu" aria-expanded="true" aria-controls="elenchi-sub-menu" [ngClass]="{'active' : menuElementActive == 'elenchi'}">
                    <div class="sidebar-element-name">
                        <i class="icon-list"></i>
                        <span>Elenchi</span>
                    </div>
                    <i class="icon-down-open"></i>
                </div>
                <div class="sidebar-sub-element-container border-bottom collapse" id="elenchi-sub-menu" (click)="refreshActiveElement()" [ngClass]="{'show' : menuElementActive == 'elenchi'}"> <!--animate__animated animate__bounceInLeft-->

                    <div routerLink="/modulo/elenchi/elencoDocumenti" class="sidebar-sub-element w-100 p-2 pl-35px" [ngClass]="{'active' : (menusubElementActive == 'elencoDocumenti'), 'element-disabled' : false}">
                        <span>Documenti</span>
                    </div>

                    <div *ngIf="false" routerLink="/modulo/elenchi/elencoDocPrenotati" class="sidebar-sub-element w-100 p-2 pl-35px" [ngClass]="{'active' : (menusubElementActive == 'elencoDocPrenotati')}">
                        <span>Documenti prenotati</span>
                    </div>

                    <div routerLink="/modulo/elenchi/fascicoliInArrivo" class="sidebar-sub-element w-100 p-2 pl-35px" [ngClass]="{'active' : (menusubElementActive == 'fascicoliInArrivo')}">
                        <span>Fascicoli in arrivo</span>
                    </div>
                    <div routerLink="/modulo/elenchi/fascicoliRicevuti" class="sidebar-sub-element w-100 p-2 pl-35px" [ngClass]="{'active' : (menusubElementActive == 'fascicoliRicevuti')}">
                        <span>Fascicoli ricevuti</span>
                    </div>
                    <div routerLink="/modulo/elenchi/emailInarrivo" class="sidebar-sub-element w-100 p-2 pl-35px" [ngClass]="{'active' : (menusubElementActive == 'emailInarrivo')}">
                        <span>Email in arrivo</span>
                    </div>
                    <!--<div routerLink="/modulo/elenchi/interoperabilita" class="sidebar-sub-element w-100 p-2 pl-35px" [ngClass]="{'active' : menusubElementActive == 'interoperabilita'}">
                        <span>Interoperabilita'</span>
                    </div>       -->
                </div>
            </div>

            <div class="sidebar-item-container"><!--Div in cui fare l'ngfor-->
                <div class="sidebar-element w-100 p-3 border-bottom d-flex justify-content-between collapsed" (click)="!(size < 3) && menuElementActive = 'documento'" data-toggle="collapse" data-target="#documento-sub-menu" aria-controls="documento-sub-menu" aria-expanded="false" [ngClass]="{'active' : menuElementActive == 'documento', 'element-disabled disabled' : size < 3}">

                    <div class="sidebar-element-name">
                        <i class="icon-doc-text"></i>
                        <span>Documento</span>
                    </div>
                    <i class="icon-down-open"></i>
                </div>
                <div *ngIf="!(size < 3)" class="sidebar-sub-element-container border-bottom collapse" id="documento-sub-menu" (click)="refreshActiveElement()" [ngClass]="{'show' : menuElementActive == 'gestioneDocumenti'}"> <!--animate__animated animate__bounceInLeft-->
                    <div routerLink="/modulo/gestioneDocumenti/nuovoProtocollo" class="sidebar-sub-element w-100 p-2 pl-35px" [ngClass]="{'active' : menusubElementActive == 'nuovoProtocollo'}">
                        <span>Nuovo Protocollo</span>
                    </div>

                    <div *ngIf="false" routerLink="/modulo/gestioneDocumenti/trasmettiDocumento" class="sidebar-sub-element w-100 p-2 pl-35px" [ngClass]="{'active' : menusubElementActive == 'trasmettiDocumento'}">
                        <span>Trasmetti documento</span>
                    </div>
                </div>
            </div>

            <div class="sidebar-item-container">
                <div class="sidebar-element w-100 p-3 border-bottom d-flex justify-content-between collapsed" data-toggle="collapse" data-target="#fascicoli-sub-menu" aria-controls="fascicoli-sub-menu" aria-expanded="false" [ngClass]="{'active' : menuElementActive == 'gestioneFascicoli'}">
                    <div class="sidebar-element-name">
                        <i class="icon-folder-open-empty"></i>
                        <span>Fascicoli</span>
                    </div>
                    <i class="icon-down-open"></i>
                </div>
                <div class="sidebar-sub-element-container border-bottom collapse" id="fascicoli-sub-menu" (click)="refreshActiveElement()" [ngClass]="{'show' : menuElementActive == 'gestioneFascicoli'}">
                    <div routerLink="/modulo/gestioneFascicoli/nuovoFascicolo" class="sidebar-sub-element w-100 p-2 pl-35px" [ngClass]="{'active' : menusubElementActive == 'nuovoFascicolo'}">
                        <span>Nuovo fascicolo</span>
                    </div>
                </div>
            </div>
            <div class="sidebar-item-container">
                <div class="sidebar-element w-100 p-3 border-bottom d-flex justify-content-between collapsed" data-toggle="collapse" data-target="#ricerche-sub-menu" aria-controls="ricerche-sub-menu" aria-expanded="false" [ngClass]="{'active' : menuElementActive == 'ricerche'}">
                    <div class="sidebar-element-name">
                        <i class="icon-search"></i>
                        <span>Ricerche</span>
                    </div>
                    <i class="icon-down-open"></i>
                </div>
                <div class="sidebar-sub-element-container border-bottom collapse" id="ricerche-sub-menu" (click)="refreshActiveElement()" [ngClass]="{'show' : menuElementActive == 'ricerche'}"> <!--animate__animated animate__bounceInLeft-->
                    <div routerLink="/modulo/ricerche/ricercaDocumenti" class="sidebar-sub-element w-100 p-2 pl-35px" [ngClass]="{'active' : menusubElementActive == 'ricercaDocumenti'}">
                        <span>Documento</span>
                    </div>
                    <div routerLink="/modulo/ricerche/fascicoli" class="sidebar-sub-element w-100 p-2 pl-35px" [ngClass]="{'active' : menusubElementActive == 'fascicoli'}">
                        <span>Fascicolo</span>
                    </div>
                    <div routerLink="/modulo/ricerche/navigazione" class="sidebar-sub-element w-100 p-2 pl-35px" [ngClass]="{'active' : menusubElementActive == 'navigazione'}">
                        <span>Navigazione</span>
                    </div>
                    <div routerLink="/modulo/ricerche/ricercaReferenti" class="sidebar-sub-element w-100 p-2 pl-35px" [ngClass]="{'active' : menusubElementActive == 'ricercaReferenti'}">
                        <span>Anagrafiche</span>
                    </div>
                </div>
            </div>

            <div class="sidebar-item-container">
                <div class="sidebar-element w-100 p-3 border-bottom d-flex justify-content-between collapsed" data-toggle="collapse" data-target="#stampe-sub-menu" aria-controls="stampe-sub-menu" aria-expanded="false" [ngClass]="{'active' : menuElementActive == 'stampe'}"><!--element-disabled-->
                    <div class="sidebar-element-name">
                        <i class="icon-print"></i>
                        <span>Stampe</span>
                    </div>
                    <i class="icon-down-open"></i>
                </div>
                <div class="sidebar-sub-element-container border-bottom collapse" id="stampe-sub-menu" (click)="refreshActiveElement()" [ngClass]="{'show' : menuElementActive == 'stampe'}"> <!--animate__animated animate__bounceInLeft-->
                    <div routerLink="/modulo/stampe/registroProtocollo" class="sidebar-sub-element w-100 p-2 pl-35px" [ngClass]="{'active' : menusubElementActive == 'registroProtocollo'}">
                        <span>Registro protocollo</span>
                    </div>
                </div>
            </div>

            <div class="sidebar-item-container">
                <div class="sidebar-element w-100 p-3 border-bottom d-flex justify-content-between collapsed" data-toggle="collapse" data-target="#amministrazione-sub-menu" aria-controls="amministrazione-sub-menu" aria-expanded="false" [ngClass]="{'active' : menuElementActive == 'amministrazione'}"><!--element-disabled-->
                    <div class="sidebar-element-name">
                        <i class="icon-tools"></i>
                        <span>Amministrazione</span>
                    </div>
                    <i class="icon-down-open"></i>
                </div>
                <div class="sidebar-sub-element-container border-bottom collapse" id="amministrazione-sub-menu" (click)="refreshActiveElement()" [ngClass]="{'show' : menuElementActive == 'amministrazione'}"> <!--animate__animated animate__bounceInLeft-->
                    <div routerLink="/modulo/amministrazione/aggiungiReferente" class="sidebar-sub-element w-100 p-2 pl-35px" [ngClass]="{'active' : menusubElementActive == 'aggiungiReferente'}">
                        <span>Aggiungi anagrafica</span>
                    </div>
                </div>
            </div>
        </section>
        <!--####################################################################-->

        <div *ngIf="true">
        <ng-container  *ngTemplateOutlet="recursiveMenu; context : {menu : menu, level:0}"></ng-container>


        <ng-template #recursiveMenu let-menu="menu" let-level="level">
            <div *ngFor="let voceMenu of menu" class="sidebar-item-container {{level > 0 ? 'pl-' + (level+1) : ''}}">
                <div class="sidebar-element w-100 p-3 border-bottom border-top d-flex justify-content-between" (click)="voceMenu.active = !voceMenu.active" [ngClass]="{'active' : menuElementActive == voceMenu.voce}">
                    <div class="sidebar-element-name">
                        <i class="{{getIconFromMapFunction(voceMenu.voce)}}"></i>
                        <span>{{voceMenu.descrizione}}</span>
                    </div>
                    <i *ngIf="voceMenu.children.length > 0" class="icon-down-open"></i>
                </div>
                <div *ngIf="voceMenu.active">
                    <div *ngFor="let child of voceMenu.children" class="sidebar-sub-element-container" (click)="refreshActiveElement()"> <!--animate__animated animate__bounceInLeft-->
                        <div *ngIf="child.children.length > 0; else terminale">
                            <ng-container  *ngTemplateOutlet="recursiveMenu; context : {menu : [child], level:level+1}"></ng-container>
                        </div>
                        <ng-template #terminale>
                            <div routerLink="{{child.routerLink}}" class="sidebar-sub-element w-100 p-2 pl-35px" [ngClass]="{'active' : menusubElementActive == child.voce, 'element-disabled' : !(getAbilitato(child.posizione))}">
                                <span>{{child.descrizione}}</span>
                            </div>
                        </ng-template>

                    </div>
                </div>

            </div>
        </ng-template>



    </div>
    </div>
</div>
