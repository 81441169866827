import { Component, Input, OnInit } from '@angular/core';
import { DizionarioOggetto } from 'src/app/model/DizionarioOggetto';
import { RequestService } from 'src/app/service/requestService/request.service';
import { Utility } from 'src/app/Utility/Utility';

@Component({
  selector: 'app-dizionario-oggetto',
  templateUrl: './dizionario-oggetto.component.html',
  styleUrls: ['./dizionario-oggetto.component.scss']
})
export class DizionarioOggettoComponent implements OnInit {

  @Input() objParameterComponent : any;

  riportaOggetto : (oggetto : string) => void;

  docRequest : PayloadRequest = new PayloadRequest();

  oggetto : string;
  showNuovoOggetto : boolean = false;
  showAlert : boolean = false;
  classAlert : string = "success";
  messageAlert : string = "";

  listaDizionario : DizionarioOggetto[] = [];

  objToEdit : DizionarioOggetto;

  searchInProgress : boolean = false;

  //Variabili utilizzate per la paginazione
  //************************************* */
  totRecords : number;
  numPage : number = 0;
  currentPage : number = 1;
  numRow : number = 10;
  listObjPage : any = [];
  elementA : number;
  elementDa : number;
  //************************************* */

  constructor(private requestService : RequestService) { }

  ngOnInit(): void {
    if(this.objParameterComponent)
    {
      this.riportaOggetto = this.objParameterComponent.riportaOggetto;
      if(this.objParameterComponent.oggetto)
      {
        this.docRequest.fullText = this.objParameterComponent.oggetto;
        this.searchDizionario();
      }
    }
  }

  setShowOggetto(stato : boolean) : void{
    if(!stato) this.oggetto = "";
    this.showNuovoOggetto = stato;
  }


  searchDizionario(){
    this.searchInProgress = true;
    this.buildDocRequestPaginationField();
    this.listaDizionario = []

    this.requestService.postRequest("searchOggettiDizionario", this.docRequest,
      (success) => {
        this.totRecords = success.obj.totRecords;
        this.listaDizionario = success.obj.list?.map(element => new DizionarioOggetto(element));
        this.buildPaginationObject();
        this.searchInProgress = false;
      }
    )
  }

  /******************************* */
  /************MODIFICA*********** */
  /******************************* */
  enableEdit(obj : DizionarioOggetto){
    let finded = this.listaDizionario.find(element => element.showEdit == true);

    if(finded)
    {
      this.ripristinaOggetto(finded);
    }

    this.objToEdit = Utility.deepCopyObject(obj);
    obj.showEdit = true;
  }

  ripristinaOggetto(obj : DizionarioOggetto)
  {
    obj.codice = this.objToEdit.codice;
    obj.descrizione = this.objToEdit.descrizione;
    this.objToEdit = null;
    obj.showEdit = false;
  }

  /******************************* */
  /************MODIFICA*********** */
  /******************************* */

  /**
   * Metodo utilizzato per settare nell'oggetto di richiesta solo i campi relativi alla paginazione
   * essendo una cosa esterna ai filtri
   */
  buildDocRequestPaginationField()
  {
    this.docRequest.setNumRow(this.numRow);
    this.docRequest.setCurrentPage(this.currentPage);
  }

  /**
   * Questo Metodo viene utilizzata per calcolare il numero di pagine che la lista ha calcolata
   * in base al numero di record della lista e al opzione di numero di righe selezionata dall'utente
   */
  buildPaginationObject()
  {
    this.numPage = Utility.calculateNumberOfPages(this.totRecords, this.numRow);
    this.listObjPage = Utility.buildPaginationObject(this.numPage, this.currentPage);
    this.elementA = (this.currentPage * this.numRow) - (this.numRow - this.listaDizionario.length);
    this.elementDa = (this.currentPage * this.numRow) - (this.numRow - 1);
  }


  /**
   * Metodo utilizzato dalla paginazione per richiedere al servere la lista
   *
   * @param page : numero della pagina cliccata
   */
  changePage(page : number)
  {
    this.currentPage = page;
    this.searchDizionario();
  }

  updateDizionario(obj? : DizionarioOggetto) : void{
    let url = !obj ? "addOggettoDizionario" : "updateOggettoDizionario";

    if(obj) delete obj.showEdit;

    this.requestService.postRequest(url, (obj ? obj : {descrizione : this.oggetto}),
      (success) =>
      {
        this.messageAlert = "L'oggetto è stato " + (obj ? "aggiornato" : "inserito") + " con successo"
        this.classAlert = "success";
        this.showAlert = true;
        this.setShowOggetto(false);
        this.searchDizionario();

      },
      () => {
        this.messageAlert = "Errore nell' "+ (obj ? "aggiornamento" : "inserimento") +" dell'oggetto";
        this.classAlert = "danger";
        this.showAlert = true;
      }
    )
  }

  deleteOggetto(oggetto : DizionarioOggetto) : void{

    delete oggetto.showEdit;
    this.requestService.postRequest("deleteOggettoDiz", oggetto,
      (success) =>
      {
        this.messageAlert = "L'oggetto è stato eliminato con successo"
        this.classAlert = "success";
        this.showAlert = true;
        this.searchDizionario();

      },
      () => {
        this.messageAlert = "Errore nell'eliminazione dell'oggetto";
        this.classAlert = "danger";
        this.showAlert = true;
      }
    )
  }

}


class PayloadRequest{
  numRow : number;
  currentPage : number;
  order : number;
  fullText : string;

  constructor(obj? : any)
  {
    if(obj)
    {
      this.numRow = obj.numRow;
      this.currentPage = obj.currentPage;
      this.order = obj.order;
      this.fullText = obj.fullText;
    }
  }

  setCurrentPage(currentPage : number)
  {
      this.currentPage = currentPage;
  }

  setNumRow(numRow : number)
  {
      this.numRow = numRow;
  }

}
