  <!-- Modal -->
  <div class="modal fade" id="dynamicModal-{{idModal}}" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered {{option.getSizeModal()}}" [ngClass]="{'heigh-90-perc' : option.maxSizeHeightModal}" role="document">
      <div class="modal-content h-100"> <!--aggiunto h-100 05/10/2021-->
        <div class="modal-header" *ngIf="option.showHeaderModal">
            <h5 class="modal-title" id="titleModal" *ngIf="!option.textTitoloInnerHtml">
              {{option.titolo}}
            </h5>
            <div class="modal-title" id="titleModal" *ngIf="option.textTitoloInnerHtml" [innerHTML]="option.titolo"></div>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div  class="modal-body overflow-auto {{option.getClassSizeMaxHeightModalBody()}} {{option.additionalClassModalBody}}">
          <ng-template DinamicComponentDirective></ng-template>
          <div *ngIf="!option.getLoadDynamicComponent()">
            <span *ngIf="!option.textBodyInnerHtml">
              {{option.textBodyModal}}
            </span>
            <div *ngIf="option.textBodyInnerHtml" [innerHTML]="option.textBodyModal"></div>
          </div>
        </div>
        <div class="modal-footer" *ngIf="checkShowFooterModal()">
          <button *ngIf="option.valueYesChoiceButton != null" type="button" class="btn bg-primary-archipro text-white" (click)="emitChoiceValue(option.valueYesChoiceButton)">Si</button>
          <button *ngIf="option.valueNoChoiceButton != null" type="button" class="btn bg-primary-archipro text-white" (click)="emitChoiceValue(option.valueNoChoiceButton)">No</button>
          <button *ngIf="option.showCloseButton" type="button" class="btn bg-primary-archipro text-white" data-dismiss="modal">Chiudi</button>
          <button *ngIf="option.showSaveButton"  type="button" class="btn bg-primary-archipro text-white ">Salva</button>
          <button *ngIf="option.showCustomButton" (click)="option.functionCustomButton()" type="button" class="btn bg-primary-archipro text-white ">{{option.customButtonText}}</button>

        </div>
      </div>
    </div>
  </div>
