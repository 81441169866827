import { Component, OnInit, Input } from '@angular/core';
import { BreadcrumbObj } from './BreadcrumbObj';
import { Titolario } from './../../../../model/Titolario';
import { GlobalService } from 'src/app/service/globalService/global.service';
import { GlobalObj } from 'src/app/service/globalService/GlobalObj';
import { FascicoliRequest } from 'src/app/model/FascicoliRequest';

@Component({
  selector: 'app-navigazione',
  templateUrl: './navigazione.component.html',
  styleUrls: ['./navigazione.component.scss']
})
export class NavigazioneComponent implements OnInit {


  //Variabili utilizzate per gestire il breadcrump
  //********************************************** */

  breadCrumb : BreadcrumbObj = new BreadcrumbObj();
  
  //********************************************** */

  @Input() 
  objParameterComponent : any;

  //Campi passati dalla modale
  static modalMode : boolean = false;
  static autoNext : boolean = false;

  riportaTitolario : (titolario:any)=>void;
  riportaFascicolo : (objTitolarioFascicolo:any)=>void;
  titolarioFromForm : FascicoliRequest;

  titolarioSelezionato : any = null;
  fascicoloSelezionato : any = null;

  modalModificaFascicolo : any = null;
  
  
  GLOBALI : GlobalObj;

  //Elemento che viene passato al viewerversione dentro la navigazione (non in modale)
  documentoViewer : any = {};

  constructor(private globalService : GlobalService) { 
    this.GLOBALI = this.globalService.GLOBAL;
  }

  ngOnInit(): void {
    if(this.objParameterComponent != null)
    {
      NavigazioneComponent.modalMode = this.objParameterComponent.modalMode;
      NavigazioneComponent.autoNext = this.objParameterComponent.autoNext;
      this.riportaTitolario = this.objParameterComponent.riportaTitolario;    
      this.riportaFascicolo = this.objParameterComponent.riportaFascicolo; 
      this.titolarioFromForm = this.objParameterComponent.titolarioFromForm;

      if(this.objParameterComponent.hasOwnProperty("titolarioDaFiltrare")){}


    }
    else
    {
      NavigazioneComponent.modalMode = false;
    }
  }

  /**
   * Questa funzione apre il fascicolo selezionando un titolario nella prima parte della navigazione.
   * NB: il fascicoloSelezionato viene sempre inserito a null perchè se dal tab fascicoli torni indietro
   *     sull'albero titolario e ne selezioni un altro, deve effettuare una nuova ricerca
   * 
   * @param titolario : titolario selezionato dal component albero-titolario
   */
  apriFascicolo = (titolario: any): void => {
    
    this.fascicoloSelezionato = null;
    this.titolarioSelezionato = titolario;
    this.breadCrumb.setFascicoli();
  }

  apriListaDocumenti = (fascicolo : any) : void =>{
    this.fascicoloSelezionato = fascicolo;
    this.breadCrumb.setDocumenti(); 
  }

  apriDettaglioDocumentiPerModal = (documento : any) : void => {
    this.documentoViewer = documento;
    this.breadCrumb.setDettaglioDocumento(); 
  }

  titolarioIsSelezionato = (args : any): boolean => {
    return this.titolarioSelezionato != null;
  }

  fascicoloIsSelezionato = () : boolean => {
    return this.fascicoloSelezionato != null;
  }

  openFormFascicolo = (nuovo : boolean, fasc : any) : void=>{
    this.breadCrumb.setFormFascicolo(nuovo);
    let fascicolo : FascicoliRequest = new FascicoliRequest();
    fascicolo.setFascicoliRequestFromString(this.titolarioSelezionato.valore);

    if(!nuovo)
    {
      fascicolo.annoFascicolo = fasc.fascPK.annoFascicolo;
      fascicolo.numeroFascicolo = fasc.fascPK.numFascicolo;
    }
    this.modalModificaFascicolo = fascicolo;
  }

  get modalMode() : boolean
  {
    return NavigazioneComponent.modalMode;
  }

}
